import userflow from 'userflow.js'
import moment from 'moment'
import { USERFLOW_TOKEN } from '@slerp/client'
import { OnboardingStatus } from './useGlobalQuery'

interface User {
  id: string
  firstname: string
  lastname: string
  email: string
  role: string
}

interface InitUserFlowProps {
  user: User
  onboardingStatus: OnboardingStatus | null
  hasOnboarded?: boolean
  isAdmin: boolean
  companyName: string
}

const UserFlow = () => {
  const initUserFlow = ({
    user,
    onboardingStatus,
    hasOnboarded,
    isAdmin,
    companyName
  }: InitUserFlowProps) => {
    const {
      hasCard,
      hasLogos,
      hasDiscount,
      hasKeyImages,
      hasLoyaltyCard,
      hasModifier,
      hasProduct,
      hasStore,
      hasStripeSetup
    } = onboardingStatus ?? {}
    if (!USERFLOW_TOKEN) return console.error('Error: Missing USERFLOW_TOKEN')
    userflow.init(USERFLOW_TOKEN)
    userflow.identify(user.id, {
      name: `${user?.firstname ?? ''} ${user?.lastname ?? ''}`,
      email: user?.email,
      signed_up_at: moment().format(),
      role: isAdmin ? 'admin' : user?.role,
      ...(isAdmin && {
        has_stripe_setup: hasStripeSetup,
        has_valid_card: hasCard,
        has_logos: hasLogos,
        has_discount: hasDiscount,
        has_key_images: hasKeyImages,
        has_loyalty_card: hasLoyaltyCard,
        has_modifier: hasModifier,
        has_product: hasProduct,
        has_store: hasStore,
        has_onboarded: hasOnboarded
      }),
      company_name: companyName
    })
  }

  return { initUserFlow, userflow }
}

export default UserFlow
