import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Button,
  Col,
  List,
  PageHeader,
  Radio,
  Row,
  Select,
  Tag,
  Input,
  Tabs
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useQuery, useMutation } from '@apollo/client'
import { UPDATE_STORE_MODIFIER_STOCK_STATUS } from './actions'
import {
  buildInventoryQuery,
  StoreVariantsAndModifiersResponseType,
  StoreVariantResponseType,
  StoreModifier
} from './LocationQueries'
import StockCount from './StockCount'

const Settings = ({
  storeSlug,
  storeId
}: {
  storeSlug: string
  storeId: string
}) => {
  return (
    <>
      <PageHeader
        title='Inventory'
        style={{ marginTop: '36px', paddingBottom: '8px' }}
      />
      <Tabs>
        <Tabs.TabPane tab='Same day' key='1'>
          <InventorySettings storeSlug={storeSlug} storeId={storeId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Pre-order' key='2'>
          <InventorySettings
            storeSlug={storeSlug}
            storeId={storeId}
            isPreorder
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}

const InventorySettings = ({
  storeSlug,
  isPreorder,
  storeId
}: {
  storeSlug: string
  isPreorder?: boolean
  storeId: string
}) => {
  const query = buildInventoryQuery(isPreorder)
  const { data, loading, refetch } =
    useQuery<StoreVariantsAndModifiersResponseType>(query, {
      variables: { slug: storeSlug },
      fetchPolicy: 'no-cache'
    })
  const [updateStoreModifierStockStatus] = useMutation(
    UPDATE_STORE_MODIFIER_STOCK_STATUS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const initialVariants = data?.store_variants
  const variantCategories = _.chain(initialVariants)
    .map((sv) => sv.product_variant.product.category)
    .flatten()
    .compact()
    .uniqBy('id')
    .value()
  const storeModifiers = data?.store_modifiers
  const initialModifiers = storeModifiers?.filter(
    (sm) => !sm.modifier.archived_at && !!sm.modifier.published_at
  )
  const modifierCategories = _.chain(initialModifiers)
    .map((sm) =>
      sm.modifier.modifier_group_modifiers?.map((mgm) => mgm.modifier_group)
    )
    .flatten()
    .compact()
    .uniqBy('id')
    .value()

  const [selectedTab, setSelectedTab] = useState('products')
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [products, setProducts] = useState(initialVariants)
  const [modifiers, setModifiers] = useState(initialModifiers)
  const [isUpdating, setIsUpdating] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [page, setPage] = useState(1)

  const changeModifierStockStatus = (
    storeModifierId: string,
    newStatus: boolean
  ) => {
    setIsUpdating(true)
    updateStoreModifierStockStatus({
      variables: {
        store_modifier_id: storeModifierId,
        in_stock: newStatus
      }
    }).then(() => {
      setModifiers(
        modifiers?.map((modifier) =>
          modifier.id === storeModifierId
            ? { ...modifier, in_stock: newStatus }
            : modifier
        )
      )
      refetch()
      setIsUpdating(false)
    })
  }

  useEffect(() => {
    setProducts(initialVariants)
    setModifiers(initialModifiers)
  }, [loading])

  useEffect(() => {
    const modifiers = storeModifiers?.filter(
      (sm) => !sm.modifier.archived_at && !!sm.modifier.published_at
    )
    setProducts(initialVariants)
    setModifiers(modifiers)
  }, [initialVariants, storeModifiers])

  const variantOptions = variantCategories.map((category) => (
    <Select.Option key={category.id} value={category.name}>
      {category.name}
    </Select.Option>
  ))

  const modifierOptions = modifierCategories.map((category) => (
    <Select.Option key={category.id} value={category.name}>
      {category.name}
    </Select.Option>
  ))

  const filterModifiers = (
    categoryName: string,
    storeModifiers?: StoreModifier[]
  ) => {
    if (!storeModifiers) {
      return
    }

    return _.chain(storeModifiers)
      .filter((sm) => {
        return !!sm.modifier.modifier_group_modifiers
          ?.map((mgm) => mgm.modifier_group.name)
          .includes(categoryName)
      })
      .value()
  }

  const handleTabChange = (tabName: string) => {
    setSelectedTab(tabName)
    setSelectedCategory('all')
  }

  const productsListDataSource = () => {
    const categorizedProducts =
      selectedCategory === 'all'
        ? products
        : products?.filter(
            (product) =>
              product.product_variant.product.category.name === selectedCategory
          )

    if (searchKeyword === '') return categorizedProducts
    return (categorizedProducts || []).filter((product) =>
      filterString(product.product_variant.name, searchKeyword)
    )
  }

  const modifiersListDataSource = () => {
    const categorizedModifiers =
      selectedCategory === 'all'
        ? modifiers
        : filterModifiers(selectedCategory, modifiers)

    if (searchKeyword === '') return categorizedModifiers
    return (categorizedModifiers || []).filter(({ modifier }) =>
      filterString(modifier.name, searchKeyword)
    )
  }

  useEffect(() => {
    setSearchKeyword('')
  }, [selectedTab])

  useEffect(() => {
    setPage(1)
  }, [selectedTab, searchKeyword, selectedCategory])

  const paginationProps = {
    onChange: setPage,
    current: page,
    showSizeChanger: true,
    pageSizeOptions: ['10', '50', '100']
  }

  return (
    <>
      <Row>
        <Col flex='auto' style={{ display: 'flex' }}>
          <Radio.Group
            defaultValue={selectedTab}
            onChange={(e) => handleTabChange(e.target.value)}
            style={{ marginRight: '16px' }}
          >
            <Radio.Button value='products'>Products</Radio.Button>
            <Radio.Button value='modifiers'>Modifiers</Radio.Button>
          </Radio.Group>
          <Search
            value={searchKeyword}
            handleChange={setSearchKeyword}
            placeholder={`Search ${selectedTab}`}
          />
        </Col>
        <Col>
          <Select
            value={selectedCategory}
            dropdownMatchSelectWidth={false}
            style={{ width: 180 }}
            onChange={(value) => setSelectedCategory(value)}
          >
            <Select.Option value='all'>All</Select.Option>
            {selectedTab === 'products' ? variantOptions : modifierOptions}
          </Select>
        </Col>
      </Row>
      {selectedTab === 'products' ? (
        <StockCount
          storeSlug={storeSlug}
          storeId={storeId}
          products={productsListDataSource()}
          refetch={refetch}
          paginationProps={paginationProps}
        />
      ) : (
        <List
          itemLayout='horizontal'
          dataSource={modifiersListDataSource()}
          loading={loading}
          pagination={{
            ...paginationProps,
            total: (modifiersListDataSource() || []).length
          }}
          style={{ marginTop: '24px' }}
          renderItem={(item) => (
            <List.Item
              extra={
                <Button
                  onClick={() =>
                    changeModifierStockStatus(item.id, !item.in_stock)
                  }
                  loading={isUpdating}
                >
                  Mark as {item.in_stock ? 'sold out' : 'in stock'}
                </Button>
              }
            >
              <List.Item.Meta
                title={item.modifier.name}
                description={
                  !item.in_stock ? <Tag color='orange'>Sold out</Tag> : null
                }
              />
            </List.Item>
          )}
        />
      )}
    </>
  )
}

export const Search = ({
  value,
  placeholder,
  handleChange
}: {
  value: string
  placeholder: string
  handleChange: (value: string) => void
}) => (
  <Input
    value={value}
    prefix={<SearchOutlined />}
    style={{ width: '200px' }}
    placeholder={placeholder}
    allowClear
    onChange={(e) => handleChange(e.target.value)}
  />
)

export const filterString = (value: string, keyword: string) =>
  (value || '').toLowerCase().includes((keyword || '').toLowerCase())

export default Settings
