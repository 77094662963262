import React, { useState, useEffect } from 'react'
import { Table, Tag, Button, Row, Select, Col, Input } from 'antd'
import { PlusOutlined, SearchOutlined, EyeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import {
  QUERY_MERCHANT_LOCATIONS,
  MerchantLocationResponseType
} from './LocationQueries'
import { useQuery } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import { SelectPrefixWrapper } from '../Widgets'
import { FilterIcon, SelectDownArrowIcon } from '@slerp/assets'
import debounce from 'lodash/debounce'
import { Store } from '@slerp/controls'
import { isAutoAccept } from './utils'

interface PreOrderSettings {
  delivery_enabled: boolean
  pickup_enabled: boolean
}

const { Column } = Table
const { Option } = Select

const LocationsList = () => {
  const { merchant } = useSession()
  const { data, loading } = useQuery<MerchantLocationResponseType>(
    QUERY_MERCHANT_LOCATIONS,
    {
      variables: { slug: merchant.slug },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }
  )

  const [isArchived, setIsArchived] = useState(0)

  const stores =
    data &&
    data.merchants[0].stores.filter((store) =>
      isArchived ? !!store.archived_at : !store.archived_at
    )

  const [locations, setLocations] = useState<Array<Store>>(stores as Store[])
  const [fuzzySearchKeyword, setFuzzySearchKeyword] = useState('')

  const filterStores = (stores: Store[]) => {
    if (!fuzzySearchKeyword) return stores

    const parsedFilter = fuzzySearchKeyword.replace(/\s/g, '').toLowerCase()
    return stores.filter((store) => {
      const nameQuery = store?.name?.toLowerCase().includes(parsedFilter)
      const zipQuery = store?.address?.zip.toLowerCase().includes(parsedFilter)

      return nameQuery || zipQuery
    })
  }

  useEffect(() => {
    if (data) return setLocations(stores as Store[])
  }, [data, isArchived])

  useEffect(() => {
    if (data) {
      setLocations(filterStores(stores as Store[]))
    }
  }, [fuzzySearchKeyword])

  const debouncedFuzzySearchKeyword = debounce(setFuzzySearchKeyword, 200)

  return (
    <>
      <Row align='middle' className='_mb-16'>
        <Col span={12}>
          <SelectPrefixWrapper
            prefixicon={<FilterIcon />}
            style={{ marginRight: '24px' }}
          >
            <Select
              data-testid='stores-tab-select'
              suffixIcon={<SelectDownArrowIcon />}
              defaultValue={0}
              onChange={(value: number) => setIsArchived(value)}
              value={isArchived}
            >
              <Option key='active' value={0}>
                Active Locations
              </Option>
              <Option key='archived' value={1}>
                Archived Locations
              </Option>
            </Select>
          </SelectPrefixWrapper>
        </Col>

        <Col span={12}>
          <Row align='middle' justify='end' gutter={8}>
            <Col>
              <Input
                data-testid='products-search-filter'
                prefix={<SearchOutlined />}
                placeholder='Search'
                onChange={(e) => debouncedFuzzySearchKeyword(e.target.value)}
              />
            </Col>
            <Col>
              <Link
                to='/locations/new'
                className='btn-text _link-white _ml-12 _ml-auto'
              >
                <Button
                  className='_center-vertical _uppercase'
                  icon={<PlusOutlined />}
                >
                  Add Location
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        data-testid='location-list-component'
        dataSource={locations}
        pagination={false}
        loading={loading}
      >
        <Column
          title='Location'
          dataIndex='name'
          key='name'
          width='20%'
          render={(text: string, record: Store) => {
            return (
              <Link to={`/location/${record.slug}`}>
                <strong className='_link-primary'>
                  {text}
                  <br />
                </strong>
                {record.address && record.address.zip}
              </Link>
            )
          }}
        />
        <Column
          title='Same Day Delivery'
          dataIndex={['settings', 'same_day_delivery']}
          align='center'
          render={(value: string, store: Store) => (
            <YesNoTag
              enabled={!!value}
              isAutoAccept={isAutoAccept(store, 'delivery', 'sameday')}
            />
          )}
        />
        <Column
          title='Same Day Pick-up'
          dataIndex={['settings', 'same_day_pickup']}
          align='center'
          render={(value: string, store: Store) => (
            <YesNoTag
              enabled={!!value}
              isAutoAccept={isAutoAccept(store, 'pickup', 'sameday')}
            />
          )}
        />
        <Column
          title='Pre-Order Delivery'
          dataIndex={['pre_order_settings']}
          align='center'
          render={(value: Array<PreOrderSettings>, store: Store) => (
            <YesNoTag
              enabled={value[0]?.delivery_enabled}
              isAutoAccept={isAutoAccept(store, 'delivery', 'preorder')}
            />
          )}
        />
        <Column
          title='Pre-Order Pickup'
          dataIndex={['pre_order_settings']}
          align='center'
          render={(value: Array<PreOrderSettings>, store: Store) => (
            <YesNoTag
              enabled={value[0]?.pickup_enabled}
              isAutoAccept={isAutoAccept(store, 'pickup', 'preorder')}
            />
          )}
        />
        <Column
          title='Order at Table'
          dataIndex={['settings', 'table_enabled']}
          align='center'
          render={(value: string) => (
            <>
              {value ? <Tag color='green'>Yes</Tag> : <Tag color='red'>No</Tag>}
            </>
          )}
        />
        <Column
          title='Action'
          render={(record: Store) => {
            return (
              <Link to={`/location/${record.slug}`}>
                <Button className='_center-vertical _p-10'>
                  <EyeOutlined />
                </Button>
              </Link>
            )
          }}
        />
      </Table>
    </>
  )
}

const YesNoTag = ({
  enabled,
  isAutoAccept
}: {
  enabled: boolean
  isAutoAccept: boolean
}) => {
  return (
    <Tag>
      {enabled ? (
        <span style={{ color: '#4eb21c' }}>Yes</span>
      ) : (
        <span style={{ color: '#e2232d' }}>No</span>
      )}
      {enabled && isAutoAccept && (
        <>
          <span style={{ color: '#8C8CA1' }}> | </span>
          <span>Auto Accept</span>
        </>
      )}
    </Tag>
  )
}

export default LocationsList
