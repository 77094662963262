import React, { useState, useRef } from 'react'
import Drawer, { DrawerProps } from 'antd/lib/drawer'
import styled from '@emotion/styled'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
interface Props extends DrawerProps {
  children: ({
    productTabHandler,
    formRef,
    pricingFormRef
  }: {
    productTabHandler: any
    formRef: any
    pricingFormRef: any
  }) => Element | JSX.Element
  onClose: ((e: React.MouseEvent | React.KeyboardEvent) => void) | undefined
}

type ProductTabs = 'general' | 'images' | 'variants' | 'pricing' | 'modifiers'

export const SlerpDrawer = (props: Props) => {
  const { children, onClose } = props
  const formRef = useRef(null)
  const pricingFormRef = useRef(null)

  const [productTab, setProductTab] = useState<ProductTabs>('general')

  const productTabHandler = (tab: ProductTabs) => {
    setProductTab(tab)
  }

  const handleSave = () => {
    switch (productTab) {
      case 'general':
        if (formRef.current) {
          formRef.current.submit()
        }

        break

      case 'pricing':
        if (pricingFormRef.current) {
          pricingFormRef.current.onSave()
        }
        break
    }
  }

  const handleOnDiscard = () => {
    switch (productTab) {
      case 'pricing':
        if (pricingFormRef.current) {
          pricingFormRef.current.onDiscard()
        }

        break

      default:
        onClose && onClose()
    }
  }

  return (
    <StyledDrawer
      {...props}
      productTab={productTab}
      footer={
        <Row gutter={[8, 0]} className='_mb-0' justify='end'>
          <Col>
            <Button
              type='ghost'
              onClick={handleOnDiscard}
              data-testid='action-discard-form-btn'
            >
              Discard
            </Button>
          </Col>
          <Col>
            <Button
              onClick={handleSave}
              type='primary'
              data-testid='action-save-form-btn'
            >
              Save
            </Button>
          </Col>
        </Row>
      }
    >
      {children({ productTabHandler, formRef, pricingFormRef })}
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer)<{ productTab: ProductTabs }>`
  ${({ productTab }) => `
  .ant-drawer-header {
    background-color: white;

    padding: 20px 12px 20px 16px;
  }

  .ant-drawer-title {
    color: #4a4a68;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
  }

  .ant-drawer-close {
    width: 36px;
    height: 36px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .ant-drawer-header {
    order: 0;
  }

  .ant-drawer-body {
    order: 2;
    padding: 24px 0 16px 24px;

    overflow: unset;
  }

  .ant-drawer-footer {
    padding: 14.5px 24px;
    background-color: #4a4a68;

    .ant-btn-ghost, .ant-btn-primary {
      opacity: ${
        productTab !== 'general' && productTab !== 'pricing' ? '0' : '1'
      };

      pointer-events: ${
        productTab !== 'general' && productTab !== 'pricing' ? 'none' : 'auto'
      }
    }
    

    order: 1;

    .ant-btn {
      padding: 8px 16px;
      line-height: 1;
    }
  }

  .ant-drawer-content {
    overflow: unset;
  }

  .ant-tabs-content-holder {
    max-height: calc(100vh - 200px);
    overflow-y: ${
      productTab === 'general' || productTab === 'pricing' ? 'scroll' : 'unset'
    };
    overflow-x: hidden;
  }

  .ant-tabs-nav[role='tablist'] {
    margin-bottom: 24px;
  }
    `}
`
