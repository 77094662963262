import React from 'react'
import { Collapse } from 'antd'
import PickupDetails from './PickupDetails'
import { OrderInfo } from '../../type'

interface PickupInformationProps {
  order: OrderInfo
}

const { Panel } = Collapse

const PickupInformation = ({ order }: PickupInformationProps) => {
  return (
    <Collapse defaultActiveKey={['pickupDetails']} className='-plain'>
      <Panel header='Pickup details' key='pickupDetails' className='-compact'>
        <PickupDetails order={order} />
      </Panel>
    </Collapse>
  )
}

export default PickupInformation
