import { Discount } from '@slerp/controls'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'

type NumberOfUsesTextType = Discount & { usage_count: number }

const isActive = (discount: Discount) => {
  const { published_at, start_date, end_date } = discount

  const isIndefinitelyActive = !start_date && !end_date
  const isWithinActiveDates =
    !!start_date &&
    !!end_date &&
    moment().isBetween(start_date, end_date, 'day', '[]')
  const isIncludedWithIndefiniteEnd =
    !!start_date && !end_date && moment().isSameOrAfter(start_date, 'day')
  const isIncludedWithIndefiniteStart =
    !start_date && !!end_date && moment().isSameOrBefore(end_date, 'day')

  return (
    !!published_at &&
    (isIndefinitelyActive ||
      isWithinActiveDates ||
      isIncludedWithIndefiniteEnd ||
      isIncludedWithIndefiniteStart)
  )
}

const isScheduled = (discount: Discount) => {
  const { published_at, start_date } = discount

  const isFutureStartDate = !!start_date && moment().isBefore(start_date, 'day')

  return !!published_at && isFutureStartDate
}

const isInactive = (discount: Discount) => {
  return !isActive(discount) && !isScheduled(discount)
}

const descriptionText = (discount: Discount) => {
  const { value, type, target } = discount
  const targets = {
    all_charges: 'all products, delivery fee and additional fee',
    subtotal_delivery_fee: 'all products & delivery fee',
    all_products: 'all products',
    delivery_fee: 'delivery fee',
    product: 'select products',
    category: 'select categories',
    product_variant: 'select products'
  }
  const discountValueText = type === 'fixed' ? `£${value} off` : `${value}% off`
  return `${discountValueText} ${targets[target]}`
}

const scheduleText = (discount: Discount) => {
  const { start_date, end_date } = discount
  let scheduleString = ''
  if (isEmpty(start_date)) {
    scheduleString = 'NONE'
  } else {
    const startDateString = moment(start_date).format('DD MMM')
    const endDateString = isEmpty(end_date)
      ? 'no end date'
      : moment(end_date).format('DD MMM')
    scheduleString = `${startDateString} - ${endDateString}`
  }
  return scheduleString
}

const numberOfUsesText = (discount: NumberOfUsesTextType) => {
  const { quantity, usage_count } = discount
  return `${usage_count}/${quantity}`
}

export {
  isActive,
  isScheduled,
  isInactive,
  descriptionText,
  scheduleText,
  numberOfUsesText
}
