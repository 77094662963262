import gql from 'graphql-tag'

const GET_DATES_WITH_ORDERS_BY_MONTH = gql`
  query getDatesWithOrdersByMonth(
    $merchantId: String
    $date: String
    $storeId: String
  ) {
    dates: getDatesWithOrdersByMonth(
      merchantId: $merchantId
      storeId: $storeId
      date: $date
    )
  }
`

export const QUERY_GET_STORE_SLUG_LOG = gql`
  query getStoreSlugLog($storeSlug: String!) {
    slug_logs(
      where: { old_slug: { _eq: $storeSlug }, type: { _eq: "store" } }
      limit: 1
    ) {
      store {
        slug
      }
    }
  }
`

const SUBMITTED_ORDER_STATS = gql`
  query orderStats(
    $merchantId: ID!
    $startDate: String!
    $endDate: String!
    $storeId: ID
  ) {
    submitted: submittedOrderStats(
      merchantId: $merchantId
      startDate: $startDate
      endDate: $endDate
      storeId: $storeId
    ) {
      count
      gmv
    }
  }
`

const GET_STORE_ORDERS = gql`
  query getStoreOrders(
    $storeId: uuid!
    $status: String!
    $startDate: timestamp
    $endDate: timestamp
  ) {
    orders(
      where: {
        store_id: { _eq: $storeId }
        status: { _eq: $status }
        _or: [
          { fulfillment_date: { _gte: $startDate, _lte: $endDate } }
          { inserted_at: { _gte: $startDate, _lte: $endDate } }
        ]
      }
      order_by: { inserted_at: desc }
    ) {
      transaction_id
      subtotal
      fulfillment_type
      fulfillment_time_range
      status
    }
  }
`

const GET_MERCHANT_ORDER_STORES = gql`
  query getSameDayOrderStores($merchantId: uuid!, $userEmail: String!) {
    stores(
      where: {
        archived_at: { _is_null: true }
        merchant_id: { _eq: $merchantId }
        _or: [
          {
            store_users: {
              user: {
                email: { _eq: $userEmail }
                merchant_id: { _eq: $merchantId }
                role: { _eq: "manager" }
              }
            }
          }
          {
            users: {
              email: { _eq: $userEmail }
              merchant_id: { _eq: $merchantId }
              role: { _eq: "member" }
            }
          }
          {
            merchant: {
              users: {
                email: { _eq: $userEmail }
                merchant_id: { _eq: $merchantId }
                role: { _eq: "admin" }
              }
            }
          }
        ]
      }
    ) {
      id
      slug
      name
      address {
        zip
      }
    }
  }
`

const buildStoreInventoryQuery = (
  isPreorder?: boolean,
  publishedOnly: boolean = true
) => {
  const storeFilter = isPreorder
    ? `preorder_store_id: { _eq: $storeId }`
    : `store_id: { _eq: $storeId }`

  const publishedFilter = publishedOnly
    ? 'published_at: { _is_null: false }'
    : ''

  return gql`
  query ${
    isPreorder ? 'getPreorderInventory' : 'getSamedayInventory'
  }($storeId: uuid!) {
    store_variants(
      where: {
        product_variant: {
          archived_at: { _is_null: true },
          product: {
            archived_at: { _is_null: true },
          }
        }
        ${publishedFilter}
        ${storeFilter}
      },
      order_by: {product_variant: {name: asc}}
    ) {
      id
      key: variant_id
      variant_id
      in_stock
      stock_count
      stock_sold
      stock_type
      stock_reset_count
      published_at
      updated_at
      stock_updated_at
      unsnooze_at
      product_variant {
        name
        sku
        default_variant
        product {
          id
          name
          allergens
          category_id
          default_variant_id
          category {
            name
          }
          products_categories {
            category_id
            product_id
          }
        }
      }
    }
    store_modifiers(
      where: {
        modifier: {
          archived_at: { _is_null: true },
          modifier_group_modifiers: {
            modifier: {
              archived_at: { _is_null: true }
            }
          }
        }
        ${publishedFilter}
        ${storeFilter}
      },
      order_by: {modifier: {name: asc}}
    ) {
      id
      key: modifier_id
      modifier_id
      in_stock
      published_at
      updated_at
      unsnooze_at
      modifier {
        name
        sku
        modifier_group_modifiers {
          modifier_group_id
        }
      }
    }
  }
`
}

const GET_STORE_SAMEDAY_INVENTORY = buildStoreInventoryQuery()
const GET_STORE_PREORDER_INVENTORY = buildStoreInventoryQuery(true)
const GET_ALL_STORE_SAMEDAY_INVENTORY = buildStoreInventoryQuery(false, false)
const GET_ALL_STORE_PREORDER_INVENTORY = buildStoreInventoryQuery(true, false)

const buildPublishStoreVariantsQuery = (
  isPreorder: boolean,
  isPublish: boolean
) => {
  const storeFilter = isPreorder
    ? `preorder_store_id: { _eq: $storeId }`
    : `store_id: { _eq: $storeId }`

  const storeVariantSetStatement = isPublish
    ? `_set: { published_at: "now()", updated_at: "now()" }`
    : `_set: { published_at: null, updated_at: "now()" }`

  return gql`
    mutation publishStoreVariants(
      $variantIds: [uuid!]
      $storeId: uuid!
    ) {
      update_store_variants(
        where: {
          variant_id: { _in: $variantIds }
          ${storeFilter}
        }
        ${storeVariantSetStatement}
      ) {
        affected_rows
        returning {
          published_at
        }
      }
    }
  `
}

const UPDATE_SAMEDAY_VARIANT_STOCK_COUNT = gql`
  mutation updateSameDayVariantStockCount(
    $variantId: uuid!
    $storeId: uuid!
    $stockType: String
    $stockCount: Int
    $stockSold: Int
    $stockResetCount: Int
  ) {
    update_stores(
      where: { id: { _eq: $storeId } }
      _append: { settings: { stock_control_enabled: true } }
    ) {
      affected_rows
    }

    update_store_variants(
      where: { variant_id: { _eq: $variantId }, store_id: { _eq: $storeId } }
      _set: {
        stock_type: $stockType
        stock_count: $stockCount
        stock_sold: $stockSold
        stock_reset_count: $stockResetCount
        stock_updated_at: "now()"
      }
    ) {
      affected_rows
      returning {
        id
        stock_type
        stock_count
        stock_reset_count
      }
    }
  }
`

const UPDATE_PREORDER_VARIANT_STOCK_COUNT = gql`
  mutation updatePreOrderVariantStockCount(
    $variantId: uuid!
    $storeId: uuid!
    $stockType: String
    $stockCount: Int
    $stockSold: Int
    $stockResetCount: Int
  ) {
    update_stores(
      where: { id: { _eq: $storeId } }
      _append: { settings: { stock_control_enabled: true } }
    ) {
      affected_rows
    }

    update_store_variants(
      where: {
        variant_id: { _eq: $variantId }
        preorder_store_id: { _eq: $storeId }
      }
      _set: {
        stock_type: $stockType
        stock_count: $stockCount
        stock_sold: $stockSold
        stock_reset_count: $stockResetCount
        stock_updated_at: "now()"
      }
    ) {
      affected_rows
      returning {
        id
        stock_type
        stock_count
        stock_reset_count
      }
    }
  }
`

const PUBLISH_STORE_VARIANTS = buildPublishStoreVariantsQuery(false, true)
const UNPUBLISH_STORE_VARIANTS = buildPublishStoreVariantsQuery(false, false)
const PUBLISH_PRE_ORDER_STORE_VARIANTS = buildPublishStoreVariantsQuery(
  true,
  true
)
const UNPUBLISH_PRE_ORDER_STORE_VARIANTS = buildPublishStoreVariantsQuery(
  true,
  false
)

const buildPublishStoreModifiersQuery = (
  isPreorder: boolean,
  isPublish: boolean
) => {
  const storeFilter = isPreorder
    ? `preorder_store_id: { _eq: $storeId }`
    : `store_id: { _eq: $storeId }`

  const storeModifierSetStatement = isPublish
    ? `_set: { published_at: "now()", updated_at: "now()" }`
    : `_set: { published_at: null, updated_at: "now()" }`

  return gql`
    mutation publishStoreModifiers(
      $modifierIds: [uuid!]
      $storeId: uuid!
    ) { update_store_modifiers(
        where: {
          modifier_id: { _in: $modifierIds }
          ${storeFilter}
        }
        ${storeModifierSetStatement}
      ) {
        affected_rows
        returning {
          published_at
        }
      }
    }
  `
}

const PUBLISH_STORE_MODIFIERS = buildPublishStoreModifiersQuery(false, true)
const UNPUBLISH_STORE_MODIFIERS = buildPublishStoreModifiersQuery(false, false)
const PUBLISH_PRE_ORDER_STORE_MODIFIERS = buildPublishStoreModifiersQuery(
  true,
  true
)
const UNPUBLISH_PRE_ORDER_STORE_MODIFIERS = buildPublishStoreModifiersQuery(
  true,
  false
)

const buildSetStoreVariantStockAvailabilityQuery = (
  isPreorder: boolean,
  inStock: boolean
) => {
  const storeFilter = isPreorder
    ? `preorder_store_id: { _eq: $storeId }`
    : `store_id: { _eq: $storeId }`

  const storeVariantSetStatement = inStock
    ? `_set: { in_stock: true, stock_type: null, updated_at: "now()" }`
    : `_set: { in_stock: false, stock_type: null, updated_at: "now()" }`

  return gql`
    mutation setStoreVariantStockAvailability(
      $variantIds: [uuid!]
      $storeId: uuid!
    ) {
      update_store_variants(
        where: {
          variant_id: { _in: $variantIds }
          ${storeFilter}
        }
        ${storeVariantSetStatement}
      ) {
        affected_rows
        returning {
          id
          in_stock
        }
      }
    }
  `
}

const MARK_IN_STOCK_STORE_VARIANTS = buildSetStoreVariantStockAvailabilityQuery(
  false,
  true
)
const MARK_OUT_OF_STOCK_STORE_VARIANTS =
  buildSetStoreVariantStockAvailabilityQuery(false, false)
const MARK_IN_STOCK_PRE_ORDER_STORE_VARIANTS =
  buildSetStoreVariantStockAvailabilityQuery(true, true)
const MARK_OUT_OF_STOCK_PRE_ORDER_STORE_VARIANTS =
  buildSetStoreVariantStockAvailabilityQuery(true, false)

const buildSetStoreModifierStockAvailabilityQuery = (
  isPreorder: boolean,
  inStock: boolean
) => {
  const storeFilter = isPreorder
    ? `preorder_store_id: { _eq: $storeId }`
    : `store_id: { _eq: $storeId }`

  const storeModifierSetStatement = inStock
    ? `_set: { in_stock: true, updated_at: "now()" }`
    : `_set: { in_stock: false, updated_at: "now()" }`

  return gql`
    mutation setStoreVariantStockAvailability(
      $modifierIds: [uuid!]
      $storeId: uuid!
    ) {
      update_store_modifiers(
        where: {
          modifier_id: { _in: $modifierIds }
          ${storeFilter}
        }
        ${storeModifierSetStatement}
      ) {
        affected_rows
        returning {
          id
          in_stock
        }
      }
    }
  `
}

const MARK_IN_STOCK_STORE_MODIFIERS =
  buildSetStoreModifierStockAvailabilityQuery(false, true)
const MARK_OUT_OF_STOCK_STORE_MODIFIERS =
  buildSetStoreModifierStockAvailabilityQuery(false, false)
const MARK_IN_STOCK_PRE_ORDER_STORE_MODIFIERS =
  buildSetStoreModifierStockAvailabilityQuery(true, true)
const MARK_OUT_OF_STOCK_PRE_ORDER_STORE_MODIFIERS =
  buildSetStoreModifierStockAvailabilityQuery(true, false)

const GET_MERCHANT_CATEGORIES = gql`
  query getMerchantCategories($merchantId: uuid!) {
    categories(
      where: {
        merchant_id: { _eq: $merchantId }
        archived_at: { _is_null: true }
      }
      order_by: { name: asc }
    ) {
      id
      name
      slug
      category_special_availabilities {
        special_availability {
          id
          end_time
          end_datetime
          rrule
          order_type
          store_special_availabilities {
            store {
              id
              slug
            }
          }
        }
      }
    }
  }
`

const GET_MERCHANT_MODIFIER_GROUPS = gql`
  query getMerchantModifierGroups($merchantId: uuid!) {
    modifier_groups(
      where: {
        merchant_id: { _eq: $merchantId }
        archived_at: { _is_null: true }
      }
    ) {
      id
      name
      description
    }
  }
`

const GET_MERCHANT_SETTINGS = gql`
  query getMerchantSettings($merchantSlug: String!) {
    merchants(where: { slug: { _eq: $merchantSlug } }) {
      setting
    }
  }
`

const SNOOZE_STORE_VARIANT = gql`
  mutation snoozeStoreVariant($storeVariantId: String!) {
    snoozeSameDayStoreVariant(storeVariantId: $storeVariantId) {
      id
    }
  }
`

const UNSNOOZE_STORE_VARIANT = gql`
  mutation unsnoozeStoreVariant($storeVariantId: String!) {
    unsnoozeSameDayStoreVariant(storeVariantId: $storeVariantId) {
      id
    }
  }
`

const SNOOZE_STORE_MODIFIER = gql`
  mutation snoozeStoreModifier($storeModifierId: String!) {
    snoozeSameDayStoreModifier(storeModifierId: $storeModifierId) {
      id
    }
  }
`

const UNSNOOZE_STORE_MODIFIER = gql`
  mutation snoozeStoreModifier($storeModifierId: String!) {
    unsnoozeSameDayStoreModifier(storeModifierId: $storeModifierId) {
      id
    }
  }
`

const ADD_SPECIAL_AVAILABILITY_TO_CATEGORY = gql`
  mutation addSpecialAvailabilityToCategory(
    $categoryId: uuid!
    $specialAvailability: RruleSpecialAvailabilityParams!
  ) {
    addSpecialAvailabilityToCategory(
      categoryId: $categoryId
      specialAvailability: $specialAvailability
    ) {
      id
      name
      rruleSpecialAvailabilities {
        id
        endTime
        endDatetime
        orderType
        rrule
        storeId
      }
    }
  }
`

const EDIT_SPECIAL_AVAILABILITY_FOR_CATEGORY = gql`
  mutation editSpecialAvailabilityForCategory(
    $categoryId: uuid!
    $newCategoryId: uuid
    $specialAvailabilityId: uuid!
    $specialAvailability: RruleSpecialAvailabilityParams!
  ) {
    editSpecialAvailabilityForCategory(
      categoryId: $categoryId
      newCategoryId: $newCategoryId
      specialAvailabilityId: $specialAvailabilityId
      specialAvailability: $specialAvailability
    ) {
      id
      endTime
      endDatetime
      orderType
      rrule
      storeId
    }
  }
`

const DELETE_SPECIAL_AVAILABILITY_FOR_CATEGORY = gql`
  mutation deleteSpecialAvailabilityForCategory($specialAvailabilityId: uuid!) {
    delete_special_availabilities(
      where: { id: { _eq: $specialAvailabilityId } }
    ) {
      affected_rows
    }
  }
`

const LIST_SPECIAL_AVAILABILITIES_FOR_CATEGORY = gql`
  query listSpecialAvailabilitiesForCategory(
    $categoryId: uuid!
    $storeId: uuid!
  ) {
    listSpecialAvailabilitiesForCategory(
      categoryId: $categoryId
      storeId: $storeId
    ) {
      id
      endTime
      endDatetime
      orderType
      rrule
      storeId
    }
  }
`

const GET_PRODUCTS_CATEGORIES_BY_CATEGORY_IDS = gql`
  query getProductsCategoriesByCategoryIds(
    $categoryIds: [products_categories_bool_exp]
  ) {
    products_categories(where: { _or: $categoryIds }) {
      id
      product_id
      category_id
      category {
        name
        category_special_availabilities {
          category_id
          special_availability {
            id
            end_time
            end_datetime
            rrule
            order_type
            store_special_availabilities {
              store {
                id
              }
            }
          }
        }
      }
    }
  }
`

const GET_MERCHANT_ACTIVE_MODIFIERS = gql`
  query getMerchantModifiersByGroup($merchantId: uuid!) {
    modifiers(
      where: {
        merchant_id: { _eq: $merchantId }
        archived_at: { _is_null: true }
      }
    ) {
      id
      name
      sku
      modifier_group_modifiers {
        modifier_group_id
      }
    }
  }
`
const CREATE_STORE_MODIFIERS = gql`
  mutation createMerchantStoreModifiers(
    $storeModifiers: [store_modifiers_insert_input!]!
  ) {
    insert_store_modifiers(objects: $storeModifiers) {
      affected_rows
    }
  }
`
const CREATE_STORE_VARIANTS = gql`
  mutation createMerchantStoreVariants(
    $storeVariants: [store_variants_insert_input!]!
  ) {
    insert_store_variants(objects: $storeVariants) {
      affected_rows
    }
  }
`

const GET_STORE_CATEGORIES_ARRANGEMENTS = gql`
  query getStoreCategoriesArrangements(
    $storeId: uuid!
    $orderType: order_type!
  ) {
    categories_arrangements(
      where: { store_id: { _eq: $storeId }, order_type: { _eq: $orderType } }
    ) {
      id
      index
      category_id
      order_type
      products_arrangements {
        id
        product_id
        index
      }
    }
  }
`

const UPDATE_STORE_CATEGORIES_ARRANGEMENTS = gql`
  mutation updateStoreCategoriesArrangements(
    $storeId: ID!
    $orderType: OrderType!
    $categoriesArrangementsInputs: [CategoriesArrangementsInput]!
  ) {
    updateStoreCategoriesArrangements(
      storeId: $storeId
      orderType: $orderType
      categoriesArrangementsInputs: $categoriesArrangementsInputs
    )
  }
`

const GET_STORE_MODIFIER_GROUP_ARRANGEMENTS = gql`
  query getStoreModifierGroupArrangements(
    $storeId: uuid!
    $orderType: order_type!
  ) {
    modifier_group_arrangements(
      where: { store_id: { _eq: $storeId }, order_type: { _eq: $orderType } }
    ) {
      id
      index
      modifier_group_id
      order_type
      modifier_arrangements {
        id
        modifier_id
        index
      }
    }
  }
`

const UPDATE_STORE_MODIFIER_GROUP_ARRANGEMENTS = gql`
  mutation updateStoreModifierGroupArrangements(
    $storeId: ID!
    $orderType: OrderType!
    $modifierGroupArrangementInputs: [ModifierGroupArrangementInput]!
  ) {
    updateStoreModifierGroupArrangements(
      storeId: $storeId
      orderType: $orderType
      modifierGroupArrangementInputs: $modifierGroupArrangementInputs
    )
  }
`

export {
  GET_DATES_WITH_ORDERS_BY_MONTH,
  SUBMITTED_ORDER_STATS,
  GET_STORE_ORDERS,
  GET_MERCHANT_ORDER_STORES,
  GET_STORE_SAMEDAY_INVENTORY,
  GET_STORE_PREORDER_INVENTORY,
  UNPUBLISH_STORE_VARIANTS,
  PUBLISH_STORE_VARIANTS,
  PUBLISH_PRE_ORDER_STORE_VARIANTS,
  UNPUBLISH_PRE_ORDER_STORE_VARIANTS,
  PUBLISH_STORE_MODIFIERS,
  UNPUBLISH_STORE_MODIFIERS,
  PUBLISH_PRE_ORDER_STORE_MODIFIERS,
  UNPUBLISH_PRE_ORDER_STORE_MODIFIERS,
  GET_MERCHANT_CATEGORIES,
  GET_MERCHANT_MODIFIER_GROUPS,
  UPDATE_SAMEDAY_VARIANT_STOCK_COUNT,
  UPDATE_PREORDER_VARIANT_STOCK_COUNT,
  GET_ALL_STORE_SAMEDAY_INVENTORY,
  GET_ALL_STORE_PREORDER_INVENTORY,
  MARK_IN_STOCK_STORE_VARIANTS,
  MARK_OUT_OF_STOCK_STORE_VARIANTS,
  MARK_IN_STOCK_PRE_ORDER_STORE_VARIANTS,
  MARK_OUT_OF_STOCK_PRE_ORDER_STORE_VARIANTS,
  MARK_IN_STOCK_STORE_MODIFIERS,
  MARK_OUT_OF_STOCK_STORE_MODIFIERS,
  MARK_IN_STOCK_PRE_ORDER_STORE_MODIFIERS,
  MARK_OUT_OF_STOCK_PRE_ORDER_STORE_MODIFIERS,
  GET_MERCHANT_SETTINGS,
  SNOOZE_STORE_VARIANT,
  UNSNOOZE_STORE_VARIANT,
  SNOOZE_STORE_MODIFIER,
  UNSNOOZE_STORE_MODIFIER,
  ADD_SPECIAL_AVAILABILITY_TO_CATEGORY,
  EDIT_SPECIAL_AVAILABILITY_FOR_CATEGORY,
  LIST_SPECIAL_AVAILABILITIES_FOR_CATEGORY,
  DELETE_SPECIAL_AVAILABILITY_FOR_CATEGORY,
  GET_PRODUCTS_CATEGORIES_BY_CATEGORY_IDS,
  GET_MERCHANT_ACTIVE_MODIFIERS,
  CREATE_STORE_MODIFIERS,
  CREATE_STORE_VARIANTS,
  GET_STORE_CATEGORIES_ARRANGEMENTS,
  UPDATE_STORE_CATEGORIES_ARRANGEMENTS,
  GET_STORE_MODIFIER_GROUP_ARRANGEMENTS,
  UPDATE_STORE_MODIFIER_GROUP_ARRANGEMENTS
}
