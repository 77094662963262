import React, { useState } from 'react'
import './App.less'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SlerpClient from '@slerp/client'
import Layout from 'components/Layout'
import StaffLayout from 'components/Layout/StaffLayout'
import Login from 'components/Login'
import ForgotPasswordForm from 'components/Login/ForgotPasswordForm'
import ResetPasswordForm from 'components/Login/ResetPasswordForm'
import SingleSignOn from 'components/SingleSignOn'
import {
  SessionContext,
  authenticate,
  createSession,
  requestPasswordChange,
  resetPassword,
  signOut,
  isAdmin
} from '@slerp/accounts'
import {
  initializeGA4,
  initializeLinkedInInsights,
  initializePixel
} from './packages/@slerp/tracker'
import VersionBanner, { EnvType } from 'components/Utils/VersionBanner'
import OnboardingForm from 'components/Login/OnboardingForm'
import { GlobalQueryProvider } from 'components/Utils/useGlobalQuery'

function App() {
  initializeGA4()
  initializePixel()
  initializeLinkedInInsights()
  const isValidJson = (string?: string | null) => {
    try {
      string && JSON.parse(string)
    } catch (e) {
      return false
    }
    return true
  }

  const [user] = useState(
    isValidJson(localStorage.getItem('user'))
      ? JSON.parse(localStorage.getItem('user')!)
      : undefined
  )

  const [merchant] = useState(
    isValidJson(localStorage.getItem('merchant'))
      ? JSON.parse(localStorage.getItem('merchant')!)
      : undefined
  )

  const [token] = useState(localStorage.getItem('token') || '')

  // Sign out user if it's using the old token.
  if (token && token.length <= 36) signOut()

  const initialSessionState = {
    user,
    merchant,
    createSession,
    authenticate,
    requestPasswordChange,
    resetPassword,
    token
  }

  return (
    <Router>
      <SessionContext.Provider value={initialSessionState}>
        <VersionBanner
          environment={
            (process.env.REACT_APP_TEST_ENV || 'production') as EnvType
          }
          branch={process.env.REACT_APP_ACTIVE_BRANCH || ''}
          commitRef={process.env.REACT_APP_COMMIT_REF || ''}
        />
        <SingleSignOn />
        {token && user && merchant ? (
          <SlerpClient config={{ apiKey: token, user: user }}>
            <GlobalQueryProvider>
              {isAdmin(user) ? <Layout /> : <StaffLayout />}
            </GlobalQueryProvider>
          </SlerpClient>
        ) : (
          <Switch>
            <Route path='/' exact component={Login} />
            <Route
              path='/forgot_password'
              exact
              component={ForgotPasswordForm}
            />
            <Route
              path='/merchants/reset_password/:token_id'
              exact
              component={ResetPasswordForm}
            />
            <Route
              path='/create-your-slerpspace'
              exact
              component={OnboardingForm}
            />
          </Switch>
        )}
      </SessionContext.Provider>
    </Router>
  )
}

export default App
