import React from 'react'
import { Form, Col, Select, PageHeader, Tooltip } from 'antd'
import { AppSettings } from 'types'
import { QuestionCircleOutlined } from '@ant-design/icons'

const { useForm } = Form

const DEFAULT_LOCATION_PRODUCTS_VIEW = [
  { label: 'List', value: 'list', id: 0 },
  { label: 'Grid', value: 'grid', id: 1 },
  { label: 'Block', value: 'block', id: 2 }
]

interface Props {
  appSettings: AppSettings
  onChange: (values: AppSettings) => void
}

const Product = (props: Props) => {
  const [form] = useForm()
  const { appSettings, onChange } = props

  return (
    <Form
      className='_mt-48 _pl-16'
      form={form}
      data-testid='app-product-form'
      onValuesChange={onChange}
      layout='vertical'
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      scrollToFirstError={true}
      initialValues={appSettings}
    >
      <PageHeader title='Product' className='settings-title' />
      <Col span={6}>
        <Form.Item
          name='location_products_view'
          label={
            <>
              Product view:
              <Tooltip
                title='Change the layout of your products.'
                placement='right'
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </>
          }
        >
          <Select data-testid='location-products-view'>
            {DEFAULT_LOCATION_PRODUCTS_VIEW.map((option) => (
              <Select.Option key={option.id} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Form>
  )
}

export default Product
