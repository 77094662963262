import React, { useState } from 'react'

import { Col, Avatar, Row, Upload, Typography, Button } from 'antd'
import {
  PlusOutlined,
  DeleteOutlined,
  FileImageOutlined,
  LoadingOutlined
} from '@ant-design/icons'
import UploadProductImage, {
  UploadAdditionalImage,
  RemoveImage
} from '../Actions/UploadProductImage'
import { filter } from 'lodash'
import { generateAssetURL } from 'packages/@slerp/helpers/asset-url'

interface ProductImagesProps {
  productName: string
  defaultVariantId: string
  additionalImages: {
    id: string
    url: string
  }[]
  image: string
}

const AdditionalImages = ({
  defaultVariantId,
  additionalImages,
  productName
}: ProductImagesProps) => {
  const [uploadedImages, setUploadedImages] = useState(additionalImages)
  const [imageUploadLoading, setImageUploadLoading] = useState(false)

  const deleteAdditionalImage = (id: string) => () => {
    RemoveImage({ imageId: id }).then(() => {
      setUploadedImages(filter(uploadedImages, (image) => image.id !== id))
    })
  }

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Row gutter={12}>
          {(uploadedImages ?? []).map((image, index) => (
            <Col span={6} key={`uploadedImage-${index}`}>
              <div className='image-preview-container'>
                <div className='overlay'>
                  <Button
                    icon={<DeleteOutlined />}
                    type='text'
                    size='large'
                    className='image-preview-action'
                    onClick={deleteAdditionalImage(image.id)}
                  />
                </div>
                <img
                  className='preview'
                  src={generateAssetURL({
                    id: image.id,
                    type: 'image',
                    size: 'original',
                    fileName: image.url,
                    itemName: productName
                  })}
                  alt=''
                  key={`uploadedImagePreview-${index}`}
                />
              </div>
            </Col>
          ))}

          {uploadedImages.length < 4 && (
            <Col span={6}>
              <Upload
                name='avatar'
                accept='.png, .jpg, .jpeg'
                listType='picture-card'
                className='avatar-uploader -square'
                multiple
                showUploadList={false}
                beforeUpload={() => {
                  setImageUploadLoading(true)
                }}
                customRequest={({ file, onSuccess, onError }) => {
                  UploadAdditionalImage({
                    variantId: defaultVariantId,
                    image: file
                  })
                    .then((res) => {
                      if (
                        res.data?.uploadProductVariantImages.additional_images
                      ) {
                        setUploadedImages((prev) => {
                          const additionalImage =
                            res.data?.uploadProductVariantImages.additional_images.pop()

                          return [
                            ...prev,
                            {
                              id: additionalImage.id,
                              url: file.name
                            }
                          ]
                        })
                      }
                      setImageUploadLoading(false)
                      client.resetStore()
                      onSuccess(res, file)
                    })
                    .catch((error: Error) => {
                      setImageUploadLoading(false)
                      onError(error)
                    })
                }}
              >
                {imageUploadLoading ? (
                  <LoadingOutlined />
                ) : (
                  <>
                    <PlusOutlined className='icon' />
                    <Typography.Text className='title'>
                      {uploadedImages.length < 3
                        ? `Upload more photos`
                        : 'Upload'}
                    </Typography.Text>

                    <Typography.Text className='subheading'>
                      Maximum 5MB, 1024 x 1024 pixels
                    </Typography.Text>
                  </>
                )}
              </Upload>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

const ProductImages = (props: ProductImagesProps) => {
  const { defaultVariantId, productName, image } = props

  const [imageUrl, setImageUrl] = useState(
    generateAssetURL({
      id: defaultVariantId,
      type: 'variant',
      size: 'original',
      fileName: image,
      itemName: productName
    })
  )

  const [imageUploadLoading, setImageUploadLoading] = useState(false)

  const beforeImageUpload = (file: File) => {
    setImageUploadLoading(true)
    return true // this always expects a boolean return value
  }

  return (
    <>
      <Row justify='center'>
        <Col>
          <Upload
            name='avatar'
            accept='.png, .jpg, .jpeg'
            listType='picture-card'
            className='avatar-uploader'
            showUploadList={false}
            customRequest={({ file, onSuccess, onError }) =>
              UploadProductImage({
                variantId: defaultVariantId,
                image: file
              })
                .then((res) => {
                  setImageUploadLoading(false)
                  onSuccess(res, file)

                  setImageUrl(
                    generateAssetURL({
                      id: defaultVariantId,
                      type: 'variant',
                      size: 'original',
                      fileName: file.name,
                      itemName: productName
                    })
                  )
                })
                .catch((error: Error) => {
                  setImageUploadLoading(false)
                  onError(error)
                })
            }
            beforeUpload={beforeImageUpload}
          >
            {!imageUploadLoading ? (
              <Avatar size={400} shape='square' src={imageUrl} />
            ) : imageUploadLoading ? (
              <LoadingOutlined />
            ) : (
              <FileImageOutlined />
            )}
          </Upload>
        </Col>
      </Row>
      <AdditionalImages {...props} />
    </>
  )
}

export default ProductImages
