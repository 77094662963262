import React from 'react'
import Modal from 'antd/lib/modal'
import Button from 'antd/lib/button'

interface SeoLearnMoreProps {
  openMetaModal: boolean
  setOpenMetaModal: (v: boolean) => void
}

const SeoLearnMore = (props: SeoLearnMoreProps) => {
  const { openMetaModal, setOpenMetaModal } = props

  return (
    <Modal
      closable={false}
      visible={openMetaModal}
      centered
      className='meta-description-container'
      onCancel={() => setOpenMetaModal(false)}
      footer={[
        <Button type='primary' onClick={() => setOpenMetaModal(false)}>
          DONE
        </Button>
      ]}
    >
      <h2>Why are SEO Descriptions important?</h2>
      <p>
        SEO descriptions are used to generate a summary customers see when using
        a search engine. By following these best practices, you can create
        descriptions that boost visibility, increase clicks, and drive more
        relevant traffic to your site.
      </p>
      <p>A good meta description should:</p>
      <ol>
        <li>
          <b>Be relevant to the page</b>: Ensure the meta description accurately
          reflects the content on the page.
        </li>
        <br />
        <li>
          <b>Be concise</b>: Keep descriptions between 150-160 characters. Any
          longer, and they may be truncated, leaving key information out.
        </li>
        <br />
        <li>
          <b>Use relevant keywords</b>: Search engines may bold these terms in
          results, making your snippet stand out.
        </li>
        <br />
        <li>
          <b>Include a Call to Action (CTA)</b>: Encourage customers to take
          specific actions, such as "Order Now". A CTA can increase engagement
          and click-through rates.
        </li>
      </ol>
    </Modal>
  )
}

export default SeoLearnMore
