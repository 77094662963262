import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Menu from 'antd/lib/menu'
import Alert from 'antd/lib/alert'
import Layout from 'antd/lib/layout'
import Result from 'antd/lib/result'
import Avatar from 'antd/lib/avatar'
import Tooltip from 'antd/lib/tooltip'
import Divider from 'antd/lib/divider'
import BackTop from 'antd/lib/back-top'
import Dropdown from 'antd/lib/dropdown'
import React, { useState, useEffect, Suspense, lazy } from 'react'
import { Route, useLocation, Switch, Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined
} from '@ant-design/icons'
import Home from '../Home'
import Orders from '../Orders'
import Sidebar from './Sidebar'
import Loyalty from '../Loyalty'
import Settings from '../Settings'
import Locations from '../Locations'
import Customers from '../Customers'
import Discounts from '../Discounts'
import NotFound from '../Misc/NotFound'
import HelpCentre from '../Home/HelpCentre'
import AppSettings from './../AppSettings'
import OrdersPending from '../Orders/OrdersPending'
import LocationForm from '../Locations/LocationForm'
import OrdersCalendar from '../Orders/OrdersCalendar'
import FeatureNotFound from '../Misc/FeatureNotFound'
import TermsAndConditions from './TermsAndConditions'
import PrintOrder from 'components/Orders/PrintOrder'
import LocationDashboard from '../Locations/Dashboard/'
import ProductsAndModifiers from '../ProductsAndModifiers'
import MarketingInitiativeModal from '../MarketingAndSales/InitiativeModal'
import PricingTable from '../Locations/PreOrderSettings/Delivery/PricingTable'
import { DownArrowIcon } from '@slerp/assets'
import { useSession, signOut, isAdmin } from '@slerp/accounts'
import { default as LocationSettings } from '../Locations/Settings'
import { SignOut } from '../Utils/session'
import UserFlow from './../Utils/Userflow'
import StripeAlert from '../Utils/StripeAlert'
import { useHubspotChat } from '../Utils/useHubspotChat'
import { CREATE_MERCHANT_SUBSCRIPTION } from './actions'
import moment from 'moment'
import { StampLogs } from './StampLogs'
import StripeRedirectRoute from './StripeRedirectRoute'
import env from 'env'
import { usePageViewTracker } from 'packages/@slerp/tracker'
import Loading from 'components/Utils/Loading'
import { useGlobalQuery } from 'components/Utils/useGlobalQuery'

const SlerpDesignerRework = lazy(() => import('../../AppDesignerRework'))

const { Header, Content } = Layout

export interface PageHeaderType {
  title: string
  tooltipTitle?: JSX.Element
}

export interface CardType {
  id: string
  last4: number
  expMonth: number
  expYear: number
  expired?: boolean
  defaultSource: string
}

const AppContainer = () => {
  usePageViewTracker()
  const { merchant, user } = useSession()
  const {
    isLoading,
    hasExpiredDefaultCard,
    onboardingStatus,
    merchantSetting,
    errorMerchantSetting,
    hasStripeSubscription
  } = useGlobalQuery()
  const [pageHeader, setPageHeader] = useState<PageHeaderType | null>(null)
  const [collapsed, setCollapsed] = useState(true)
  const [displayMarketingModal, setDisplayMarketingModal] =
    useState<boolean>(false)
  const location = useLocation()
  const { initUserFlow, userflow } = UserFlow()
  const hubspotJWT = useHubspotChat(user)

  const [createSubscription, { loading: stripeLoading }] = useMutation(
    CREATE_MERCHANT_SUBSCRIPTION,
    {
      fetchPolicy: 'no-cache',
      refetchQueries: ['getStripeData']
    }
  )

  const isOnboarding = merchantSetting?.has_onboarded
  const merchantStoreUrl = `https://${merchant?.slug}.${env.ASSET_HOST}`
  const isIdentified = userflow.isIdentified()

  useEffect(() => {
    if (!hubspotJWT) return
    if (!merchantSetting) return

    initUserFlow({
      user,
      hasOnboarded: isOnboarding,
      onboardingStatus: onboardingStatus,
      isAdmin: isAdmin(user),
      companyName: merchantSetting.name
    })

    window.hsConversationsSettings = {
      identificationEmail: user.email,
      identificationToken: hubspotJWT
    }

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load()
    }
  }, [hubspotJWT, onboardingStatus, isOnboarding, user.inserted_at])

  useEffect(() => {
    if (!isIdentified) return
    const userInsertedAt = moment(user.inserted_at)
    const fourteenDaysAgo = moment().subtract(14, 'days')
    const timer = setTimeout(() => {
      if (userInsertedAt.isAfter(fourteenDaysAgo)) {
        userflow.openResourceCenter()
      }
    }, 7500)

    return () => clearTimeout(timer)
  }, [isIdentified])

  useEffect(() => {
    createSubscription({
      variables: {
        merchant_id: merchant.id
      }
    }).catch((error) =>
      console.error(`Unable to create subscription due to ${error}`, 5)
    )
  }, [])

  // Check JWT validity. If invalid, sign out.
  useEffect(() => {
    if (errorMerchantSetting?.graphQLErrors) {
      const [err] = errorMerchantSetting.graphQLErrors
      if (err && err?.extensions?.code === 'invalid-jwt') {
        signOut()
      }
    }

    if (merchantSetting) {
      const { marketing_modal_viewers } = merchantSetting
      setDisplayMarketingModal(
        !(marketing_modal_viewers || []).includes(user.id)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantSetting, errorMerchantSetting?.graphQLErrors])

  const [pathContext] = location?.pathname?.split('/').slice(1, 2)

  useEffect(() => {
    switch (pathContext) {
      case '':
        document.title = 'Slerp Controls - Insights'
        setPageHeader({
          title: 'Insights',
          tooltipTitle: (
            <span>
              All your insights on this dashboard can be filtered by your
              store's locations and time period.
            </span>
          )
        })
        break
      case 'heat-map':
        document.title = 'Slerp Controls - Insights'
        setPageHeader({
          title: 'Insights',
          tooltipTitle: (
            <span>
              All your insights on this dashboard can be filtered by your
              store's locations and time period.
            </span>
          )
        })
        break
      case 'settings':
        document.title = 'Slerp Controls - Settings'
        setPageHeader({
          title: 'Settings',
          tooltipTitle: (
            <span>
              Here's where you can edit the master settings for your brand's
              Slerp account.
            </span>
          )
        })
        break
      case 'orders':
        document.title = 'Slerp Controls - Orders'
        setPageHeader({
          title: 'Orders',
          tooltipTitle: (
            <span>
              A breakdown of all placed and pending orders which can be filtered
              by store, fulfilment type and date range.
            </span>
          )
        })
        break
      case 'location':
        document.title = 'Slerp Controls - Locations'
        setPageHeader({
          title: 'Locations',
          tooltipTitle: (
            <span>
              An{' '}
              <a
                href='https://support.slerp.com/en_gb/stores-and-locations-rkkCUtEpd'
                target='_blank'
                rel='noopener noreferrer'
              >
                overview
              </a>{' '}
              of your
              <br />
              stores' set up.
            </span>
          )
        })
        break
      case 'locations':
        document.title = 'Slerp Controls - Locations'
        setPageHeader({
          title: 'Locations',
          tooltipTitle: (
            <span>
              An{' '}
              <a
                href='https://support.slerp.com/en_gb/stores-and-locations-rkkCUtEpd'
                target='_blank'
                rel='noopener noreferrer'
              >
                overview
              </a>{' '}
              of your
              <br />
              stores' set up.
            </span>
          )
        })
        break
      case 'products':
        document.title = 'Slerp Controls - Products'
        setPageHeader({
          title: 'Products',
          tooltipTitle: (
            <span>
              <a
                href='https://support.slerp.com/en_gb/categories/products-H1ZdJ7G6u'
                target='_blank'
                rel='noopener noreferrer'
              >
                Here's our guides
              </a>{' '}
              for creating and managing your products. See this as your master
              library of producs that you then assign to each of your stores'
              inventories.
            </span>
          )
        })
        break
      case 'modifiers':
        document.title = 'Slerp Controls - Modifiers'
        setPageHeader({
          title: 'Modifiers',
          tooltipTitle: (
            <span>
              Modifiers are a powerful upselling tool. They allow a product to
              have multiple add ons.
              <br />
              <br />
              Find out more{' '}
              <a
                href='https://support.slerp.com/knowledge/modifiers'
                target='_blank'
                rel='noopener noreferrer'
              >
                here
              </a>
              .
            </span>
          )
        })
        break
      case 'discounts':
        document.title = 'Slerp Controls - Discounts'
        setPageHeader({
          title: 'Discounts',
          tooltipTitle: (
            <span>
              <a
                href='https://support.slerp.com/knowledge/creating-and-managing-discount-codes'
                target='_blank'
                rel='noopener noreferrer'
              >
                Discount codes
              </a>{' '}
              can be used to increase conversion, drive loyalty and win back
              customers.
              <br />
              <br />
              Did you know one of the biggest barriers to online sales is
              delivery fees?
              <br />
              <br /> Why not try a free delivery campaign to drive sales on your
              new product?
            </span>
          )
        })
        break
      case 'customers':
        document.title = 'Slerp Controls - Customers'
        setPageHeader({
          title: 'Customers',
          tooltipTitle: (
            <span>
              One of the biggest advantages of selling direct to customers
              online is you have{' '}
              <a
                href='https://support.slerp.com/en_gb/download-customer-database-SyA9W_qt'
                target='_blank'
                rel='noopener noreferrer'
              >
                access to their data
              </a>{' '}
              for your marketing efforts.
              <br />
              <br />
              Here you'll be able to see and download your customers' details
              and order frequencies. Talk to member of our team about CRM
              integrations.
            </span>
          )
        })
        break
      case 'designer':
        document.title = 'Slerp Controls - Designer'
        setPageHeader({
          title: 'Designer',
          tooltipTitle: (
            <span>
              A seamless, branded experience from order button to checkout
              drives trust which in turns drives sales.
              <br />
              <br />
              Designer enables you to fully brand and add character to your
              Slerp storefront.
              <br />
              <br />
              Learn how to optimise your store{' '}
              <a
                href='http://slerp-8156030.hs-sites.com/optimise'
                target='_blank'
                rel='noopener noreferrer'
              >
                here
              </a>
              .
            </span>
          )
        })
        break
      case 'loyalty':
        document.title = 'Slerp Controls - Loyalty'
        setPageHeader({ title: 'Loyalty' })
        break
      case 'app-settings':
        document.title = 'Slerp Controls - App Settings'
        setPageHeader({ title: 'App Settings' })
        break
      default:
        document.title = 'Slerp Controls'
        setPageHeader(null)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  const fullView = location.pathname.match('designer|help-centre')

  const userMenu = (
    <Menu>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px 12px',
          fontWeight: 'bold'
        }}
      >
        {`${user.firstname}`}
        <div
          style={{
            display: 'inline-block',
            height: '12px',
            width: '12px',
            borderRadius: '50%',
            backgroundColor: 'lightgreen',
            margin: '0 8px 0 24px'
          }}
        ></div>
      </div>
      <Menu.Item key='general-settings'>
        <Link to='/settings/general'>
          <span>Settings</span>
        </Link>
      </Menu.Item>
      <Divider className='_mt-0 _mb-0' />
      <Menu.Item key='sign-out'>
        <SignOut>
          <span>Sign Out</span>
        </SignOut>
      </Menu.Item>
    </Menu>
  )

  const Pending = () => (
    <Result
      status='info'
      title='This page is coming soon'
      subTitle='Sorry, but we are still working on this page. Please come back later.'
    />
  )

  if (pathContext === 'print-order')
    return <Route path='/print-order/:order_id' exact component={PrintOrder} />

  return (
    <Layout hasSider>
      <Sidebar collapsed={collapsed} />
      <StampLogs />
      {!isLoading && !hasStripeSubscription && (
        <StripeAlert
          loading={stripeLoading}
          hasSubscription={hasStripeSubscription}
        />
      )}
      <Layout
        className='site-layout'
        style={{ marginLeft: collapsed ? 65 : 240 }}
      >
        <Header className='site-layout-background main-header'>
          <Row align='middle' justify='space-between' className='_mb-0'>
            <Col>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: toggle
                }
              )}
              <a
                href={merchantStoreUrl}
                target='_blank'
                rel='noopener noreferrer'
                className={`location-link ${
                  (!hasStripeSubscription && 'disabled') || ''
                }`}
              >
                Visit your location
              </a>
            </Col>
            <Col className='user-notifications'>
              <Dropdown overlay={userMenu} trigger={['click']}>
                <div className='user-block'>
                  <Avatar
                    className='ant-menu-item-selected ant-menu-item-active'
                    size='large'
                    icon={<UserOutlined style={{ color: '#fff' }} />}
                    style={{ cursor: 'pointer', marginLeft: '16px' }}
                  />
                  <div>
                    <p>{merchantSetting?.name} </p>
                    <span>{`${user.firstname}`}</span>
                  </div>
                  <div>
                    <DownArrowIcon />
                  </div>
                </div>
              </Dropdown>
            </Col>
          </Row>
          {hasExpiredDefaultCard && (
            <Alert
              message={
                <>
                  Your card on file has expired. To continue transacting with
                  Slerp, please update your credit card. Click{' '}
                  <Link to='/settings/accounts-and-billing'>
                    <strong>here</strong>
                  </Link>{' '}
                  to update
                </>
              }
              type='warning'
              className='expiry-warning'
            />
          )}
        </Header>
        {pageHeader && (
          <>
            <div
              className={`page-title ${
                hasExpiredDefaultCard && 'with-expired'
              }`}
            >
              <Tooltip
                placement='right'
                title={pageHeader?.tooltipTitle}
                overlayStyle={{
                  visibility: `${
                    pageHeader?.tooltipTitle ? 'visible' : 'hidden'
                  }`
                }}
              >
                <span className='header'>{pageHeader?.title}</span>
              </Tooltip>
            </div>
          </>
        )}
        {displayMarketingModal && location.pathname === '/' && (
          <MarketingInitiativeModal />
        )}
        <Content
          className={`site-content -main ${
            (window.location.pathname === '/' ||
              window.location.pathname === '/heat-map') &&
            '-dashboard'
          }`}
          style={{
            padding: fullView ? '0' : 24,
            overflow: fullView ? 'hidden' : 'auto'
          }}
        >
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path='/' exact component={Home} />
              <Route path='/:heat-map' exact component={Home} />
              <Route path='/pending' component={OrdersPending} />
              <Route path='/calendar' exact component={OrdersCalendar} />
              <Route path='/orders' exact component={Orders} />
              <Route path='/designer' exact component={SlerpDesignerRework} />
              <Route path='/products' exact component={ProductsAndModifiers} />
              <Route path='/modifiers' exact component={ProductsAndModifiers} />

              <Route path='/discounts' component={Discounts} />
              <Route path='/customers' component={Customers} />
              <Route path='/loyalty'>
                <Loyalty />
              </Route>
              <Route path='/locations' exact component={Locations} />
              <Route path='/locations/new' component={LocationForm} />
              <StripeRedirectRoute path='/locations/stripe/connect' />
              <Route
                path='/location/:slug'
                exact
                component={LocationDashboard}
              />
              <Route
                path='/location/:slug/settings'
                component={LocationSettings}
              />
              <Route
                path='/location/:slug/slerp-optimize-delivery-pricing/:id'
                component={PricingTable}
              />
              <Route path='/settings' component={() => <Settings />} />
              <Route
                path='/app-settings'
                component={() =>
                  merchantSetting?.app_settings[0]?.enabled ? (
                    <AppSettings />
                  ) : (
                    <FeatureNotFound />
                  )
                }
              />
              <Route path='/account' component={Pending} />
              <Route path='/help-centre' component={HelpCentre} />
              <Route
                path='/sign-out'
                component={() => {
                  signOut()
                  return <></>
                }}
              />
              <Route component={NotFound} />
            </Switch>
          </Suspense>

          <BackTop />
        </Content>
        <TermsAndConditions
          isAdmin={isAdmin(user)}
          merchantId={merchant?.id}
          merchantName={merchant?.name}
          registeredCompanyName={merchantSetting?.registered_company_name}
        />
      </Layout>
    </Layout>
  )
}

export default AppContainer
