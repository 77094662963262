import React, { useState, useEffect } from 'react'
import { useSlerp } from '@slerp/client'
import { useQuery, useMutation } from '@apollo/client'
import { uuid } from 'uuidv4'
import env from '../../env'
import moment from 'moment-timezone'
import isEmpty from 'lodash/isEmpty'
import {
  Alert,
  Avatar,
  Button,
  Radio,
  Form,
  Input,
  Select,
  Switch,
  Space,
  Row,
  Col,
  Upload,
  message,
  Tabs,
  Spin
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { requiredRule, nonNegativeRule } from './Rules'
import {
  PlusOutlined,
  FileImageOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import {
  QUERY_APP_SETTINGS,
  CREATE_APP_SETTINGS,
  CREATE_BASIC_APP_SETTINGS,
  UPDATE_APP_SETTINGS,
  UPDATE_BASIC_APP_SETTINGS
} from './AppSettingsQueries'
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis
} from '@dnd-kit/modifiers'
import AppSettingsDraggable from './AppSettingsDraggable'
import Campaigns from './Campaigns'
import Designer from './Designer'
import Loading from './../Utils/Loading'
import {
  Section,
  AppSettings as AppSettingsType,
  BasicAppSettings as BasicAppSettingsType
} from 'types'
import { appSettingsSuccessMessage } from './utils'
import { useGlobalQuery } from 'components/Utils/useGlobalQuery'

interface FormType {
  custom_content_enabled: boolean
  sections: Section[]
}

const { useForm } = Form
const { TabPane } = Tabs
const DEFAULT_LANDING_OPTIONS = [
  { label: 'Cards', value: 'cards', id: 0 },
  { label: 'Rewards', value: 'rewards', id: 1 },
  { label: 'Locations', value: 'locations', id: 2 },
  { label: 'Log in', value: 'login', id: 3 }
]
const DEFAULT_FORM_VALUES = {
  hide_status_bar: null,
  footer_nav: null,
  splash_additional_button_text: '',
  splash_additional_button_link: '',
  location_products_view: ''
}

const AppSettings = () => {
  const { user } = useSlerp()
  const { isLoading } = useGlobalQuery()
  const { data, loading, refetch } = useQuery(QUERY_APP_SETTINGS, {
    variables: { merchantId: user.merchant.id },
    fetchPolicy: 'no-cache'
  })

  const appSettings = data?.app_settings[0]?.id
    ? data?.app_settings[0]
    : DEFAULT_FORM_VALUES
  const [form] = useForm()

  const [customContentEnabled, setCustomContentEnabled] = useState(
    appSettings?.custom_content_enabled || false
  )
  const [sectionImages, setSectionImages] = useState([null])
  const [imageUrls, setImageUrls] = useState([null])
  const [submitDisabled, setSubmitDisabled] = useState(false)

  const [expandedItemList, setExpandedItemList] = useState<string[]>([])

  const appSections =
    appSettings?.sections
      ?.map((section) => ({
        ..._.omit(section, ['inserted_at', 'updated_at'])
      }))
      .sort((sec_a, sec_b) => {
        const rankA = sec_a ? Number(sec_a.rank) : 0
        const rankB = sec_a ? Number(sec_b.rank) : 0
        return rankA - rankB
      }) || []

  const [sectionsArrangement, setSectionsArrangement] = useState<
    Array<Section>
  >([])

  const [createAppSettings] = useMutation(CREATE_APP_SETTINGS, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      { query: QUERY_APP_SETTINGS, variables: { merchantId: user.merchant.id } }
    ]
  })

  const [createBasicAppSettings] = useMutation(CREATE_BASIC_APP_SETTINGS, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      { query: QUERY_APP_SETTINGS, variables: { merchantId: user.merchant.id } }
    ]
  })

  const [updateAppSettings, { loading: isLoadingUpdateAppSettings }] =
    useMutation(UPDATE_APP_SETTINGS, {
      fetchPolicy: 'no-cache',
      refetchQueries: [
        {
          query: QUERY_APP_SETTINGS,
          variables: { merchantId: user.merchant.id }
        }
      ]
    })

  const [updateBasicAppSettings, { loading: isLoadingUpdateBasicAppSettings }] =
    useMutation(UPDATE_BASIC_APP_SETTINGS, {
      fetchPolicy: 'no-cache',
      refetchQueries: [
        {
          query: QUERY_APP_SETTINGS,
          variables: { merchantId: user.merchant.id }
        }
      ]
    })

  // array of event types a draggable will listen to
  const sensors = [useSensor(PointerSensor)]

  useEffect(() => {
    setSectionsArrangement(appSections)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSettings])

  const onFinish = (values: AppSettingsType) => {
    const newAppSettings = { ...appSettings, ...values }

    if (customContentEnabled && sectionsArrangement.length === 0)
      return message.error(
        'Unable to save, please add a custom content section first.'
      )
    setSubmitDisabled(true)
    if (isEmpty(newAppSettings.id)) {
      create(newAppSettings)
        .then((result) => {
          message.success('Custom content created!')
          setSubmitDisabled(false)
        })
        .catch((error) => {
          message.error('Unable to create custom content.')
          console.error('CREATE_CUSTOM_CONTENT:', error)
          setSubmitDisabled(false)
        })
    } else {
      update(newAppSettings)
        .then((result) => {
          message.success('App settings updated!')
          setSubmitDisabled(false)
        })
        .catch((error) => {
          message.error('Unable to update app settings.')
          console.error('UPDATE_CUSTOM_CONTENT:', error)
          setSubmitDisabled(false)
        })
    }
  }

  const create = (values: FormType) => {
    const sectionsWithImages =
      values?.sections?.length > 0
        ? values.sections.reduce((acc: Section[], section: Section) => {
            if (section)
              return [
                ...acc,
                { ...section, image: section?.image?.file?.originFileObj }
              ]
            return acc
          }, [])
        : []
    const sectionsWithRank = sectionsWithImages.map(
      (section: Section, index: number) => ({
        ...section,
        rank: index,
        image_key: uuid()
      })
    )
    const sectionsOmmittedUnchosenLink = sectionsWithRank.map(
      (section: Section) => {
        const ommittedLink =
          section?.link_type === 'app'
            ? { custom_link: null }
            : { app_link: null }
        return { ...section, ...ommittedLink }
      }
    )
    const formValues = {
      ...values,
      custom_content_enabled: customContentEnabled,
      sections: sectionsOmmittedUnchosenLink
    }

    return createAppSettings({
      variables: {
        ...formValues,
        merchantId: user.merchant.id
      }
    })
  }

  const update = (values) => {
    const sectionsWithImages =
      values?.sections?.length > 0
        ? values.sections.reduce((acc: Section[], section: Section) => {
            const imageArgs = section?.image?.file?.originFileObj
              ? { ...section, image: section?.image?.file?.originFileObj }
              : _.omit(section, 'image')
            if (section) return [...acc, { ...imageArgs }]
            return acc
          }, [])
        : []
    const sectionsWithRank = sectionsWithImages.map(
      (section: Section, index: number) => ({
        ...section,
        rank: sectionsArrangement[index].rank,
        image_key: uuid()
      })
    )
    const sectionsOmmittedUnchosenLink = sectionsWithRank.map(
      (section: Section) => {
        const ommittedLink =
          section?.link_type === 'app'
            ? { custom_link: null }
            : { app_link: null }
        return { ...section, ...ommittedLink }
      }
    )
    const formValues = {
      ...values,
      custom_content_enabled: customContentEnabled,
      sections: sectionsOmmittedUnchosenLink
    }

    return updateAppSettings({
      variables: {
        ...formValues,
        id: appSettings?.id
      }
    })
  }

  const beforeImageUpload = (file: File, index) => {
    setSectionImages(
      sectionImages.map((value, id) => {
        if (index === id) return file
        return value
      })
    )

    // this always expects a boolean return value
    return true
  }

  const handleImageRemove = (index: number) => {
    setSectionImages(
      sectionImages.map((value, id) => {
        if (id === index) return null
        return value
      })
    )

    setImageUrls(
      sectionImages.map((value, id) => {
        if (id === index) return null
        return value
      })
    )
  }

  const renderDisplayImage = (index) => {
    const image = sectionImages?.length > 0 && sectionImages[index]
    const imageUrl = imageUrls?.length > 0 && imageUrls[index]

    if (image) {
      return <img src={URL.createObjectURL(image)} alt='loyalty display' />
    } else if (imageUrl) {
      return <Avatar shape='square' src={imageUrl} />
    } else {
      return <FileImageOutlined />
    }
  }

  useEffect(() => {
    setCustomContentEnabled(appSettings?.custom_content_enabled)
  }, [appSettings])

  useEffect(() => {
    if (appSections && appSections.length > 0) {
      const images = appSections.map((section: Section) => null)
      const imageUrls = appSections.map(
        (section: Section) =>
          `https://${user.merchant.slug}.${env.ASSET_HOST}/assets/app_setting/${
            appSettings.id
          }/section/${section?.id}?size=original&${moment().unix()}`
      )
      setSectionImages(images)
      setImageUrls(imageUrls)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSettings])

  const initialValues = () => {
    if (appSettings) {
      const values = {
        ...appSettings,
        sections: appSections
      }

      return values
    }

    return {
      custom_content_enabled: false,
      sections: []
    }
  }

  const handleSectionRemove = (index: number) => {
    setSectionImages([
      ...sectionImages.slice(0, index),
      ...sectionImages.slice(index + 1, sectionImages.length)
    ])

    setImageUrls([
      ...imageUrls.slice(0, index),
      ...imageUrls.slice(index + 1, imageUrls.length)
    ])
  }

  const handleSectionAdd = (
    add: (defaultValue?: any, insertIndex?: number | undefined) => void
  ) => {
    setSectionImages([...sectionImages, null])
    setImageUrls([...imageUrls, null])

    const newSection = {
      id: uuid(),
      title: null,
      link_type: 'custom' as 'custom',
      app_link: null,
      custom_link: null,
      image: null,
      rank: sectionsArrangement.length,
      full_page: false
    }

    add(newSection)
    setSectionsArrangement((prevState) => [...prevState, newSection])
    setExpandedItemList((list) => [...list, newSection.id])
  }

  const onChange = (values: BasicAppSettingsType) => {
    if (!isEmpty(appSettings?.id)) {
      updateBasicAppSettings({
        variables: {
          updateValues: { ...values },
          id: appSettings.id
        }
      })
        .then(() => {
          const key = Object.keys(values)[0]
          const value = Object.values(values)[0]

          message.success(appSettingsSuccessMessage(key, value), 2)
        })
        .catch((error) => {
          message.destroy()
          message.error(
            `Unable to update app settings due to ${error.message}.`,
            3
          )
        })
    } else {
      let newValues = { ...values }

      createBasicAppSettings({
        variables: {
          object: {
            ...newValues,
            id: uuid(),
            merchant_id: user.merchant.id,
            inserted_at: 'now()',
            updated_at: 'now()'
          }
        }
      })
        .then(() => refetch())
        .catch((error) => {
          message.destroy()
          message.error(
            `Unable to create app settings due to ${error.message}.`,
            3
          )
        })
    }
  }

  if (loading || isLoading) return <Loading />

  return (
    <Tabs type='card' defaultActiveKey='1' className='app-settings'>
      <TabPane
        key='1'
        tab={
          <span data-testid='app-custom-content-tab-pane'>CUSTOM CONTENT</span>
        }
      >
        <Spin
          size='large'
          tip='Saving app settings...'
          indicator={<LoadingOutlined spin />}
          spinning={
            isLoadingUpdateAppSettings || isLoadingUpdateBasicAppSettings
          }
        >
          <Col className='_pl-16 _mt-48' span={12}>
            <Col span={16} className='_mb-32'>
              <Alert
                type='warning'
                message={
                  <>
                    Layout may not be modified immediately. You may need to
                    close and re-open the app on your device to view your
                    changes. Any changes you make are instant and live to your
                    audience.
                  </>
                }
              />
            </Col>

            <Row align='middle' gutter={12} className='_mb-0 _pt-4 _pb-4 _pl-4'>
              <Col>
                <Switch
                  checked={customContentEnabled}
                  onChange={(checked: boolean) => {
                    setCustomContentEnabled(checked)
                    onChange({ custom_content_enabled: checked })
                  }}
                />
              </Col>
              <Col>Custom content</Col>
            </Row>
          </Col>

          <Form
            className='_mt-48 _pl-16'
            form={form}
            data-testid='app-general-form'
            onFinish={onFinish}
            layout='vertical'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            scrollToFirstError={true}
            initialValues={initialValues()}
          >
            <Form.List name='sections'>
              {(fields, { add, remove, move }) => (
                <>
                  {customContentEnabled && (
                    <Col span={8}>
                      <DndContext
                        modifiers={[
                          restrictToVerticalAxis,
                          restrictToFirstScrollableAncestor
                        ]}
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={({ active, over }) => {
                          if (active.id !== over?.id) {
                            setSectionsArrangement((sections) => {
                              const oldIndex = sections.findIndex(
                                (section: Section) => section.id === active.id
                              )
                              const newIndex = sections.findIndex(
                                (section: Section) => section.id === over?.id
                              )
                              const newArrangement = arrayMove(
                                sections,
                                oldIndex,
                                newIndex
                              ).map((section: Section, index) => {
                                renderDisplayImage(index)

                                return {
                                  ...section,
                                  rank: index
                                }
                              })
                              move(oldIndex, newIndex)

                              const newImageArrangement = arrayMove(
                                sectionImages,
                                oldIndex,
                                newIndex
                              )
                              const newImageURLsArrangement = arrayMove(
                                imageUrls,
                                oldIndex,
                                newIndex
                              )

                              setSectionImages(newImageArrangement)
                              setImageUrls(newImageURLsArrangement)

                              return newArrangement
                            })
                          }
                        }}
                      >
                        {fields.map((field, index) => (
                          <SortableContext
                            items={sectionsArrangement.map(
                              (section) => section.id
                            )}
                            strategy={verticalListSortingStrategy}
                            key={field.key}
                          >
                            <AppSettingsDraggable
                              itemId={sectionsArrangement[index]?.id}
                              headerTitle={sectionsArrangement[index]?.title}
                              expandedItemList={expandedItemList}
                              setExpandedItemList={setExpandedItemList}
                            >
                              <Form.Item
                                name={[field.name, 'title']}
                                fieldKey={[field.key, 'title']}
                                label='Section title:'
                                className='_mb-24'
                              >
                                <Input type='text' />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, 'image']}
                                fieldKey={[field.key, 'image']}
                                label='Upload image:'
                                rules={[
                                  {
                                    required: true,
                                    validator: (_: any, val: string) => {
                                      return imageUrls[field.name] ||
                                        sectionImages[field.name]
                                        ? Promise.resolve()
                                        : Promise.reject(
                                            new Error('Please add a photo')
                                          ).then(
                                            () => {
                                              // do nothing
                                            },
                                            (error) => {
                                              setExpandedItemList((prev) => [
                                                ...prev,
                                                sectionsArrangement[field.name]
                                                  .id
                                              ])
                                              return Promise.reject(error)
                                            }
                                          )
                                    }
                                  }
                                ]}
                                className='_mb-24'
                              >
                                <Upload
                                  key={field.key}
                                  accept='.png, .jpg, .jpeg'
                                  defaultFileList={
                                    sectionImages?.length > 0 &&
                                    sectionImages[index]
                                      ? [sectionImages[index]]
                                      : []
                                  }
                                  fileList={
                                    sectionImages?.length > 0 &&
                                    sectionImages[index]
                                      ? [sectionImages[index]]
                                      : []
                                  }
                                  onRemove={() => handleImageRemove(index)}
                                  listType='picture-card'
                                  className='avatar-uploader'
                                  showUploadList={false}
                                  beforeUpload={(file) =>
                                    beforeImageUpload(file, index)
                                  }
                                >
                                  {renderDisplayImage(index)}
                                </Upload>
                              </Form.Item>
                              <Form.Item
                                name={[field.name, 'link_type']}
                                fieldKey={[field.key, 'link_type']}
                                label='Link to:'
                                rules={[
                                  {
                                    required: true,
                                    validator: (_: any, val: string) => {
                                      return !!val
                                        ? Promise.resolve()
                                        : Promise.reject(
                                            new Error(
                                              'This is a required field'
                                            )
                                          ).then(
                                            () => {
                                              // do nothing
                                            },
                                            (error) => {
                                              setExpandedItemList((prev) => {
                                                const merged = [
                                                  ...prev,
                                                  sectionsArrangement[
                                                    field.name
                                                  ].id
                                                ]

                                                const removedDuplicates =
                                                  new Set(merged)

                                                return [...removedDuplicates]
                                              })
                                              return Promise.reject(error)
                                            }
                                          )
                                    }
                                  }
                                ]}
                                className='_mb-32'
                              >
                                <Radio.Group buttonStyle='solid'>
                                  <Space direction='vertical' align='start'>
                                    <Radio value='custom'>External page</Radio>
                                    <Radio value='app'>App page</Radio>
                                  </Space>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) =>
                                  prevValues?.sections[field.name]
                                    ?.link_type !==
                                  currentValues?.sections[field.name]?.link_type
                                }
                              >
                                {({ getFieldValue }) =>
                                  getFieldValue('sections')[index]
                                    ?.link_type === 'custom' ? (
                                    <Form.Item
                                      name={[field.name, 'custom_link']}
                                      fieldKey={[field.key, 'custom_link']}
                                      label='External link'
                                      rules={[
                                        {
                                          required: true,
                                          validator: (_: any, val: string) => {
                                            return !!val
                                              ? Promise.resolve()
                                              : Promise.reject(
                                                  new Error(
                                                    'This is a required field'
                                                  )
                                                ).then(
                                                  () => {
                                                    // do nothing
                                                  },
                                                  (error) => {
                                                    setExpandedItemList(
                                                      (prev) => {
                                                        const merged = [
                                                          ...prev,
                                                          sectionsArrangement[
                                                            field.name
                                                          ].id
                                                        ]

                                                        const removedDuplicates =
                                                          new Set(merged)

                                                        return [
                                                          ...removedDuplicates
                                                        ]
                                                      }
                                                    )
                                                    return Promise.reject(error)
                                                  }
                                                )
                                          }
                                        }
                                      ]}
                                    >
                                      <Input type='text' />
                                    </Form.Item>
                                  ) : (
                                    <Form.Item
                                      name={[field.name, 'app_link']}
                                      fieldKey={[field.key, 'app_link']}
                                      label='Link to page:'
                                      rules={[
                                        {
                                          required: true,
                                          validator: (_: any, val: string) => {
                                            return !!val
                                              ? Promise.resolve()
                                              : Promise.reject(
                                                  new Error(
                                                    'This is a required field'
                                                  )
                                                ).then(
                                                  () => {
                                                    // do nothing
                                                  },
                                                  (error) => {
                                                    setExpandedItemList(
                                                      (prev) => {
                                                        const merged = [
                                                          ...prev,
                                                          sectionsArrangement[
                                                            field.name
                                                          ].id
                                                        ]

                                                        const removedDuplicates =
                                                          new Set(merged)

                                                        return [
                                                          ...removedDuplicates
                                                        ]
                                                      }
                                                    )
                                                    return Promise.reject(error)
                                                  }
                                                )
                                          }
                                        }
                                      ]}
                                    >
                                      <Select>
                                        {DEFAULT_LANDING_OPTIONS.map(
                                          (option) => (
                                            <Select.Option
                                              key={option.id}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </Select.Option>
                                          )
                                        )}
                                      </Select>
                                    </Form.Item>
                                  )
                                }
                              </Form.Item>
                              {/* Nesting Form.Item is required to keep the Switch's value binding - do not remove */}
                              <Form.Item
                                name={[field.name, 'rank']}
                                fieldKey={[field.key, 'rank']}
                                label='Section rank'
                                rules={[...requiredRule, ...nonNegativeRule]}
                                hidden
                              >
                                <Input type='number' min={0} step={1} />
                              </Form.Item>
                              <Row className='_mb-0 _mt-24'>
                                <Form.Item>
                                  <Button
                                    type='link'
                                    onClick={() => {
                                      remove(index)
                                      const fieldsValue = form.getFieldsValue()

                                      const filteredSections =
                                        fieldsValue.sections.filter(
                                          (section: Section) => section.id
                                        )

                                      const updateSectionsRank =
                                        filteredSections.map(
                                          (
                                            section: Section,
                                            index: number
                                          ) => ({
                                            ...section,
                                            rank: index
                                          })
                                        )

                                      handleSectionRemove(index)

                                      form.setFieldsValue({
                                        sections: updateSectionsRank
                                      })

                                      setSectionsArrangement(updateSectionsRank)
                                    }}
                                    icon={<DeleteOutlined />}
                                    className='_center-vertical remove-section'
                                  >
                                    Remove section
                                  </Button>
                                </Form.Item>
                              </Row>
                            </AppSettingsDraggable>
                          </SortableContext>
                        ))}
                      </DndContext>

                      <Row className='_mt-24'>
                        <Form.Item>
                          <Button
                            type='dashed'
                            onClick={() => handleSectionAdd(add)}
                            icon={<PlusOutlined />}
                            disabled={submitDisabled}
                          >
                            Add custom section
                          </Button>
                        </Form.Item>
                      </Row>
                    </Col>
                  )}
                </>
              )}
            </Form.List>
            {customContentEnabled && (
              <Col span={8} className='_mt-40'>
                <Row justify='end'>
                  <Form.Item>
                    <Button
                      title='Save'
                      type='primary'
                      htmlType='submit'
                      disabled={submitDisabled}
                    >
                      Save Custom Content
                    </Button>
                  </Form.Item>
                </Row>
              </Col>
            )}
          </Form>
        </Spin>
      </TabPane>
      <TabPane
        key='2'
        tab={<a data-testid='app-notifications-tab-pane'>CAMPAIGNS</a>}
      >
        <Campaigns />
      </TabPane>
      <TabPane
        key='3'
        tab={<a data-testid='app-designer-tab-pane'>DESIGNER</a>}
      >
        <Designer appSettings={appSettings} onChange={onChange} />
      </TabPane>
    </Tabs>
  )
}

export default AppSettings
