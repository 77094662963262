import React, { useState, useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { Row, Col, Drawer, Tooltip } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import partition from 'lodash/partition'
import { useSession } from '@slerp/accounts'
import { Discount, DiscountUsages } from '@slerp/controls'
import { useQuery } from '@apollo/client'
import { QUERY_MERCHANT_DISCOUNTS } from '../Merchants/MerchantQueries'
import DiscountTabs from './DiscountTabs'
import Codes from './Codes'
import Loading from '../Utils/Loading'
import DiscountForm from './DiscountForm'
import { useGlobalQuery } from 'components/Utils/useGlobalQuery'

type NumberOfUses = Record<string, number>

const Discounts = () => {
  const { merchant } = useSession()
  const history = useHistory()
  const { isLoading } = useGlobalQuery()

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [drawerTitle, setDrawerTitle] = useState('Discount')
  const [discountCodes, setDiscountCodes] = useState<Discount[] | null>(null)
  const [automaticDiscounts, setAutomaticDiscounts] = useState<
    Discount[] | null
  >(null)
  const [loyaltyRewardsDiscounts, setLoyaltyRewardsDiscounts] = useState<
    Discount[] | null
  >(null)
  const [numberOfUses, setNumberOfUses] = useState<NumberOfUses | null>(null)

  const { data, loading: isLoadingDiscounts } = useQuery<{
    discounts: Discount[] | null
    discount_usages: [DiscountUsages]
  }>(QUERY_MERCHANT_DISCOUNTS, {
    variables: { merchantId: merchant.id },
    fetchPolicy: 'no-cache',
    onCompleted: ({ discounts, discount_usages }) => {
      if (!discounts) return

      const [automatic, filtered] = partition(
        discounts,
        (disc) => disc.trigger === 'automatic'
      )
      const [loyalty, manual] = partition(
        filtered,
        (disc) => disc.customer_reward_id != null
      )

      discount_usages?.forEach(({ discount_usages }) => {
        if (discount_usages) {
          const { discount_id, count } = discount_usages
          setNumberOfUses((prev) => ({ ...prev, [discount_id]: count }))
        }
      })

      setDiscountCodes(manual)
      setAutomaticDiscounts(automatic)
      setLoyaltyRewardsDiscounts(loyalty)
    },
    notifyOnNetworkStatusChange: true
  })

  const setDrawerVisibility = (visible: boolean) => {
    setDrawerVisible(visible)
  }

  useEffect(() => {
    if (
      history.location.pathname.includes('discounts/code/edit') ||
      history.location.pathname.includes('discounts/code/new')
    )
      return setDrawerVisible(true)
  }, [history.location.pathname])

  if (isLoadingDiscounts || isLoading) return <Loading />

  const DiscountFormRoutes = () => (
    <>
      <Route
        path='/discounts/code/new'
        component={() => {
          setDrawerTitle('Add Discount')
          return <DiscountForm setDrawerVisibility={setDrawerVisibility} />
        }}
      />
      <Route
        path='/discounts/code/edit/:discount_id'
        component={() => {
          setDrawerTitle('Update Discount')
          return <DiscountForm setDrawerVisibility={setDrawerVisibility} />
        }}
      />
    </>
  )

  return (
    <>
      <Row data-testid='discounts-component'>
        {(data && data.discounts && history && (
          <>
            <Col span={24}>
              <DiscountTabs
                codes={
                  <Codes
                    discounts={discountCodes}
                    numberOfUses={numberOfUses}
                  />
                }
                automatic={
                  <Codes
                    discounts={automaticDiscounts}
                    numberOfUses={numberOfUses}
                  />
                }
                loyalty={
                  <Codes
                    discounts={loyaltyRewardsDiscounts}
                    numberOfUses={numberOfUses}
                  />
                }
              />
            </Col>
          </>
        )) || <Loading />}
      </Row>
      <Drawer
        visible={drawerVisible}
        onClose={() => {
          setDrawerVisible(false)
          history.replace('/discounts')
        }}
        width={720}
        title={drawerTitle}
        data-testid='discounts-form'
        closeIcon={
          <Tooltip title={<span>Be sure to hit save! </span>}>
            <CloseOutlined className='close-icon' />
          </Tooltip>
        }
        className='discounts-drawer'
      >
        <DiscountFormRoutes />
      </Drawer>
    </>
  )
}

export default Discounts
