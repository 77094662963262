import { gql } from '@apollo/client'

export const UPDATE_SETTINGS = gql`
  mutation updateSettings(
    $merchant_id: uuid!
    $id: uuid!
    $settings: jsonb!
    $order_with_google: Boolean
  ) {
    update_stores(
      where: { merchant_id: { _eq: $merchant_id }, id: { _eq: $id } }
      _append: { settings: $settings }
    ) {
      affected_rows
      returning {
        settings
      }
    }
  }
`

export const buildUpdateAdvancedSettings = (
  has_store_integration_settings: boolean
) => {
  const storeIntegrationSettingsMutation = has_store_integration_settings
    ? `
  update_store_integration_settings(
    where: { store_id: { _eq: $store_id } }
    _set: { order_with_google: $order_with_google }
  ) {
    returning {
      store_id
      order_with_google
    }
  }
  `
    : `
  insert_store_integration_settings(
    objects: {
      id: $id, 
      store_id: $store_id, 
      order_with_google: $order_with_google, 
      inserted_at: "now()", 
      updated_at: "now()"
  }) {
    affected_rows
  }

  `

  return gql`
    mutation updateAdvancedSettings(
      $merchant_id: uuid!
      $store_id: uuid!
      $settings: jsonb!
      $order_with_google: Boolean!
      $id: uuid
    ) {
      update_stores(
        where: { merchant_id: { _eq: $merchant_id }, id: { _eq: $store_id } }
        _append: { settings: $settings }
      ) {
        affected_rows
        returning {
          settings
        }
      }
      ${storeIntegrationSettingsMutation}
    }
  `
}

export const UPDATE_SETTINGS_AND_SAMEDAY_PICKUP_MOV = gql`
  mutation updateSettings(
    $merchant_id: uuid!
    $id: uuid!
    $settings: jsonb!
    $pickup_sameday_mov: numeric!
  ) {
    update_stores(
      where: { merchant_id: { _eq: $merchant_id }, id: { _eq: $id } }
      _append: { settings: $settings }
      _set: { sameday_pickup_minimum_order_value: $pickup_sameday_mov }
    ) {
      affected_rows
      returning {
        settings
      }
    }
  }
`

export const UPDATE_SETTINGS_AND_MINIMUM_ORDER_VALUE = gql`
  mutation updateSettings(
    $merchant_id: uuid!
    $id: uuid!
    $settings: jsonb!
    $sameday_delivery_minimum_order_value: numeric
  ) {
    update_stores(
      where: { merchant_id: { _eq: $merchant_id }, id: { _eq: $id } }
      _append: { settings: $settings }
      _set: {
        sameday_delivery_minimum_order_value: $sameday_delivery_minimum_order_value
      }
    ) {
      affected_rows
      returning {
        settings
      }
    }
  }
`

export const UPDATE_STORE_SETTINGS_WITH_AREA = gql`
  mutation UpdateStoreSettings(
    $store_id: uuid!
    $busy_delivery_area_enabled: Boolean
    $custom_delivery_area: Boolean
    $kml_upload: Boolean
    $busy_preparation_time: Int
    $moderate_preparation_time: Int
    $quiet_preparation_time: Int
    $delivery_interval: Int
    $pickup_interval: Int
    $prep_mode: ValidPrepModes
    $same_day_pickup: Boolean
    $delivery_area: [DeliveryAreaInputObject]
  ) {
    updateStoreSettings(
      storeId: $store_id
      busyDeliveryAreaEnabled: $busy_delivery_area_enabled
      customDeliveryArea: $custom_delivery_area
      kmlUpload: $kml_upload
      busyPreparationTime: $busy_preparation_time
      moderatePreparationTime: $moderate_preparation_time
      quietPreparationTime: $quiet_preparation_time
      deliveryInterval: $delivery_interval
      pickupInterval: $pickup_interval
      prepMode: $prep_mode
      sameDayPickup: $same_day_pickup
      deliveryArea: $delivery_area
    ) {
      id
    }
  }
`

export const UPDATE_STORE_SETTINGS_WITH_MILE_RADIUS = gql`
  mutation UpdateStoreSettings(
    $store_id: uuid!
    $delivery_radius: Float
    $busy_delivery_area_enabled: Boolean
    $custom_delivery_area: Boolean
    $kml_upload: Boolean
    $busy_preparation_time: Int
    $moderate_preparation_time: Int
    $quiet_preparation_time: Int
    $delivery_interval: Int
    $pickup_interval: Int
    $prep_mode: ValidPrepModes
    $same_day_pickup: Boolean
    $delivery_area: [DeliveryAreaInputObject]
  ) {
    updateStoreSettings(
      storeId: $store_id
      deliveryRadius: $delivery_radius
      busyDeliveryAreaEnabled: $busy_delivery_area_enabled
      customDeliveryArea: $custom_delivery_area
      busyPreparationTime: $busy_preparation_time
      moderatePreparationTime: $moderate_preparation_time
      quietPreparationTime: $quiet_preparation_time
      deliveryInterval: $delivery_interval
      pickupInterval: $pickup_interval
      prepMode: $prep_mode
      sameDayPickup: $same_day_pickup
      deliveryArea: $delivery_area
      kmlUpload: $kml_upload
    ) {
      id
    }
  }
`

export const UPDATE_STORE_SETTINGS_WITH_MINUTES_RADIUS = gql`
  mutation UpdateStoreSettings(
    $store_id: uuid!
    $custom_delivery_area: Boolean
    $kml_upload: Boolean
    $busy_preparation_time: Int
    $moderate_preparation_time: Int
    $quiet_preparation_time: Int
    $delivery_time: Int
    $busy_delivery_area_enabled: Boolean
    $delivery_interval: Int
    $pickup_interval: Int
    $prep_mode: ValidPrepModes
    $same_day_pickup: Boolean
  ) {
    updateStoreSettings(
      storeId: $store_id
      customDeliveryArea: $custom_delivery_area
      kmlUpload: $kml_upload
      busyPreparationTime: $busy_preparation_time
      moderatePreparationTime: $moderate_preparation_time
      quietPreparationTime: $quiet_preparation_time
      deliveryTime: $delivery_time
      busyDeliveryAreaEnabled: $busy_delivery_area_enabled
      deliveryInterval: $delivery_interval
      pickupInterval: $pickup_interval
      prepMode: $prep_mode
      sameDayPickup: $same_day_pickup
    ) {
      id
    }
  }
`

export const UPDATE_BUSY_AREA_WITH_AREA = gql`
  mutation UpdateStoreSettings(
    $store_id: uuid!
    $busy_delivery_area: [DeliveryAreaInputObject]
    $custom_busy_delivery_area: Boolean
    $busy_delivery_area_kml_upload: Boolean
  ) {
    updateStoreSettings(
      storeId: $store_id
      busyDeliveryArea: $busy_delivery_area
      customBusyDeliveryArea: $custom_busy_delivery_area
      busyDeliveryAreaKmlUpload: $busy_delivery_area_kml_upload
    ) {
      id
    }
  }
`

export const UPDATE_BUSY_AREA_WITH_MILE_RADIUS = gql`
  mutation UpdateStoreSettings(
    $store_id: uuid!
    $busy_delivery_radius: Float
    $custom_busy_delivery_area: Boolean
    $busy_delivery_area_kml_upload: Boolean
  ) {
    updateStoreSettings(
      storeId: $store_id
      busyDeliveryRadius: $busy_delivery_radius
      customBusyDeliveryArea: $custom_busy_delivery_area
      busyDeliveryAreaKmlUpload: $busy_delivery_area_kml_upload
    ) {
      id
    }
  }
`

export const UPDATE_BUSY_AREA_WITH_MINUTES_RADIUS = gql`
  mutation UpdateStoreSettings(
    $store_id: uuid!
    $busy_delivery_time: Int
    $custom_busy_delivery_area: Boolean
    $busy_delivery_area_kml_upload: Boolean
  ) {
    updateStoreSettings(
      storeId: $store_id
      busyDeliveryTime: $busy_delivery_time
      customBusyDeliveryArea: $custom_busy_delivery_area
      busyDeliveryAreaKmlUpload: $busy_delivery_area_kml_upload
    ) {
      id
    }
  }
`

export const RECALCULATE_STORE_TIMER = gql`
  mutation recalculateStoreTimer($storeId: String!) {
    updateStoreTimer(storeId: $storeId) {
      statusChangeIn
    }
  }
`

export const UPDATE_STORE_PREPARATION_TIME = gql`
  mutation updateStorePreparationTime(
    $merchant_id: uuid!
    $id: uuid!
    $settings: jsonb!
  ) {
    update_stores(
      where: { merchant_id: { _eq: $merchant_id }, id: { _eq: $id } }
      _append: { settings: $settings }
    ) {
      affected_rows
      returning {
        settings
      }
    }
  }
`

export const TOGGLE_STORE = gql`
  mutation toggleStore($store_id: uuid!, $is_open: Boolean!) {
    update_stores(
      where: { id: { _eq: $store_id } }
      _set: { is_open: $is_open }
    ) {
      affected_rows
      returning {
        id
        is_open
      }
    }
  }
`

export const UPDATE_STORE = gql`
  mutation updateStore(
    $store_id: uuid!
    $name: String!
    $slug: String!
    $email: String!
    $pickup_notes: String
    $line_1: String!
    $line_2: String!
    $city: String!
    $country: String!
    $zip: String!
    $geom: geometry!
    $contact_num: String!
    $archived_at: timestamp
    $address_cache: jsonb
    $address_is_new_version: Boolean = true
    $description: String
    $seo_description: String
  ) {
    update_stores(
      where: { id: { _eq: $store_id } }
      _set: {
        name: $name
        slug: $slug
        email: $email
        pickup_notes: $pickup_notes
        archived_at: $archived_at
        address_cache: $address_cache
        description: $description
        seo_description: $seo_description
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
    update_addresses(
      where: { store_id: { _eq: $store_id } }
      _set: {
        line_1: $line_1
        line_2: $line_2
        city: $city
        country: $country
        zip: $zip
        geom: $geom
        contact_num: $contact_num
        is_new_version: $address_is_new_version
        updated_at: "now()"
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const CREATE_LOCATION = gql`
  mutation createLocation(
    $merchant_id: ID!
    $name: String!
    $email: String!
    $pickup_notes: String
    $settings: SettingsParams!
    $prep_reminders: PrepRemindersParams!
    $variant_data: [VariantDataParams]!
    $store_modifiers: [StoreModifierParams]!
    $users: [StoreUserParams]!
    $address: AddressParams!
  ) {
    createStore(
      merchantId: $merchant_id
      name: $name
      email: $email
      pickupNotes: $pickup_notes
      settings: $settings
      prepReminders: $prep_reminders
      variantData: $variant_data
      storeModifiers: $store_modifiers
      address: $address
      users: $users
    ) {
      id
    }
  }
`

export const TOGGLE_PREORDER = gql`
  mutation toggleWarehouseStatus($slug: String) {
    toggleWarehouseStatus(slug: $slug) {
      id
    }
  }
`

export const OPEN_CLOSE_STORE = gql`
  mutation openCloseStore($userId: ID!, $storeId: ID!, $isOpen: Boolean!) {
    openOrCloseStore(userId: $userId, storeId: $storeId, isOpen: $isOpen) {
      is_open
    }
  }
`

export const UPDATE_STORE_VARIANT_STOCK_STATUS = gql`
  mutation toggleStockStatus($store_variant_id: uuid!, $in_stock: Boolean!) {
    update_store_variants(
      where: { id: { _eq: $store_variant_id } }
      _set: { in_stock: $in_stock }
    ) {
      affected_rows
      returning {
        id
        in_stock
      }
    }
  }
`

export const UPDATE_STORE_MODIFIER_STOCK_STATUS = gql`
  mutation toggleStockStatus($store_modifier_id: uuid!, $in_stock: Boolean!) {
    update_store_modifiers(
      where: { id: { _eq: $store_modifier_id } }
      _set: { in_stock: $in_stock }
    ) {
      affected_rows
      returning {
        id
        in_stock
      }
    }
  }
`

export const PUBLISH_STORE_VARIANT = gql`
  mutation PublishStoreVariant($store_variant_id: uuid!) {
    update_store_variants(
      where: { id: { _eq: $store_variant_id } }
      _set: { published_at: "now()" }
    ) {
      affected_rows
      returning {
        id
        published_at
      }
    }
  }
`

export const UNPUBLISH_STORE_VARIANT = gql`
  mutation UnpublishStoreVariant($store_variant_id: uuid!) {
    update_store_variants(
      where: { id: { _eq: $store_variant_id } }
      _set: { published_at: null }
    ) {
      affected_rows
      returning {
        id
        published_at
      }
    }
  }
`

export const PUBLISH_STORE_MODIFIER = gql`
  mutation PublishStoreModifier($store_modifier_id: uuid!) {
    update_store_modifiers(
      where: { id: { _eq: $store_modifier_id } }
      _set: { published_at: "now()" }
    ) {
      affected_rows
      returning {
        id
        published_at
      }
    }
  }
`

export const UNPUBLISH_STORE_MODIFIER = gql`
  mutation UnpublishStoreModifier($store_modifier_id: uuid!) {
    update_store_modifiers(
      where: { id: { _eq: $store_modifier_id } }
      _set: { published_at: null }
    ) {
      affected_rows
      returning {
        id
        published_at
      }
    }
  }
`

export const CREATE_STORE_USERS = gql`
  mutation createStoreUsers($store_id: String!, $users: [StoreUserParams!]!) {
    createStoreUsers(storeId: $store_id, users: $users) {
      id
    }
  }
`

export const STORE_SLUG_CHANGE_LOG = gql`
  mutation storeSlugChange(
    $storeId: ID!
    $oldSlug: String!
    $newSlug: String!
  ) {
    storeSlugChange(storeId: $storeId, oldSlug: $oldSlug, newSlug: $newSlug)
  }
`
