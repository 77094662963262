import React, { useState } from 'react'
import { Button, Col, Collapse, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { AdditionalCourier, OrderInfo } from 'components/Orders/Info/type'
import { orderAbilities } from 'components/Orders/Info/utils'
import DeliveryDetails from './DeliveryDetails'
import CourierInfo from './CourierInfo'
import DeliveryHeader from './DeliveryHeader'
import DeliveryCta from './DeliveryCta'
import DeliveryMapDetails from './DeliveryMapDetails'
import CourierRebookingForm from './Forms/CourierRebookingForm'

interface DeliveryInformationProps {
  order: OrderInfo
  reloadOrder: () => void
}

const { Panel } = Collapse

const DeliveryInformation = ({
  order,
  reloadOrder
}: DeliveryInformationProps) => {
  const {
    delivery,
    additional_couriers,
    transaction_id,
    fulfillment_date,
    fulfillment_time_range,
    inserted_at
  } = order
  const { canManageCourierBookings, canCancelCourier } = orderAbilities(order)
  const [showCourierRebookingForm, setShowCourierRebookingForm] =
    useState<boolean>(false)

  const bookButton = (
    <Button
      icon={<PlusOutlined className='_pb-2' />}
      onClick={setShowCourierRebookingForm}
    >
      BOOK ANOTHER COURIER
    </Button>
  )

  const defaultActiveKeysHandler = (
    additionalCouriers: AdditionalCourier[]
  ) => {
    const ids = additionalCouriers.map(({ data }) => data.id)
    const deliveryId = delivery?.id ?? ''

    return [deliveryId, ...ids]
  }

  return (
    <Collapse
      defaultActiveKey={['deliveryDetails', 'viewOnMap']}
      className='-plain'
    >
      <Panel
        header='Delivery details'
        key='deliveryDetails'
        className='-compact'
      >
        <Row className='_mb-0'>
          <Col span={24}>
            <DeliveryDetails order={order} reloadOrder={() => reloadOrder()} />
            <Collapse
              className='-borderless-edges'
              defaultActiveKey={defaultActiveKeysHandler(additional_couriers)}
            >
              <Panel
                header={<DeliveryHeader delivery={delivery} />}
                extra={
                  <DeliveryCta
                    delivery={delivery}
                    canCancel={canCancelCourier}
                    transactionId={transaction_id}
                    successCallback={() => reloadOrder()}
                  />
                }
                key={delivery?.id || ''}
              >
                <CourierInfo
                  delivery={delivery}
                  fulfillment={fulfillment_date || inserted_at}
                  isAsap={fulfillment_time_range === 'ASAP'}
                />
              </Panel>
              {additional_couriers.map(
                ({
                  data: additionalCourierDelivery,
                  address: additionalCourierAddress,
                  recipient_details: recipientDetails,
                  fulfillment_date,
                  store
                }: AdditionalCourier) => (
                  <Panel
                    header={
                      <DeliveryHeader delivery={additionalCourierDelivery} />
                    }
                    extra={
                      <DeliveryCta
                        delivery={additionalCourierDelivery}
                        canCancel={canCancelCourier}
                        transactionId={transaction_id}
                        successCallback={() => reloadOrder()}
                      />
                    }
                    key={additionalCourierDelivery.id}
                  >
                    <CourierInfo
                      store={store}
                      customer={recipientDetails}
                      dropoff={additionalCourierAddress}
                      delivery={additionalCourierDelivery}
                      fulfillment={
                        fulfillment_date ||
                        additionalCourierDelivery.inserted_at
                      }
                      isAsap={!fulfillment_date}
                    />
                  </Panel>
                )
              )}
            </Collapse>

            <Row className='_mb-0 _ml-16 _mt-16'>
              {canManageCourierBookings && !showCourierRebookingForm && (
                <Col>{bookButton}</Col>
              )}
            </Row>

            {canManageCourierBookings && showCourierRebookingForm && (
              <Row className='_mb-0 _ml-16 _mt-16 _mr-16'>
                <Col span={24}>
                  <CourierRebookingForm
                    order={order}
                    cancelHandler={() => setShowCourierRebookingForm(false)}
                    successCallback={() => {
                      reloadOrder()
                      setShowCourierRebookingForm(false)
                    }}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Panel>
      <Panel header='View on map' key='viewOnMap'>
        <DeliveryMapDetails order={order} />
      </Panel>
    </Collapse>
  )
}

export default DeliveryInformation
