import React from 'react'
import { Col, Collapse, Row } from 'antd'
import ClientDetails from './ClientDetails'
import OrderDetails from './OrderDetails'
import OrderItemsDetails from './OrderItemsDetails'
import { OrderInfo } from 'components/Orders/Info/type'

interface BasicInformationProps {
  order: OrderInfo
  reloadOrder: () => void
}

const { Panel } = Collapse

const BasicInformation = ({ order, reloadOrder }: BasicInformationProps) => {
  const { customer_details, recipient_details } = order
  return (
    <Collapse
      defaultActiveKey={['clientDetails', 'orderDetails', 'orderItems']}
      className='-plain'
    >
      <Panel header='Client details' key='clientDetails'>
        <Row>
          <Col span={24}>
            <ClientDetails
              customer={customer_details}
              recipient={recipient_details}
            />
          </Col>
        </Row>
      </Panel>
      <Panel header='Order details' key='orderDetails'>
        <OrderDetails order={order} reloadOrder={reloadOrder} />
      </Panel>
      <Panel header='Order items' key='orderItems'>
        <OrderItemsDetails order={order} reloadOrder={reloadOrder} />
      </Panel>
    </Collapse>
  )
}

export default BasicInformation
