import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col } from 'antd'
import GoogleMap from 'google-map-react'
import { DeliveryArea } from './DeliveryArea'
import { LatLng } from '@slerp/controls'

import MapStyles from '../MapStyles.json'

export const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY || ''

export const getZoom = (radius: number) => {
  if (radius >= 30) return 8
  if (radius >= 20) return 9
  if (radius >= 10) return 10
  if (radius >= 6) return 10.5
  if (radius >= 5) return 11
  if (radius >= 3) return 11.5
  if (radius >= 2) return 12
  if (radius >= 1) return 12.5
  if (radius >= 0.5) return 13
  return 14
}

export const drawMapCenter = (mapRef: any, coords: number[]) => {
  return new mapRef.maps.Marker({
    position: { lat: coords[0], lng: coords[1] },
    map: mapRef.map,
    title: 'Shop'
  })
}

const DeliveryAreaSettings = (props: {
  deliveryPaths: LatLng[]
  busyDeliveryPaths?: LatLng[]
  storeLocation: [number, number]
  onTouched: (path: LatLng[]) => void
  maxDistance?: number | null
}) => {
  const {
    deliveryPaths,
    storeLocation,
    busyDeliveryPaths,
    onTouched,
    maxDistance
  } = props
  const showBusyArea = !!busyDeliveryPaths

  const DEFAULT_CUSTOM_AREA_RADIUS_IN_MILES = 4
  const DEFAULT_BUSY_AREA_SCALE = 0.7

  const [deliveryArea, setDeliveryArea] = useState<DeliveryArea | undefined>(
    undefined
  )
  const [busyDeliveryArea, setBusyDeliveryArea] = useState<
    DeliveryArea | undefined
  >(undefined)

  let mapRef = { map: null, maps: null }

  const createMapOptions = (maps: any) => ({ styles: MapStyles })

  const centerOnStoreLocation = useCallback(() => {
    const storeLocationLatLng = {
      lat: storeLocation[0],
      lng: storeLocation[1]
    }

    mapRef.map.setCenter(storeLocationLatLng)
  }, [mapRef, storeLocation])

  useEffect(() => {
    if (deliveryPaths && deliveryArea) {
      deliveryArea?.setPath(deliveryPaths)
    }

    if (busyDeliveryPaths && busyDeliveryArea) {
      busyDeliveryArea?.setPath(busyDeliveryPaths)
    }
  }, [busyDeliveryPaths, deliveryArea, deliveryPaths, busyDeliveryArea])

  useEffect(() => {
    if (deliveryArea) {
      deliveryArea.setMaxDistance(maxDistance)
    }

    if (busyDeliveryArea) {
      busyDeliveryArea.setMaxDistance(maxDistance)
    }
  }, [maxDistance, deliveryArea, busyDeliveryArea])

  // TODO: draw shop log as with slerp-shop splash map
  const drawCenter = useCallback(() => {
    mapRef && drawMapCenter(mapRef, storeLocation)
  }, [mapRef, storeLocation])

  const handleGoogleApiLoaded = ({ map, maps }: any) => {
    mapRef = { map, maps }

    setDeliveryArea(
      new DeliveryArea({
        center: {
          lat: storeLocation[0],
          lng: storeLocation[1]
        },
        radius: DEFAULT_CUSTOM_AREA_RADIUS_IN_MILES,
        path: deliveryPaths,
        onTouched,
        mapRef: mapRef,
        options: {
          editable: !showBusyArea,
          fillColor: '#6580a3',
          strokeColor: '#6580a3'
        },
        maxDistance: props.maxDistance
      })
    )

    if (showBusyArea) {
      setBusyDeliveryArea(
        new DeliveryArea({
          center: {
            lat: storeLocation[0],
            lng: storeLocation[1]
          },
          radius: DEFAULT_CUSTOM_AREA_RADIUS_IN_MILES * DEFAULT_BUSY_AREA_SCALE,
          path: busyDeliveryPaths,
          onTouched,
          mapRef: mapRef,
          options: {
            editable: true,
            fillColor: '#a38065',
            strokeColor: '#a38065'
          },
          maxDistance: props.maxDistance
        })
      )
    }

    drawCenter()
    centerOnStoreLocation()
  }

  return (
    <>
      <Row style={{ marginBottom: 0 }}>
        <Col span={24}>
          <div
            style={{
              width: '100%',
              height: '400px',
              borderRadius: '4px',
              overflow: 'hidden'
            }}
          >
            <GoogleMap
              bootstrapURLKeys={{
                key: MAPS_API_KEY
              }}
              defaultCenter={{ lat: 51.5137137, lng: -0.1285204 }}
              defaultZoom={getZoom(DEFAULT_CUSTOM_AREA_RADIUS_IN_MILES)}
              options={createMapOptions}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={handleGoogleApiLoaded}
            ></GoogleMap>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default DeliveryAreaSettings
