import React from 'react'
import { Link } from 'react-router-dom'
import { Tabs, Row, Col, Button, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const { TabPane } = Tabs

const DiscountTabs = (props: any) => {
  const { codes, automatic, loyalty } = props

  return (
    <>
      <Row justify='end' className='_w-100'>
        <Col>
          <Link to={'/discounts/code/new'}>
            <Button
              icon={<PlusOutlined className='_pb-8' />}
              className='_uppercase _ml-12 _link-white btn-text'
            >
              Add Discount
            </Button>
          </Link>
        </Col>
      </Row>
      <Tabs type='card' defaultActiveKey='codes' className='_mb-8'>
        <TabPane
          key='codes'
          tab={
            <Tooltip
              title={
                <span>
                  <a
                    href='https://support.slerp.com/knowledge/creating-and-managing-discount-codes'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Here's our best practise guide
                  </a>{' '}
                  to creating and managing discount codes.
                </span>
              }
            >
              <span data-testid='discount-codes-tab-pane'>
                Manual Discounts
              </span>
            </Tooltip>
          }
        >
          {codes}
        </TabPane>

        <TabPane
          key='automatic'
          tab={
            <span data-testid='automatic-discounts-tab-pane'>
              Automatic Discounts
            </span>
          }
        >
          {automatic}
        </TabPane>

        <TabPane
          key='loyalty'
          tab={
            <span data-testid='loyalty-rewards-tab-pane'>Loyalty Rewards</span>
          }
        >
          {loyalty}
        </TabPane>
      </Tabs>
    </>
  )
}

export default DiscountTabs
