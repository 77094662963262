import React from 'react'
import { Col } from 'antd'
import { AppSettings } from 'types'
import Product from './Product'

interface Props {
  appSettings: AppSettings
  onChange: (values: AppSettings) => void
}

const Designer = (props: Props) => {
  const { appSettings, onChange } = props

  return (
    <>
      <Col span={12}>
        <Product appSettings={appSettings} onChange={onChange} />
      </Col>
    </>
  )
}

export default Designer
