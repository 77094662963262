import React, { useEffect, useState } from 'react'
import * as DOMPurify from 'dompurify'
import { Button, Checkbox, Col, Form, Input, message, Modal, Row } from 'antd'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import { uuid } from 'uuidv4'
import { CREATE_SIGNATURE } from './actions'
import { nameRule } from './Rules'
import { useGlobalQuery } from 'components/Utils/useGlobalQuery'

interface FormValues {
  first_name: string
  last_name: string
  agreed: boolean
}

const { useForm } = Form

const TermsAndConditions = ({
  isAdmin,
  merchantId,
  registeredCompanyName = '',
  merchantName
}: {
  isAdmin: boolean
  merchantId: string
  registeredCompanyName: string | undefined
  merchantName: string
}) => {
  const [form] = useForm()
  const [enableSubmit, setEnableSubmit] = useState({
    agreed: false,
    authorised: false
  })
  const { signatures, isLoading, terms, refetchSignatures } = useGlobalQuery()

  const [showTerms, setShowTerms] = useState<boolean>(false)

  const termsAndConditions = (terms ?? [])[0]?.terms_and_conditions
  const updatedAt = moment((terms ?? [])[0]?.updated_at).format('D MMM YYYY')
  const latestTermsId = (terms ?? [])[0]?.id
  const latestSignature = (signatures ?? [])[0]?.terms_id

  const [createSignature, { loading: createSignatureLoading }] = useMutation(
    CREATE_SIGNATURE,
    {
      fetchPolicy: 'no-cache'
    }
  )

  useEffect(() => {
    if (isLoading) return
    if (sessionStorage.getItem(`terms-merchant-id-${merchantId}`) === 'skip')
      return setShowTerms(false)

    // checks if admin has signed the latest terms and conditions
    if (latestTermsId !== latestSignature) {
      setShowTerms(true)

      // if signature is outdated, makes sure to remove skip in sessionStorage
      sessionStorage.removeItem(`terms-merchant-id-${merchantId}`)
    } else {
      setShowTerms(false)
    }
  }, [latestTermsId, latestSignature, sessionStorage, isLoading])

  const onFinish = async (values: FormValues) => {
    const { first_name, last_name, agreed } = values

    const variables = {
      firstName: first_name,
      lastName: last_name,
      termsId: latestTermsId,
      merchantId: merchantId,
      agreed: agreed,
      id: uuid()
    }

    form
      .validateFields()
      .then(() =>
        createSignature({
          variables
        })
      )
      .then(() => {
        if (refetchSignatures) refetchSignatures()
        message.success('Successfully signed T&Cs!', 5)
      })
      .catch((error) =>
        message.error(`Unable to sign T&Cs due to ${error.message}`, 5)
      )
  }

  return (
    (isAdmin && (
      <>
        <Modal
          closable={false}
          visible={showTerms}
          centered
          className='terms'
          title={
            <Row className='_mb-0' justify='space-between' align='middle'>
              <Col className='title'>Terms & Conditions</Col>
              <Col>
                <Button
                  data-testid='terms-and-conditions-skip'
                  type='ghost'
                  loading={createSignatureLoading}
                  className='_w-100 -secondary terms-skip'
                  onClick={() => {
                    sessionStorage.setItem(
                      `terms-merchant-id-${merchantId}`,
                      'skip'
                    )
                    setShowTerms(false)
                  }}
                >
                  Skip
                </Button>
              </Col>
              <Col className='last-updated'>
                <span>Last Updated:</span> <strong>{updatedAt}</strong>
              </Col>
            </Row>
          }
          style={{
            maxWidth: '672px'
          }}
          width='100%'
          footer={[
            <Form
              form={form}
              layout='vertical'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              className='custom-content'
            >
              <Form.Item name='agreed' valuePropName='checked'>
                <Checkbox
                  data-testid='agreed-checkbox'
                  onChange={(e) => {
                    const agreed = e.target.checked
                    setEnableSubmit((prev) => ({ ...prev, agreed }))
                  }}
                  className='_w-100'
                >
                  By clicking 'I agree', I acknowledge I have read and agree to
                  be bound by the Terms and Conditions set out above.
                </Checkbox>
              </Form.Item>
              <Form.Item name='authorised' valuePropName='checked'>
                <Checkbox
                  data-testid='authorised-checkbox'
                  onChange={(e) => {
                    const authorised = e.target.checked
                    setEnableSubmit((prev) => ({ ...prev, authorised }))
                  }}
                  className='_w-100'
                >
                  I am authorised on behalf of{' '}
                  <strong>{registeredCompanyName || merchantName}</strong> to
                  enter into these Terms and Conditions
                </Checkbox>
              </Form.Item>
              <Row gutter={8} justify='space-between' className='_mb-0'>
                <Col span='10'>
                  <Form.Item
                    name='first_name'
                    className='_mb-0'
                    rules={nameRule}
                  >
                    <Input type='text' placeholder='First name' required />
                  </Form.Item>
                </Col>
                <Col span='10'>
                  <Form.Item
                    name='last_name'
                    className='_mb-0'
                    rules={nameRule}
                  >
                    <Input type='text' placeholder='Last name' required />
                  </Form.Item>
                </Col>
                <Col span='4'>
                  <Form.Item className='_mb-0 _mt-2' shouldUpdate>
                    {() => {
                      return (
                        <Button
                          data-testid='terms-and-conditions-submit'
                          type='default'
                          htmlType='submit'
                          disabled={
                            !enableSubmit.agreed || !enableSubmit.authorised
                          }
                          className='_w-100'
                        >
                          I agree
                        </Button>
                      )
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ]}
        >
          {termsAndConditions && (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(termsAndConditions, {
                  USE_PROFILES: { html: true },
                  ADD_ATTR: ['target']
                })
              }}
            />
          )}
        </Modal>
      </>
    )) || <></>
  )
}

export default TermsAndConditions
