import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Select } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import SettingsTabs from './SettingsTabs'
import { useSession } from '@slerp/accounts'
import { useQuery, useLazyQuery } from '@apollo/client'
import { Route, Redirect, useHistory } from 'react-router-dom'
import Loading from '../Utils/Loading'
import GeneralSettings from './GeneralSettings'
import TableSettings from './TableSettings'
import PreOrderSettings from './PreOrderSettings'
import SameDaySettings from './SameDaySettings'
import InventorySettings from './InventorySettings'
import { useParams } from 'react-router-dom'
import { Store, Merchant } from '@slerp/controls'
import { QUERY_MERCHANT_STORES } from './LocationQueries'
import {
  GET_MERCHANT_ORDER_STORES,
  QUERY_GET_STORE_SLUG_LOG
} from './Dashboard/DashboardQueries'
import { LocationIcon, SelectDownArrowIcon } from '@slerp/assets'
import { SelectPrefixWrapper } from './../Widgets/'
import AccountsAndBilling from './AccountAndBilling'
import { useGlobalQuery } from 'components/Utils/useGlobalQuery'

export interface LocationSettingProps {
  merchant: Merchant
  store: Store
  refetch: () => {}
  disabled?: boolean
}

interface SameDayOrderStoresData {
  id: string
  slug: string
  name: string
}

const Settings = () => {
  const { merchant: merchantSession, user } = useSession()
  const { slug } = useParams()
  const { isLoading } = useGlobalQuery()
  const history = useHistory()
  const currentLocation = window.location.pathname.match(/settings.*/)

  const [sameDayOrderStores, setSameDayOrderStores] = useState<
    Array<SameDayOrderStoresData>
  >([])

  const { data, refetch } = useQuery(QUERY_MERCHANT_STORES, {
    variables: { merchant_id: merchantSession.id, slug },
    fetchPolicy: 'no-cache'
  })

  const { data: sameDayOrderStoresData } = useQuery(GET_MERCHANT_ORDER_STORES, {
    variables: {
      merchantId: merchantSession.id,
      userEmail: user.email
    }
  })

  // Checks slug has been change
  const [getLog, { data: storeSlugData }] = useLazyQuery(
    QUERY_GET_STORE_SLUG_LOG,
    {
      variables: { storeSlug: slug }
    }
  )

  useEffect(() => {
    getLog()
  }, [getLog])

  useEffect(() => {
    if (storeSlugData?.slug_logs?.length) {
      const store = storeSlugData.slug_logs[0].store
      if (store.slug !== slug) {
        // redirects to new slug
        history.replace(`/location/${store.slug}/${currentLocation}`)
      }
    }
  }, [storeSlugData])

  useEffect(() => {
    if (sameDayOrderStoresData) {
      const { stores } = sameDayOrderStoresData
      setSameDayOrderStores(stores)
    }
  }, [sameDayOrderStoresData])

  const merchant = data?.merchant && data.merchant
  const store = data?.merchant.stores[0]

  const moveToStore = (storeSlug: string) => {
    window.location.pathname = `/location/${storeSlug}/${currentLocation}`
  }

  const SettingRoutes = () => (
    <>
      <Route
        path={`/location/${slug}/settings/general`}
        component={() => (
          <GeneralSettings
            merchant={merchant}
            store={store}
            refetch={refetch}
          />
        )}
      />

      <Route
        path={`/location/${slug}/settings/inventory`}
        component={() => (
          <InventorySettings storeSlug={store.slug} storeId={store.id} />
        )}
      />

      <Route
        path={`/location/${slug}/settings/same-day`}
        component={() => (
          <SameDaySettings
            merchant={merchant}
            store={store}
            refetch={refetch}
          />
        )}
      />

      <Route
        path={`/location/${slug}/settings/pre-order`}
        component={() => (
          <PreOrderSettings
            merchant={merchant}
            store={store}
            refetch={refetch}
          />
        )}
      />

      <Route
        path={`/location/${slug}/settings/table`}
        component={() => (
          <TableSettings
            merchant={data.merchant}
            store={store}
            refetch={refetch}
          />
        )}
      />

      {merchant.setting.franchise_enabled && (
        <>
          <Route
            path={`/location/${slug}/settings/connect-redirect`}
            component={() => {
              localStorage.removeItem('redirect-store')

              return (
                <Redirect
                  to={`/location/${slug}/settings/accounts-and-billing`}
                />
              )
            }}
          />

          <Route
            path={`/location/${slug}/settings/accounts-and-billing`}
            component={() => (
              <AccountsAndBilling
                merchant={data.merchant}
                store={store}
                refetch={refetch}
              />
            )}
          />
        </>
      )}
    </>
  )

  return (
    <>
      <Row justify='space-between' className='_mb-8'>
        <Col>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '0 0 20px 0'
            }}
            onClick={() => history.replace(`/location/${slug}`)}
          >
            <Button
              icon={<ArrowLeftOutlined />}
              style={{ paddingTop: '2px', borderRadius: '4px' }}
            />
            <span
              style={{
                cursor: 'pointer',
                margin: '0 0 0 8px'
              }}
            >
              Back to <strong>ORDERS</strong> and <strong>INVENTORY</strong>
            </span>
          </div>
        </Col>
        <Col>
          {data && sameDayOrderStores && (
            <SelectPrefixWrapper prefixicon={<LocationIcon />}>
              <SelectPrefixWrapper prefixicon={<LocationIcon />}>
                <Select
                  suffixIcon={<SelectDownArrowIcon />}
                  className='_w-100'
                  defaultValue={sameDayOrderStores && slug}
                  onChange={moveToStore}
                  style={{
                    minWidth: 'max-content'
                  }}
                  dropdownStyle={{
                    minWidth: '200px'
                  }}
                >
                  {sameDayOrderStores.map((store) => (
                    <Select.Option key={store.id} value={store.slug}>
                      {store.name}
                    </Select.Option>
                  ))}
                </Select>
              </SelectPrefixWrapper>
            </SelectPrefixWrapper>
          )}
        </Col>
      </Row>
      <Row data-testid='settings-form'>
        <Col span={24}>
          <SettingsTabs merchant={merchant} />
        </Col>
        <Col span={24}>
          {(data && data.merchant && store && !isLoading && (
            <SettingRoutes />
          )) || <Loading />}
        </Col>
      </Row>
    </>
  )
}
export default Settings
