import React from 'react'
import { Modal, ModalFuncProps } from 'antd'
import { LatLng, Store, VehiclesSettings } from '@slerp/controls'
export type FeeTypes = 'quoted' | 'custom' | 'rate_card' | ''

export const DEFAULT_DELIVERY_RADIUS = 4
export const DEFAULT_DELIVERY_TIME = 30

const BIKE_MIN_DISTANCE = 8
const CAR_MIN_DISTANCE = 11

export const getVehicleMinDistance = (vehicles: VehiclesSettings) => {
  if (vehicles?.car?.enabled) {
    return CAR_MIN_DISTANCE
  }

  return BIKE_MIN_DISTANCE
}

export const getDistanceOverflowPoints = (
  center: LatLng,
  path: LatLng[] | null,
  maxDistance: number
): LatLng[] => {
  let overflowPoints: LatLng[] = []

  if (!path || !google?.maps?.geometry?.spherical) {
    return overflowPoints
  }

  for (const point of path) {
    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(center.lat, center.lng),
      new google.maps.LatLng(point.lat, point.lng)
    )

    const distanceInMiles = distance / 1609.34

    if (distanceInMiles > maxDistance) {
      overflowPoints.push(point)
    }
  }

  return overflowPoints
}

export const warningModal = ({
  warningType,
  onOk,
  onCancel
}: {
  warningType: 'quoted' | 'custom' | 'courier'
  onOk: () => void
  onCancel: () => void
}) => {
  const modal = Modal.warning(null as unknown as ModalFuncProps)

  const modalProps = {
    title: <span className='cl-text-warning _text-weight-600'>Warning!</span>,
    onOk: () => {
      onOk()
      modal.destroy()
    },
    onCancel: () => {
      onCancel()
      modal.destroy()
    },
    okText: 'Proceed',
    cancelText: 'Cancel',
    okCancel: true,
    centered: true,
    keyboard: true
  }

  if (warningType === 'quoted') {
    modal.update({
      ...modalProps,
      content: (
        <span data-testid='warning-text-quoted'>
          Changing the delivery pricing will mean your customers pay more. It
          will lead to lower conversion and lower sales. Are you sure you want
          to change?
        </span>
      ),
      cancelButtonProps: {
        type: 'ghost'
      },
      okButtonProps: {
        type: 'default'
      }
    })
  }

  if (warningType === 'custom') {
    modal.update({
      ...modalProps,
      content: (
        <span data-testid='warning-text-custom'>
          Make sure your fixed fee is competitive. We recommend it being at a
          maximum of £3.99 to ensure customer conversion is not impacted.
        </span>
      ),
      cancelButtonProps: {
        type: 'ghost'
      },
      okButtonProps: {
        type: 'default'
      }
    })
  }

  if (warningType === 'courier') {
    modal.update({
      ...modalProps,
      content: (
        <span data-testid='warning-text-courier'>
          In order to save your configuration, a fixed delivery fee must be set.
          If you would like to set distance based rates, please contact our
          support team.
        </span>
      ),
      cancelButtonProps: {
        type: 'ghost'
      },
      okButtonProps: {
        type: 'default'
      }
    })
  }
}

export const isAutoAccept = (
  store: Store,
  fulfillmentType: string,
  orderType: string
) => {
  return store.store_order_type_settings.some(
    ({ order_type, fulfillment_type, auto_accept }) =>
      fulfillment_type === fulfillmentType &&
      order_type === orderType &&
      auto_accept
  )
}
