import { AppSettings } from '@slerp/controls'
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client'
import {
  LAYOUT_QUERY_MERCHANT_SETTING,
  QUERY_MERCHANT_HAS_STRIPE_SUBSCRIPTION,
  QUERY_MERCHANT_ONBOARDING_STATUS,
  QUERY_SIGNATURE,
  QUERY_TERMS
} from 'components/Layout/LayoutQueries'
import { QUERY_MERCHANT_CONNECTED_CARDS } from 'components/Merchants/MerchantQueries'
import moment from 'moment'
import { useSession } from 'packages/@slerp/accounts'
import React, { useContext, useEffect, useState } from 'react'

export interface Term {
  terms_and_conditions: string
  id: string
  inserted_at: Date
  updated_at: Date
}

export interface Signature {
  agreed: boolean
  terms_id: string
}

export interface MerchantSetting {
  name: string
  marketing_modal_viewers: Array<string>
  app_settings: AppSettings[]
  integration_settings: {}
  registered_company_name: string
  subscription: {}
  has_onboarded: boolean
}

interface hasStripeSubscription {
  merchantHasStripeSubscription: boolean
}

type OnboardingStatusKeys =
  | 'hasCard'
  | 'hasLogos'
  | 'hasDiscount'
  | 'hasKeyImages'
  | 'hasLoyaltyCard'
  | 'hasModifier'
  | 'hasProduct'
  | 'hasStore'
  | 'hasStripeSetup'
export type OnboardingStatus = Record<OnboardingStatusKeys, boolean>

export interface Card {
  id: string
  last4: number
  expMonth: number
  expYear: number
  expired?: boolean
  defaultSource: string
}

interface GlobalQuery {
  terms: Array<Term> | null
  signatures: Array<Signature> | null
  refetchSignatures:
    | ((
        variables?: Partial<{ merchantId: string }> | undefined
      ) => Promise<ApolloQueryResult<{ signatures: Signature[] }>>)
    | null
  merchantSetting: MerchantSetting | null
  hasStripeSubscription: boolean
  onboardingStatus: OnboardingStatus | null
  cards: Array<Card> | null
  hasExpiredDefaultCard: boolean | null
  isLoading: boolean
  errorMerchantSetting: ApolloError | undefined
}

const defaultValues: GlobalQuery = {
  terms: null,
  signatures: null,
  refetchSignatures: null,
  merchantSetting: null,
  hasStripeSubscription: false,
  onboardingStatus: null,
  cards: null,
  hasExpiredDefaultCard: null,
  isLoading: false,
  errorMerchantSetting: undefined
}

export const GlobalQueryContext =
  React.createContext<GlobalQuery>(defaultValues)

export const GlobalQueryProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const { merchant } = useSession()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [terms, setTerms] = useState<Array<Term> | null>(null)
  const [signatures, setSignatures] = useState<Array<Signature> | null>(null)
  const [merchantSetting, setMerchantSetting] =
    useState<MerchantSetting | null>(null)
  const [hasStripeSubscription, setHasStripeSubscription] =
    useState<boolean>(false)
  const [onboardingStatus, setOnboardingStatus] =
    useState<OnboardingStatus | null>(null)
  const [cards, setCards] = useState<Array<Card> | null>(null)
  const [hasExpiredDefaultCard, setHasExpiredDefaultCard] = useState<
    boolean | null
  >(null)

  // terms and conditions
  const { loading: isLoadingTerms } = useQuery<{ terms: Array<Term> }>(
    QUERY_TERMS,
    {
      onCompleted: (data) => {
        if (!data) return
        setTerms(data.terms)
      }
    }
  )

  const { refetch: refetchSignatures, loading: isLoadingSignatures } =
    useQuery<{ signatures: Array<Signature> }>(QUERY_SIGNATURE, {
      variables: { merchantId: merchant.id },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (!data) return
        setSignatures(data.signatures)
      }
    })

  // welcome modal
  const { loading: isLoadingMerchantSetting, error: errorMerchantSetting } =
    useQuery<{ merchants_by_pk: MerchantSetting }>(
      LAYOUT_QUERY_MERCHANT_SETTING,
      {
        variables: { merchant: merchant.id },
        onCompleted: (data) => {
          if (!data) return
          setMerchantSetting(data.merchants_by_pk)
        }
      }
    )

  // stripe subscription checker
  const { loading: isLoadingHasSubscription } = useQuery<hasStripeSubscription>(
    QUERY_MERCHANT_HAS_STRIPE_SUBSCRIPTION,
    {
      variables: { id: merchant?.id },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (!data) return
        setHasStripeSubscription(data.merchantHasStripeSubscription)
      }
    }
  )

  // onboarding status
  const { loading: isLoadingOnboardingStatus } = useQuery<{
    merchantOnboardingStatus: OnboardingStatus
  }>(QUERY_MERCHANT_ONBOARDING_STATUS, {
    variables: { id: merchant?.id },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data) return
      setOnboardingStatus(data.merchantOnboardingStatus)
    }
  })

  const { loading: isLoadingCards } = useQuery<{ cards: Array<Card> }>(
    QUERY_MERCHANT_CONNECTED_CARDS,
    {
      variables: { merchantSlug: merchant.slug },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (!data) return
        const cards = data.cards

        const cardsWithExpiry = cards.map((card: Card) => {
          const currentMonth = moment().format('YYYY-MM')
          const expiryMonth = moment(`${card.expYear}-${card.expMonth}`).format(
            'YYYY-MM'
          )

          return {
            ...card,
            expired: moment(currentMonth).isAfter(expiryMonth)
          }
        })

        setHasExpiredDefaultCard(
          cards?.some(
            (card: Card) => card.id === card.defaultSource && card.expired
          )
        )
        setCards(cardsWithExpiry)
      }
    }
  )

  useEffect(() => {
    if (
      isLoadingTerms ||
      isLoadingSignatures ||
      isLoadingMerchantSetting ||
      isLoadingHasSubscription ||
      isLoadingOnboardingStatus ||
      isLoadingCards
    ) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [
    isLoadingTerms,
    isLoadingSignatures,
    isLoadingMerchantSetting,
    isLoadingHasSubscription,
    isLoadingOnboardingStatus,
    isLoadingCards
  ])

  return (
    <GlobalQueryContext.Provider
      value={{
        terms,
        signatures,
        merchantSetting,
        refetchSignatures,
        hasStripeSubscription,
        onboardingStatus,
        cards,
        hasExpiredDefaultCard,
        isLoading,
        errorMerchantSetting
      }}
    >
      {children}
    </GlobalQueryContext.Provider>
  )
}

export const useGlobalQuery = () => {
  return useContext(GlobalQueryContext)
}
