import React from 'react'
import { Row, Col } from 'antd'
import Sidebar from './Sidebar'
import { useSession } from '@slerp/accounts'
import { useQuery } from '@apollo/client'
import { Route } from 'react-router-dom'
import Loading from '../Utils/Loading'
import GeneralSettings from './GeneralSettings'
import AnalyticsSettings from './AnalyticsSettings'
import Delivery from './Delivery'
import { QUERY_MERCHANT_SETTING } from '../Merchants/MerchantQueries'
import { CONNECT_AND_UPDATE_STRIPE_SETTINGS } from './actions'
import styled from '@emotion/styled'
import MerchantAdminUsersSettings from './MerchantAdminUsersSettings'
import TermsAndConditions from './TermsAndConditions'
import AccountsAndBilling from './AccountsAndBilling'
import { StripeConnectRoute } from 'components/Utils/Stripe'

const Settings = () => {
  const { merchant } = useSession()

  const { data, refetch } = useQuery(QUERY_MERCHANT_SETTING, {
    variables: { id: merchant.id },
    fetchPolicy: 'no-cache'
  })

  const SettingRoutes = () => (
    <>
      <Route
        path='/settings/general'
        component={() => (
          <GeneralSettings merchant={data.merchant} refetch={refetch} />
        )}
      />

      <Route
        path='/settings/delivery'
        component={() => (
          <Delivery merchant={data.merchant} refetch={refetch} />
        )}
      />

      <StripeConnectRoute
        path='/settings/stripe/connect'
        redirectUrl='/settings/accounts-and-billing'
        connectMutation={CONNECT_AND_UPDATE_STRIPE_SETTINGS}
        connectVariables={{ merchantId: merchant.id }}
      />

      <Route
        path='/settings/analytics'
        component={() => (
          <AnalyticsSettings merchant={data.merchant} refetch={refetch} />
        )}
      />

      <Route
        path='/settings/admin_users'
        component={() => (
          <MerchantAdminUsersSettings
            merchant={data.merchant}
            refetch={refetch}
          />
        )}
      />

      <Route
        path='/settings/terms-and-conditions'
        component={() => <TermsAndConditions merchantId={data?.merchant?.id} />}
      />

      <Route
        path='/settings/accounts-and-billing'
        component={() => <AccountsAndBilling merchant={data.merchant} />}
      />
    </>
  )

  return (
    <>
      <Row data-testid='settings-component'>
        <SideBarWrapper>
          <Sidebar />
        </SideBarWrapper>
      </Row>
      <Row style={{ backgroundColor: '#ffffff', paddingBottom: '32px' }}>
        <Col span={24} className='_pl-16'>
          {(data && data.merchant && <SettingRoutes />) || <Loading />}
        </Col>
      </Row>
    </>
  )
}

const SideBarWrapper = styled.div(() => ({
  padding: '0',
  width: '100%'
}))

export default Settings
