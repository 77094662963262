import React, { useContext } from 'react'
import {
  ApolloProvider,
  ApolloClient,
  useApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  ApolloLink,
  concat
} from '@apollo/client'
import { createLink } from 'apollo-absinthe-upload-link'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/link-ws'
import env from '../../../env'

export const GRAPH_HOST = env.GRAPH_HOST
export const ASSET_HOST = env.ASSET_HOST
export const CONFIG_DOMAIN = env.CONFIG_DOMAIN
export const HOST = env.ASSET_HOST
export const PARTNER_API_HOST = env.PARTNER_API_HOST
export const REPORTING_HOST = env.REPORTING_HOST
export const JWT_ENCRYPTION_KEY = env.JWT_ENCRYPTION_KEY
export const JWT_SECRET = env.JWT_SECRET
export const MAPS_API_KEY = env.MAPS_API_KEY
export const USERFLOW_TOKEN = env.USERFLOW_TOKEN

export const PROTOCOL =
  process.env.NODE_ENV === 'production' ? 'https:' : 'https:'
export const GRAPHQL_ENDPOINT = `${PROTOCOL}//${GRAPH_HOST}/v1/graphql`
export const WEBSOCKET_ENDPOINT = `wss://${GRAPH_HOST}/v1/graphql`
export const PARTNER_API_ENDPOINT = `${PROTOCOL}//${PARTNER_API_HOST}/v1/graphql`
export const REPORTS_ENDPOINT = `https://${REPORTING_HOST}/data-export`

export const LEGACY_LOGIN_ENDPOINT = `https://${HOST}/api/mobile/authenticate`
export const ABSINTHE_GRAPHQL_ENDPOINT = `${PROTOCOL}//${HOST}/graphql`
export const STRIPE_CONNECT_OAUTH_ENDPOINT = env.STRIPE_CONNECT_OAUTH_ENDPOINT
export const STRIPE_CONNECT_LOCATION_OAUTH_ENDPOINT =
  env.STRIPE_CONNECT_LOCATION_OAUTH_ENDPOINT

export const COURIER_SUPPORT_TRACKING_URL = `https://${ASSET_HOST}/track_my_order/`

export const HELP_CENTRE_URL = env.HELP_CENTRE_URL

// List of absinthe mutation/query names to delegate to Absinthe
const ABSINTHE_OPERATION_NAMES = [
  'uploadProductVariantImage',
  'uploadProductVariantImages',
  'uploadSingleProductVariantImage',
  'uploadModifierImageFile',
  'deleteAdditionalImage',
  'quote',
  'uploadLoyaltyCardImage',
  'createMerchantAppSettings',
  'updateMerchantAppSettings'
]

// Since we now use JWT. For backwards compatibility, use the user token.
const parseJson = (value?: string) => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return {}
  }
}

const userDetails = localStorage.getItem('user') || undefined
const user = parseJson(userDetails)

const httpLink = (uri: string, token: string) =>
  new HttpLink({
    uri: uri,
    headers: {
      authorization: `Bearer ${token}`
    }
  })

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-hasura-operation-name': operation.operationName,
      'x-hasura-merchant-id': user?.merchant?.id
    }
  }))

  return forward(operation)
})

// Make WebSocketLink with appropriate url
const wsLink = (uri: string, token: string) => {
  return new WebSocketLink({
    uri: uri,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        return {
          headers: {
            authorization: token ? `Bearer ${token}` : ''
          }
        }
      }
    }
  })
}

const splitLink = (apiKey = undefined || '', userApiKey = undefined || '') => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink(WEBSOCKET_ENDPOINT, userApiKey),
    absintheSplitLink(
      apiKey,
      concat(authMiddleware, httpLink(PARTNER_API_ENDPOINT, apiKey))
    )
  )
}

const absintheSplitLink = (
  apiKey = undefined || '',
  fallbackLink: ApolloLink
) => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        ABSINTHE_OPERATION_NAMES.includes(definition?.name?.value || '')
      )
    },
    createLink({
      uri: ABSINTHE_GRAPHQL_ENDPOINT,
      headers: {
        authorization: user?.api_key
      }
    }),
    fallbackLink
  )
}

/**
 * This creates a new Apollo Client.
 * This is being used by SlerpClient or can be called as a standalone function
 *
 * @param domain
 * @param apiKey
 */
const createClient = (apiKey: string, userApiKey: string) => {
  const client = new ApolloClient({
    link: splitLink(apiKey, userApiKey),
    cache: new InMemoryCache({
      addTypename: false
    })
  })
  return client
}

export type Merchant = {
  id: string
  name: string
  slug: string
  setting: {}
}

export type User = {
  id: string
  api_key: string
  firstname: string
  lastname: string
  email: string
  role: string
  merchant: Merchant
}

export type SlerpContextProps = {
  user: User
}

export const SlerpContext = React.createContext<SlerpContextProps>({
  user: {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    role: '',
    hasuraRole: '',
    api_key: '',
    merchant: {
      id: '',
      name: '',
      slug: '',
      setting: {}
    }
  }
})

/**
 *  The Slerp config required by SlerpClient
 */
export interface SlerpConfig {
  apiKey: string
  user: User
}

/**
 *  Slerp props
 */
interface SlerpProps {
  config: SlerpConfig
  children: React.ReactNode
}

/**
 *  A hook to return a wrapped graphql client
 *
 *  @returns ApolloClient
 */
const useSlerpClient = () => {
  return useApolloClient()
}

/**
 * The Slerp context provider that allows the app to connect
 * to the GraphQL endpoint
 *
 * @param props An object that accepts a config (SlerpConfig) and its children.
 *
 * @example
 *
 *  const config = {
 * apiKey: 'someapikey',
 *   merchant: 'crosstown'
 *  }
 *
 *  <SlerpClient config={config}>
 *    <SomeComponent />
 *  </SlerpClient>
 *
 */
const SlerpClient = (props: SlerpProps) => {
  const {
    config: { apiKey, user }
  } = props

  const { api_key: userApiKey } = user

  return (
    <SlerpContext.Provider value={{ user }}>
      <ApolloProvider client={createClient(apiKey, userApiKey)}>
        {props.children}
      </ApolloProvider>
    </SlerpContext.Provider>
  )
}

const useSlerp = () => {
  return useContext(SlerpContext)
}

export { useSlerpClient, createClient, useSlerp }
export default SlerpClient
