import _, { flatMap, groupBy, pick, toPairs, uniq } from 'lodash'
import { uuid } from 'uuidv4'
import moment from 'moment'
import {
  CategorySpecialAvailabilities,
  ProductCategory
} from 'components/ProductsAndModifiers/Products/Form'
import {
  Category,
  InventoryGroup,
  ModifierGroup,
  Modifier,
  StoreModifier,
  StoreVariant,
  StoreModifiersInsertParams,
  StoreVariantsInsertParams
} from './types'

const formatStoreModifiersForInventoryDrawer = (
  modifiers: StoreModifier[],
  modifierGroups: ModifierGroup[]
): InventoryGroup[] => {
  const grouped = _.chain(modifiers)
    .map((modifier) => {
      return modifier.modifier.modifier_group_modifiers.map((mgm) => {
        return {
          ...modifier,
          groupId: mgm.modifier_group_id,
          allGroupIds: modifier.modifier.modifier_group_modifiers.map(
            (mgm) => mgm.modifier_group_id
          )
        }
      })
    })
    .flatten()
    .groupBy((reference) => reference.groupId)
    .value()
  const filtered = pick(
    grouped,
    modifierGroups.map((group) => group.id)
  ) // only from active modifier groups
  const data = toPairs(filtered).map(([groupId, storeModifiers]) => {
    const group = modifierGroups.find((group) => group.id === groupId) || {
      name: '',
      description: ''
    }

    return {
      id: groupId,
      name: `${group.name} (${group.description})`,
      items: storeModifiers.map((modifier) => {
        return {
          label: modifier.modifier.name,
          value: modifier.modifier_id,
          id: modifier.modifier_id,
          groupId: groupId,
          isPublished: !!modifier.published_at,
          allGroupIds: modifier.allGroupIds,
          orphaned: modifier?.orphaned || false
        }
      })
    }
  })

  return data
}

const formatModifierToStoreModifierFormat = (
  storeModifiers: StoreModifier[],
  modifiers: Modifier[]
) => {
  const inventoryKeepingModifierIds = storeModifiers.map(
    ({ modifier_id }: StoreModifier) => modifier_id
  )
  const orphanedModifier = modifiers.filter(
    ({ id }: Modfifier) => !inventoryKeepingModifierIds.includes(id)
  )

  return orphanedModifier.map((modifier: Modifier) => {
    const { id, name, sku, modifier_group_modifiers } = modifier

    return {
      id: uuid(),
      key: id,
      in_stock: false,
      published_at: null,
      updated_at: null,
      unsnooze_at: null,
      modifier_id: id,
      modifier: {
        name,
        sku,
        modifier_group_modifiers
      },
      orphaned: true
    }
  })
}

const formatDate = (
  date: string | moment.Moment = moment(),
  format = 'YYYY-MM-DD'
) => {
  const momentDate = moment(date, true)
  if (!momentDate.isValid()) {
    console.warn(`formatDate error: given date (${date}) is invalid`)
    return 'Invalid date'
  }
  return momentDate.format(format)
}
const getNextMonth = (date = moment()) => moment(date).add(1, 'M')
const getPreviousMonth = (date = moment()) => moment(date).subtract(1, 'M')

// I cant think of a better name :(
const getDates = (startingDates: (moment.Moment | null | undefined)[]) => {
  return uniq(
    flatMap(startingDates, (date) => {
      if (!date) {
        console.warn(`getDates error: given date (${date}) is empty`)
        return []
      }

      if (!moment(date, true).isValid()) {
        console.warn(`getDates error: given date (${date}) is not valid`)
        return []
      }

      return [date, getPreviousMonth(date), getNextMonth(date)].map((date) =>
        formatDate(date)
      )
    })
  )
}

const filterSavedDates = (dates: string[], savedMonths: string[]) =>
  dates.filter((date) => {
    if (!date) {
      console.warn(`filterSavedDates error: given date (${date}) is empty`)
      return []
    }

    const momentDate = moment(date, true)
    if (!momentDate.isValid()) {
      console.warn(`filterSavedDates error: ${date} is invalid`)
      return false
    }

    return !savedMonths.includes(formatDate(momentDate, 'YYYY-MM'))
  })

type DatesWithOrdersResponse = { month: string; dates: string[] }[]
const formatDatesWithOrders = (
  dates: DatesWithOrdersResponse,
  acc: { [key: string]: string[] }
) =>
  dates.reduce((acc, val) => {
    if (!val) {
      console.warn(`formatDatesWithOrders error: given value (${val}) is empty`)
      return acc
    }

    if (!val.month || !val.dates) {
      console.warn(
        `formatDatesWithOrders error: given values (${val}) are invalid`
      )
      return acc
    }

    if (!Array.isArray(val.dates)) {
      console.warn(
        `formatDatesWithOrders error: dates for the month should be an array. Received: ${val.dates}`
      )
      return acc
    }

    return { ...acc, [val.month]: val.dates || [] }
  }, acc)

const isCategorySpeciallyAvailable = ({
  storeId,
  productCategory,
  isPreorder = false
}: {
  storeId: string
  productCategory: ProductCategory
  isPreorder: boolean
}) => {
  const isSameStoreAndInventory: boolean =
    productCategory.category.category_special_availabilities?.some(
      (csa: CategorySpecialAvailabilities) => {
        const isSameStore =
          csa.special_availability.store_special_availabilities.some(
            ({ store: { id } }) => id === storeId
          )

        switch (csa.special_availability.order_type) {
          case 'sameday':
            return !isPreorder && isSameStore

          case 'preorder':
            return isPreorder && isSameStore

          default:
            return isSameStore
        }
      }
    ) ?? false

  const hasCategorySpecialAvailability: boolean =
    (productCategory.category.category_special_availabilities || []).length > 0

  return isSameStoreAndInventory && hasCategorySpecialAvailability
}

const flattenItems = (groups: InventoryGroup[]) => {
  return _.chain(groups)
    .map((group) => group.items)
    .flatten()
    .value()
}

const flattenUniqueItems = (groups: InventoryGroup[]) => {
  return _.chain(groups)
    .map((o) => o.items.map((item) => item))
    .flatten()
    .uniqBy((o) => o.id)
    .value()
}

const getPublishedIDs = (inventory: InventoryGroup[]) =>
  flattenItems(inventory).reduce((acc: string[], item: InventoryItem) => {
    if (item.isPublished) return [...acc, item.id]
    return acc
  }, [])

const getFlattenedPublishedIDs = (inventory: InventoryItem[]) =>
  inventory.reduce((acc: string[], item: InventoryItem) => {
    if (item.isPublished) return [...acc, item.id]
    return acc
  }, [])

const getUnpublishedIDs = (inventory: InventoryGroup[]) =>
  flattenItems(inventory).reduce((acc: string[], item: InventoryItem) => {
    if (!item.isPublished) return [...acc, item.id]
    return acc
  }, [])

const getFlattenedUnpublishedIDs = (inventory: InventoryItem[]) =>
  inventory.reduce((acc: string[], item: InventoryItem) => {
    if (!item.isPublished) return [...acc, item.id]
    return acc
  }, [])

const countPublished = (inventory: InventoryGroup[]) => {
  return getPublishedIDs(inventory).length
}

const generateStoreModifierInsertParams = ({
  modifierIds,
  storeId,
  isPublished,
  isPreorder,
  inStock
}: StoreModifiersInsertParams) => {
  return modifierIds.map((modifierId) => {
    return {
      id: uuid(),
      modifier_id: modifierId,
      store_id: isPreorder ? null : storeId,
      preorder_store_id: isPreorder ? storeId : null,
      published_at: isPublished ? 'now()' : null,
      in_stock: inStock,
      inserted_at: 'now()',
      updated_at: 'now()'
    }
  })
}

const generateStoreVariantInsertParams = ({
  variantIds,
  storeId,
  isPublished,
  isPreorder,
  inStock
}: StoreVariantsInsertParams) => {
  return variantIds.map((variantId) => {
    return {
      id: uuid(),
      variant_id: variantId,
      store_id: isPreorder ? null : storeId,
      preorder_store_id: isPreorder ? storeId : null,
      published_at: isPublished ? 'now()' : null,
      in_stock: inStock,
      inserted_at: 'now()',
      updated_at: 'now()'
    }
  })
}

export {
  countPublished,
  formatDatesWithOrders,
  filterSavedDates,
  flattenItems,
  flattenUniqueItems,
  getDates,
  getNextMonth,
  getPublishedIDs,
  getUnpublishedIDs,
  getPreviousMonth,
  formatDate,
  formatStoreModifiersForInventoryDrawer,
  formatModifierToStoreModifierFormat,
  isCategorySpeciallyAvailable,
  getFlattenedPublishedIDs,
  getFlattenedUnpublishedIDs,
  generateStoreModifierInsertParams,
  generateStoreVariantInsertParams
}
