import React, { useState, useEffect } from 'react'
import { isNumber as isNumeric, isEmpty } from 'lodash'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { Store as FormValues } from 'antd/lib/form/interface'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Divider from 'antd/lib/divider'
import Tooltip from 'antd/lib/tooltip'
import message from 'antd/lib/message'
import Checkbox from 'antd/lib/checkbox'
import InputNumber from 'antd/lib/input-number'
import {
  GET_MERCHANT_MODIFIER_GROUPS,
  CREATE_MODIFIER,
  UPDATE_MODIFIER
} from '../ModifierQueries'
import { ALLERGENS } from 'components/ProductsAndModifiers/constants'

import { useSession } from '@slerp/accounts'
import { requiredRule, caloriesFieldRule } from './rules'
import '../Modifiers.css'
import ModifierGroupsField from './ModifierGroupsField'
import { uuid } from 'uuidv4'
import { computeGrossPrice, computeNetPrice } from 'components/Utils/price'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { getAvailabilities } from 'components/ProductsAndModifiers/Products/Form/utils'
import generateRandomString from 'components/Utils/RandomStringGenerator'

export type FulfillmentType = 'delivery' | 'pickup' | 'order_at_table'

export interface Availabilities {
  fulfillment_type: FulfillmentType
  is_available: boolean
  id?: string
}

interface Modifier {
  id: string
  name: string
  sku: string
  source_id: string
  price: number
  vat: number
  alcoholic: boolean
  image: string | null
  archived_at: string | null
  modifier_groups: {
    modifier_group_id: string
  }[]
  store_modifiers: StoreModifier[]
  restrictions?: { id: string }
  calorie_data?: {
    id: string
    calories_per_serving: number
    serving_unit: string
    amount_per_serving: number
  }
  allergens: string[]
  availabilities: Availabilities[]
}

interface StoreModifier {
  published_at: string
  in_stock: boolean
}

interface ModifierFormProps {
  modifierInfo?: Modifier
  discardCallback?: () => void
  successCallback?: () => void
}

interface arrangement {
  [id: string]: string
}

interface ModifierGroupModifier {
  modifier_id: string
}

export interface ModifierGroup {
  id: string
  name: string
  description: string
  sku: string | null
  source_id: string
  minimum: number
  minimum_enabled: boolean
  maximum: number
  maximum_enabled: boolean
  archived_at: string | null
  modifier_arrangement: arrangement
  modifier_group_modifiers: ModifierGroupModifier[]
}

const { useForm } = Form

const FULFILLMENT_TYPES = [
  { label: 'Pickup', value: 'pickup' },
  { label: 'Delivery', value: 'delivery' },
  { label: 'Table ordering', value: 'order_at_table' }
]

const ModifierForm = ({
  discardCallback,
  successCallback,
  modifierInfo
}: ModifierFormProps) => {
  const { merchant } = useSession()
  const client = useApolloClient()
  const [form] = useForm()
  const initialValues = {
    name: modifierInfo?.name || '',
    sku: modifierInfo?.sku || `MOD_${generateRandomString(6)}`,
    source_id: modifierInfo?.source_id,
    price: modifierInfo?.price || 0,
    vat: modifierInfo?.vat || 0,
    total_price: computeGrossPrice(
      modifierInfo?.price || 0,
      modifierInfo?.vat || 0
    ),
    alcoholic: modifierInfo?.alcoholic || false,
    published: isEmpty(modifierInfo)
      ? true
      : !!modifierInfo?.store_modifiers.length,
    modifier_groups: (modifierInfo?.modifier_groups || []).map(
      (mg) => mg.modifier_group_id
    ),
    calories: isNumeric(modifierInfo?.calorie_data?.calories_per_serving)
      ? modifierInfo?.calorie_data?.calories_per_serving
      : null,
    allergens: modifierInfo?.allergens || [],
    availability_by_fulfillment_type:
      modifierInfo?.availabilities && modifierInfo?.id
        ? modifierInfo?.availabilities
            .filter(({ is_available }) => is_available)
            .map(({ fulfillment_type }) => fulfillment_type)
        : FULFILLMENT_TYPES.map((ft) => ft.value) || []
  }

  const [modifierGroups, setModifierGroups] = useState<Array<ModifierGroup>>([])
  const [selectedGroups, setSelectedGroups] = useState<Array<string>>(
    initialValues.modifier_groups
  )
  const [grossPrice, setGrossPrice] = useState(initialValues.total_price)
  const [vat, setVat] = useState<number>(initialValues.vat)

  const {
    data: merchantModifierGroupsData,
    refetch: refetchModifiersGroupsData
  } = useQuery(GET_MERCHANT_MODIFIER_GROUPS, {
    variables: {
      merchantId: merchant.id
    }
  })

  const [createModifier] = useMutation(CREATE_MODIFIER, {
    fetchPolicy: 'no-cache'
  })

  const [updateModifier] = useMutation(UPDATE_MODIFIER, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (merchantModifierGroupsData) {
      const { modifier_groups } = merchantModifierGroupsData
      setModifierGroups(modifier_groups)
    }
  }, [merchantModifierGroupsData])

  useEffect(() => {
    form.setFieldsValue({ price: computeNetPrice(grossPrice, vat) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vat, grossPrice])

  const processCreateModifier = (values: FormValues) => {
    const {
      name,
      sku,
      source_id,
      price,
      vat,
      published,
      alcoholic,
      calories,
      allergens,
      availability_by_fulfillment_type
    } = values

    const calorieData = {
      id: modifierInfo?.id || uuid(),
      caloriesPerServing: calories,
      amountPerServing: 1,
      servingUnit: 'servings'
    }

    const availabilities = getAvailabilities(
      null,
      [],
      availability_by_fulfillment_type,
      modifierInfo?.id
    ).map((availability) => ({
      fulfillmentType: availability.fulfillment_type.toLowerCase(),
      isAvailable: availability.is_available
    }))

    message.destroy()
    message.loading(`Creating modifier.. please wait`, 3)

    createModifier({
      variables: {
        name,
        sku,
        price,
        vat,
        allergens,
        availabilities,
        sourceId: source_id,
        isPublished: published,
        modifierGroups: selectedGroups,
        restrictions: { id: uuid(), alcoholic },
        calorieData: isNumeric(calories) ? calorieData : null
      }
    })
      .then(async (result) => {
        message.destroy()
        message.success(`Modifier created!`, 3)
        await refetchModifiersGroupsData()
        successCallback && successCallback()
        client.resetStore()
      })
      .catch((error) => {
        message.destroy()
        message.error(`Unable to create modifier due to ${error.message}`, 3)
      })
  }

  const processUpdateModifier = (values: FormValues) => {
    const {
      name,
      sku,
      source_id,
      price,
      vat,
      published,
      alcoholic,
      calories,
      allergens,
      availability_by_fulfillment_type
    } = values
    const calorieData = {
      id: modifierInfo?.id || uuid(),
      caloriesPerServing: calories,
      amountPerServing: 1,
      servingUnit: 'servings'
    }

    const availabilities = getAvailabilities(
      null,
      [...(modifierInfo?.availabilities || [])],
      availability_by_fulfillment_type,
      modifierInfo?.id
    ).map((availability) => ({
      fulfillmentType: availability.fulfillment_type.toLowerCase(),
      isAvailable: availability.is_available,
      id: availability.id
    }))

    message.destroy()
    message.loading(`Updating modifier.. please wait`, 3)

    updateModifier({
      variables: {
        vat,
        sku,
        name,
        price,
        published,
        allergens,
        availabilities,
        sourceId: source_id,
        id: modifierInfo?.id || '',
        modifierGroups: selectedGroups,
        restrictions: {
          id: modifierInfo?.restrictions?.id || uuid(),
          alcoholic
        },
        calorieData: isNumeric(calories) ? calorieData : null
      }
    })
      .then(async (result) => {
        message.destroy()
        message.success(`Modifier updated!`, 3)
        await refetchModifiersGroupsData()
        successCallback && successCallback()
        client.resetStore()
      })
      .catch((error: Error) => {
        message.destroy()
        message.error(`Unable to update modifier due to ${error.message}`, 3)
      })
  }

  const onFinish = (values: FormValues) => {
    if (modifierInfo?.id) {
      processUpdateModifier(values)
    } else {
      processCreateModifier(values)
    }
  }

  return (
    <>
      <Row>
        <Col span={18}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            data-testid='modifier-form'
          >
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row>
                  <Col>Modifier name:</Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name='name' rules={requiredRule}>
                      <Input
                        type='text'
                        autoFocus
                        required
                        placeholder='Name'
                        data-testid='modifier-form-name'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Row>
                  <Col>SKU:</Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name='sku'
                      rules={[
                        {
                          validator: (_: any, val: string) => {
                            if (val.includes('?')) {
                              return Promise.reject(
                                'Question marks are not allowed'
                              )
                            }
                            return Promise.resolve()
                          }
                        }
                      ]}
                    >
                      <Input
                        type='text'
                        autoFocus
                        disabled={
                          !!modifierInfo?.id && !!modifierInfo?.archived_at
                        }
                        placeholder='Sku'
                        data-testid='modifier-form-sku'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col>PLU:</Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name='source_id'>
                      <Input
                        type='text'
                        autoFocus
                        placeholder='PLU'
                        data-testid='modifier-form-source-id'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row>
                  <Col>Modifier Groups:</Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <ModifierGroupsField
                      modifierGroups={modifierGroups}
                      setModifierGroups={setModifierGroups}
                      selectedGroups={selectedGroups}
                      setSelectedGroups={setSelectedGroups}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row>
                  <Col>Modifier availability:</Col>
                </Row>
                <Row data-testid='modifier-form-container-modifier-availability'>
                  <Col span={24}>
                    <Form.Item name='availability_by_fulfillment_type'>
                      <Checkbox.Group
                        options={FULFILLMENT_TYPES}
                        className='-vertical'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row gutter={[8, 8]}>
                  <Col span={8}>Price</Col>
                  <Col span={8}>
                    VAT
                    <Tooltip
                      title={
                        <span>
                          When listing a product price include VAT and set the
                          relevant{' '}
                          <a
                            href='https://www.gov.uk/guidance/food-products-and-vat-notice-70114'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            VAT band
                          </a>{' '}
                          for off-premise consumption (
                          <a
                            href='https://www.gov.uk/guidance/food-products-and-vat-notice-70114'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            Gov UK Food Products VAT Notice
                          </a>
                          ).
                          <br />
                          <br />
                          Our system then surfaces net and gross price VAT in
                          your statements for your reporting. Find out more.
                        </span>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8 _mr-8' />
                    </Tooltip>
                  </Col>
                  <Col span={8}>Price excluding VAT</Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={8}>
                    <Form.Item
                      name='total_price'
                      className='mb-0'
                      rules={requiredRule}
                    >
                      <InputNumber
                        min={0}
                        precision={2}
                        style={{ width: '100%' }}
                        data-testid='modifier-form-gross-price'
                        onChange={(val: string | number | undefined) => {
                          if (isNumeric(val)) setGrossPrice(val)
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name='vat' className='mb-0' rules={requiredRule}>
                      <InputNumber
                        min={0}
                        className='width-100'
                        data-testid='modifier-form-vat'
                        onChange={(val: string | number | undefined) => {
                          if (isNumeric(val)) setVat(val)
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name='price'
                      className='mb-0'
                      rules={requiredRule}
                    >
                      <InputNumber
                        precision={2}
                        disabled
                        className='width-100'
                        data-testid='modifier-form-price'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col>
                <Row>
                  <Col>Calories (kcal)</Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      name='calories'
                      className='_mb-0'
                      rules={caloriesFieldRule}
                    >
                      <InputNumber
                        min={0}
                        data-testid='modifier-form-calories'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row>
                  <Col>
                    Allergens:
                    <Tooltip
                      title={
                        <span>
                          In line with Natasha's law, it's mandatory to list
                          allergen information at the time of purchase.
                          <br />
                          <br />
                          Find out more{' '}
                          <a
                            href='https://support.slerp.com/knowledge/allergen-tagging-and-listing'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            here
                          </a>
                          .
                        </span>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8' />
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name='allergens'>
                      <Select
                        mode='multiple'
                        style={{ width: '100%' }}
                        placeholder='Select allergen(s)'
                        data-testid='modifier-form-allergens'
                      >
                        {Object.keys(ALLERGENS).map((key) => (
                          <Select.Option
                            key={key}
                            value={key}
                            data-testid={key}
                          >
                            {ALLERGENS[key]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]} className='_pl-2'>
              <Col span={24}>
                <Row align='middle'>
                  <Col span={2}>
                    <Form.Item name='alcoholic' className='mb-0'>
                      <Switch
                        title='Alcoholic'
                        defaultChecked={initialValues.alcoholic}
                        data-testid='modifier-form-alcoholic'
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <span className='pl-half-rem'>Alcoholic</span>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]} hidden={!!modifierInfo?.id} className='_pl-2'>
              <Col span={24}>
                <Row align='middle'>
                  <Col span={2}>
                    <Form.Item name='published' className='mb-0'>
                      <Switch
                        title='Make live to all locations'
                        defaultChecked={initialValues.published}
                        data-testid='modifier-form-published'
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Tooltip title='Enabling this feature will publish this modifier into all your store inventories, ready for customers to purchase'>
                      <span className='pl-half-rem'>
                        Add modifier to all inventories
                      </span>
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider />

            <Row gutter={16}>
              <Col>
                <Button
                  data-testid='product-form-discard'
                  onClick={() => {
                    discardCallback && discardCallback()
                  }}
                  type='ghost'
                >
                  Discard
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  htmlType='submit'
                  data-testid='modifier-form-add-modifier'
                >
                  {`${modifierInfo?.id ? 'Update' : 'Add'} modifier`}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default ModifierForm
