import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef
} from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { isNumber as isNumeric, isEmpty } from 'lodash'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Spin from 'antd/lib/spin'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Switch from 'antd/lib/switch'
import Upload from 'antd/lib/upload'
import Select from 'antd/lib/select'
import message from 'antd/lib/message'
import Tooltip from 'antd/lib/tooltip'
import Checkbox from 'antd/lib/checkbox'
import Typography from 'antd/lib/typography'
import InputNumber from 'antd/lib/input-number'
import { BsStars } from 'react-icons/bs'
import { Spinner } from 'components/Widgets'
import { Category } from '@slerp/controls'
import { uuid } from 'uuidv4'
import { Store } from 'antd/lib/form/interface'
import { useSession } from '@slerp/accounts'
import {
  PlusOutlined,
  UploadOutlined,
  DeleteOutlined,
  LoadingOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'

import {
  GET_MERCHANT_CATEGORIES,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCTS_MULTIPLE_CATEGORIES,
  GENERATE_PRODUCT_SEO
} from '../ProductQueries'
import UploadProductImage, {
  UploadAdditionalImages
} from '../Actions/UploadProductImage'
import { requiredRule, caloriesFieldRule } from './rules'
import '../Products.css'
import CategoryDropdown from '../Categories/CategoryDropdown'
import { computeGrossPrice, computeNetPrice } from 'components/Utils/price'
import generateRandomString from 'components/Utils/RandomStringGenerator'
import { slugify } from '@slerp/helpers'
import { getAvailabilities } from './utils'

import { Product } from '../'
import errorReducer from 'errors/errorReducer'
import Loading from 'components/Utils/Loading'
import SeoLearnMore from 'components/Utils/SeoLearnMore'
import { noWhiteSpaceRule } from 'components/Login/rules'
import styled from '@emotion/styled'

export interface CategorySpecialAvailabilities {
  category_id: string
  special_availability: {
    id: string
    end_time: string
    end_datetime: string
    rrule: string
    order_type: 'all' | 'sameday' | 'preorder'
    store_special_availabilities: {
      store: {
        id: string
      }
    }[]
  }
}
export interface ProductCategory {
  id: string
  product_id: string
  category_id: string
  category: {
    name: string
    category_special_availabilities?: CategorySpecialAvailabilities[]
  }
}

interface ProductInfo extends Product {}

interface Props {
  discardCallback?: () => void
  successCallback?: () => void
  isSeoEnabled: boolean
  productInfo?: ProductInfo
  // Using fixed ids for product and variant because the id
  // is randomly generated upon triggering the product creation
  // and if I don't supply a fixed ID, it wont match any
  // of the provided mocks for product creation
  testProductId?: string
  testVariantId?: string
  formRef?: any
}

const { useForm } = Form
const { TextArea } = Input

export const ALLERGENS = {
  celery: 'Celery',
  cereals: 'Cereals containing gluten',
  crustaceans: 'Crustaceans',
  eggs: 'Eggs',
  fish: 'Fish',
  gluten: 'Gluten',
  lupin: 'Lupin',
  milk: 'Milk',
  molluscs: 'Molluscs',
  mustard: 'Mustard',
  nuts: 'Nuts',
  peanuts: 'Peanuts',
  sesame: 'Sesame',
  soybeans: 'Soybeans',
  sulphur: 'Sulphur dioxide and sulphites',
  treenuts: 'Tree nuts',
  wheat: 'Wheat'
}

const FULFILLMENT_TYPES = [
  { label: 'Pickup', value: 'pickup' },
  { label: 'Delivery', value: 'delivery' },
  { label: 'Table ordering', value: 'order_at_table' }
]

const ProductForm = forwardRef((props: Props) => {
  const { merchant } = useSession()
  const client = useApolloClient()
  const [form] = useForm()

  const { successCallback, productInfo, isSeoEnabled, formRef } = props
  const [updateProduct, { loading: isLoadingUpdateProduct }] = useMutation(
    UPDATE_PRODUCT,
    {
      refetchQueries: [
        'getProductsCategoriesByCategoryIds',
        'getMerchantCategories'
      ]
    }
  )
  const [createProduct, { loading: isLoadingCreateProduct }] = useMutation(
    CREATE_PRODUCT,
    {
      refetchQueries: ['getMerchantProducts']
    }
  )

  const {
    data: productsCategoriesData,
    loading: isLoadingProductsCategoriesData
  } = useQuery(GET_PRODUCTS_MULTIPLE_CATEGORIES, {
    variables: {
      productId: productInfo?.id
    },
    skip: !productInfo?.id,
    nextFetchPolicy: 'no-cache'
  })

  const { loading: isLoadingCategoriesData } = useQuery(
    GET_MERCHANT_CATEGORIES,
    {
      variables: {
        merchantId: merchant.id
      },
      onCompleted: (data) => {
        if (data) {
          const { categories } = data
          setCategories(categories)
        }
      },
      nextFetchPolicy: 'no-cache'
    }
  )

  const hasCalories = isNumeric(
    productInfo?.default_variant?.length &&
      productInfo?.default_variant[0].calorie_data?.calories_per_serving
  )

  const initialValues = {
    name: productInfo?.name || '',
    sku: productInfo?.sku || `PROD_${generateRandomString(6)}`,
    source_id: productInfo?.source_id || '',
    description: productInfo?.description || '',
    seo_description: productInfo?.seo_description || '',
    total_price: computeGrossPrice(
      productInfo?.default_price || 0,
      productInfo?.default_vat || 0
    ),
    default_price: productInfo?.default_price || 0,
    default_vat: productInfo?.default_vat || 0,
    default_limit: productInfo?.default_limit || 0,
    alcoholic: productInfo?.alcoholic || false,
    publish_to_stores: true,
    allergens: productInfo?.allergens || [],
    availabilities_by_fulfillment_type:
      productInfo?.availabilities && productInfo?.id
        ? productInfo?.availabilities
            .filter(({ is_available }) => is_available)
            .map(({ fulfillment_type }) => fulfillment_type)
        : FULFILLMENT_TYPES.map((ft) => ft.value) || [],
    calories: hasCalories
      ? (productInfo?.default_variant[0] || {}).calorie_data
          ?.calories_per_serving
      : null,
    product_category_ids: productsCategoriesData?.products_categories.map(
      (pc: ProductCategory) => pc.category_id
    )
  }

  const [orderLimitSwitch, setOrderLimitSwitch] = useState<boolean>(
    initialValues.default_limit > 0
  )
  const [grossPrice, setGrossPrice] = useState(initialValues.total_price)
  const [defaultVat, setDefaultVat] = useState<number>(
    initialValues.default_vat
  )
  const [images, setImages] = useState<File[]>([])
  const [uploadingImages, setUploadingImages] = useState<boolean>(false)
  const [categories, setCategories] = useState<Category[]>([])
  const [openMetaModal, setOpenMetaModal] = useState<boolean>(false)
  const [productName, setProductName] = useState<string>(initialValues.name)
  const [productDescription, setProductDescription] = useState<string>(
    initialValues.description
  )

  const [getMerchantSeo, { loading: isSeoLoading }] = useMutation(
    GENERATE_PRODUCT_SEO,
    {
      variables: {
        productId: productInfo?.id,
        merchantId: merchant.id,
        name: productName,
        description: productDescription
      },
      onCompleted: (data) => {
        form.setFieldsValue({
          seo_description: data.generateProductSeo.seo_description
        })
      }
    }
  )

  const processCreateProduct = (values: Store) => {
    const {
      name,
      sku,
      description,
      seo_description,
      product_category_ids,
      default_price,
      default_vat,
      default_limit,
      alcoholic,
      publish_to_stores,
      allergens,
      calories,
      availabilities_by_fulfillment_type,
      source_id
    } = values

    const calorieData = {
      calories_per_serving: calories,
      serving_unit: 'servings',
      amount_per_serving: 1
    }

    form.validateFields().then(() => {
      message.loading('Creating Product... Please Wait.')
      const variables = {
        name: name.trim(),
        sku: sku,
        source_id: source_id,
        description: description,
        seo_description: seo_description,
        default_vat: default_vat,
        price: default_price,
        default_limit: default_limit,
        publish_to_stores: publish_to_stores,
        restrictions: {
          alcoholic: alcoholic
        },
        allergens,
        calorieData: isNumeric(calories) ? calorieData : null,
        fulfillment_types: availabilities_by_fulfillment_type,
        category_ids: product_category_ids
      }

      createProduct({
        variables
      })
        .then(async ({ data }) => {
          message.destroy()
          message.success(`Product ${name} created!`, 3)

          //images
          if (!isEmpty(images)) {
            message.destroy()
            message.info('Uploading images...', 3)
            setUploadingImages(true)
            await UploadProductImage({
              variantId: data.createProduct.default_variant_id,
              image: images[0]
            }).then(async () => {
              const additionalImages = images.slice(1)
              if (!isEmpty(additionalImages)) {
                await UploadAdditionalImages({
                  variantId: data.createProduct.default_variant_id,
                  images: additionalImages
                })
              }
            })
            message.destroy()
            message.info('Images uploaded!', 3)
            setUploadingImages(false)
          }

          message.destroy()
          client.resetStore()
          successCallback && successCallback()
          message.success(`Product ${name} created!`, 3)
        })
        .catch((error: Error) => {
          throw errorReducer({
            origin: 'Product',
            data: {
              error: error,
              message: error.message
            }
          })
        })
        .catch((error) => {
          message.destroy()
          client.resetStore()
          message.error(error.message, 4)
          setUploadingImages(false)
        })
    })
  }

  const processUpdateProduct = (values: Store) => {
    const {
      sku,
      source_id,
      name,
      description,
      seo_description,
      default_price,
      default_vat,
      default_limit,
      alcoholic,
      allergens,
      calories,
      availabilities_by_fulfillment_type,
      product_category_ids
    } = values

    const calorieData = {
      id: productInfo?.id || uuid(),
      calories_per_serving: calories,
      amount_per_serving: 1,
      serving_unit: 'servings'
    }

    const availabilities = getAvailabilities(
      productInfo.id,
      [...(productInfo?.availabilities || [])],
      availabilities_by_fulfillment_type
    )

    form.validateFields().then(() => {
      if (productInfo?.id) {
        message.loading('Updating Product... Please Wait.')
        updateProduct({
          variables: {
            product_id: productInfo.id,
            name: name.trim(),
            slug: slugify(name.trim()),
            description: description,
            seo_description: seo_description,
            default_vat: default_vat,
            price: default_price,
            default_limit: default_limit || 0,
            publish_to_stores: productInfo.publish_to_stores,
            sku: sku,
            source_id: source_id,
            restrictions: {
              alcoholic: alcoholic
            },
            allergens,
            calorieData: isNumeric(calories) ? calorieData : null,
            availabilities: props.testProductId ? [] : availabilities,
            category_ids: product_category_ids,
            category_id: product_category_ids[0]
          }
        })
          .then((res) => {
            message.destroy()
            client.resetStore()

            message.success(`Product ${name} Updated!`, 3)
            successCallback && successCallback()
          })
          .catch((error: Error) => {
            throw errorReducer({
              origin: 'Product',
              data: {
                error: error,
                message: error.message
              }
            })
          })
          .catch((error) => {
            message.destroy()
            client.resetStore()
            message.error(error.message, 4)
          })
      }
    })
  }

  const onFinish = (values: Store) => {
    if (productInfo?.id) {
      processUpdateProduct(values)
    } else {
      processCreateProduct(values)
    }
  }

  const handleCreateCategory = (searchValue: string) => {
    const current_category_ids =
      form.getFieldValue('product_category_ids') ?? []

    form.setFieldsValue({
      product_category_ids: [...current_category_ids, searchValue]
    })
    setCategories((prev) => [
      ...prev,
      { id: searchValue, name: searchValue, slug: slugify(searchValue) }
    ])
  }

  const removeImage = (index: number) =>
    setImages([
      ...images.slice(0, index),
      ...images.slice(index + 1, images.length)
    ])

  const replaceImage = (image: File, index: number) =>
    setImages([
      ...images.slice(0, index),
      image,
      ...images.slice(index + 1, images.length)
    ])

  useImperativeHandle(
    formRef,
    () => ({
      submit: () => {
        form.submit()
      }
    }),
    []
  )

  useEffect(() => {
    form.setFieldsValue({
      default_price: computeNetPrice(grossPrice, defaultVat)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grossPrice, defaultVat])

  if (isLoadingCategoriesData || isLoadingProductsCategoriesData)
    return <Loading />

  return (
    <>
      <SeoLearnMore
        openMetaModal={openMetaModal}
        setOpenMetaModal={setOpenMetaModal}
      />
      <Row>
        <Col span={23}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            data-testid='product-form'
            labelCol={{
              span: 24
            }}
          >
            <Row gutter={[8, 8]} data-testid='product-form-container-name'>
              <Col span={24}>
                <Form.Item
                  name='name'
                  rules={[...noWhiteSpaceRule]}
                  label={
                    <Row align='middle' className='_mb-0'>
                      <Col>Product name:</Col>
                    </Row>
                  }
                  required
                >
                  <Input
                    type='text'
                    autoFocus
                    placeholder='Name'
                    data-testid='product-form-name'
                    onChange={(e) => {
                      setProductName(e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col>
                <Form.Item
                  name='sku'
                  rules={[
                    {
                      validator: (_: any, val: string) => {
                        if (val.includes('?')) {
                          return Promise.reject(
                            'Question marks are not allowed'
                          )
                        }
                        return Promise.resolve()
                      }
                    }
                  ]}
                  label={
                    <Row align='middle' className='_mb-0'>
                      <FormLabel>SKU:</FormLabel>
                      <Tooltip
                        title={
                          <span>
                            This is not compulsory. A Stock Keeping Unit, is a
                            unique number used to internally track your product
                            inventory and used for production and logistics.
                            <br />
                            <br />
                            Find our more about our order management tool{' '}
                            <a
                              href='https://www.slerp.com/order-management/'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              here
                            </a>
                            .
                          </span>
                        }
                      >
                        <QuestionCircleOutlined className='_ml-8' />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Input
                    type='text'
                    autoFocus
                    placeholder='SKU'
                    disabled={!!productInfo?.id && !!productInfo?.archived_at}
                    data-testid='product-form-sku'
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name='source_id'
                  label={
                    <Row align='middle' className='_mb-0'>
                      <FormLabel>PLU:</FormLabel>
                      <Tooltip
                        title={
                          <span>
                            POS ID
                            <br />
                            This is the identifier we use to connect this
                            product to the POS.
                          </span>
                        }
                      >
                        <QuestionCircleOutlined className='_ml-8 _mr-8' />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Input
                    type='text'
                    autoFocus
                    placeholder='PLU'
                    data-testid='product-form-source-id'
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row data-testid='product-form-container-description'>
              <Col span={24}>
                <Form.Item
                  label={
                    <Row align='middle' className='_mb-0'>
                      <span className='_mr-8'>Product description:</span>
                      <Tooltip
                        title={
                          <span>
                            Be sure to include keywords related to products such
                            as 'chocolate' for the customer search function
                            feature.
                          </span>
                        }
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Row>
                  }
                  name='description'
                  rules={[...noWhiteSpaceRule]}
                  required
                >
                  <TextArea
                    onChange={(e) => {
                      setProductDescription(e.target.value)
                    }}
                    autoFocus
                    rows={4}
                    placeholder='Description'
                    data-testid='product-form-description'
                  />
                </Form.Item>
              </Col>
            </Row>
            {isSeoEnabled && (
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <FormLabel>SEO description:</FormLabel>

                      <div style={{ position: 'relative' }}>
                        <Form.Item name='seo_description'>
                          <TextArea
                            autoSize={{ minRows: 2 }}
                            placeholder='Enter SEO description'
                            data-testid='product-form-meta-description'
                            maxLength={160}
                            disabled={isSeoLoading}
                            showCount={{
                              formatter: ({ count, maxLength }) =>
                                `Remaining characters: ${
                                  (maxLength ?? 160) - count
                                }`
                            }}
                          />
                        </Form.Item>
                        {isSeoLoading && (
                          <div className='overflow-container'>
                            <Spinner />
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row justify='end' gutter={[8, 8]}>
                    <Col>
                      <Button
                        style={{
                          color: '#77778E',
                          background: '#FAFAFB',
                          borderStyle: 'none'
                        }}
                        onClick={() => setOpenMetaModal(true)}
                      >
                        LEARN MORE
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        disabled={
                          isSeoLoading || !productName || !productDescription
                        }
                        onClick={() => getMerchantSeo()}
                        type='ghost'
                      >
                        <BsStars style={{ marginRight: '8px' }} />
                        GENERATE
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            <Row gutter={[8, 8]} data-testid='product-form-container-category'>
              <Col span={24}>
                <Row>
                  <Col>Category:</Col>
                </Row>
                <Row data-testid='product-form-container-category'>
                  <Col span={24}>
                    <CategoryDropdown
                      categories={categories}
                      categoryLoading={
                        isLoadingProductsCategoriesData ||
                        isLoadingCategoriesData ||
                        isLoadingCreateProduct ||
                        isLoadingUpdateProduct
                      }
                      handleCreateCategory={handleCreateCategory}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              gutter={[8, 8]}
              data-testid='product-form-container-product-availability'
            >
              <Col span={24}>
                <Form.Item
                  name='availabilities_by_fulfillment_type'
                  label={
                    <Row className='_mb-0'>
                      <FormLabel>Product availability:</FormLabel>
                    </Row>
                  }
                >
                  <Checkbox.Group
                    options={FULFILLMENT_TYPES}
                    className='-vertical'
                  />
                </Form.Item>
              </Col>
            </Row>

            {isEmpty(productInfo?.id) && (
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Row align='middle'>
                    <FormLabel>Image:</FormLabel>
                    <Tooltip
                      title={
                        <span>
                          Keep a clear and consistent look and feel across your
                          photography to increase customer engagement and sales.
                          Learn more{' '}
                          <a
                            href='https://support.slerp.com/en_gb/categories/products-H1ZdJ7G6u'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            here
                          </a>
                          .
                        </span>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8 _mr-8' />
                    </Tooltip>
                  </Row>
                  <Row gutter={12} data-testid='product-form-images'>
                    {images.map((image, index) => (
                      <Col span={6}>
                        <Spin
                          spinning={uploadingImages}
                          size='large'
                          indicator={<LoadingOutlined spin />}
                        >
                          <div className='image-preview-container'>
                            <div className='overlay'>
                              <Upload
                                name='avatar'
                                accept='.png, .jpg, .jpeg'
                                showUploadList={false}
                                beforeUpload={(file: File) =>
                                  replaceImage(file, index)
                                }
                              >
                                <Button
                                  icon={<UploadOutlined />}
                                  type='text'
                                  size='large'
                                  className='image-preview-action'
                                />
                              </Upload>
                              <Button
                                icon={<DeleteOutlined />}
                                type='text'
                                size='large'
                                className='image-preview-action'
                                onClick={() => removeImage(index)}
                              />
                            </div>
                            <img
                              className='preview'
                              src={URL.createObjectURL(image)}
                              alt='product preview'
                            />
                          </div>
                        </Spin>
                      </Col>
                    ))}
                    {images.length < 4 && (
                      <Col span={images.length > 0 ? 6 : 24}>
                        <Upload
                          name='avatar'
                          accept='.png, .jpg, .jpeg'
                          listType='picture-card'
                          className={`avatar-uploader ${
                            images.length === 0 ? '-large' : '-square'
                          }`}
                          multiple
                          showUploadList={false}
                          beforeUpload={(_file, files: File[]) => {
                            const filesToUpload = files.slice(
                              0,
                              4 - images.length
                            )
                            setImages([...images, ...filesToUpload])
                          }}
                        >
                          <PlusOutlined className='icon' />
                          <Typography.Text className='title'>
                            {images.length < 3
                              ? `Upload up to ${4 - images.length} photos`
                              : 'Upload'}
                          </Typography.Text>
                          <Typography.Text className='subheading'>
                            Maximum 5MB, 1024 x 1024 pixels
                          </Typography.Text>
                        </Upload>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            )}

            <Row gutter={[8, 8]}>
              <Col span={8} data-testid='product-form-container-total-price'>
                <Form.Item
                  name='total_price'
                  className='mb-0'
                  rules={requiredRule}
                  label={<FormLabel>Price</FormLabel>}
                >
                  <InputNumber
                    min={0}
                    precision={2}
                    style={{ width: '100%' }}
                    data-testid='product-form-total-price'
                    onChange={(val) => isNumeric(val) && setGrossPrice(val)}
                  />
                </Form.Item>
              </Col>
              <Col span={8} data-testid='product-form-container-vat'>
                <Form.Item
                  name='default_vat'
                  className='mb-0'
                  rules={requiredRule}
                  label={
                    <Row align='middle' className='_mb-0'>
                      <FormLabel>VAT</FormLabel>
                      <Tooltip
                        title={
                          <span>
                            When listing a product price include VAT and set the
                            relevant{' '}
                            <a
                              href='https://www.gov.uk/guidance/food-products-and-vat-notice-70114'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              VAT band
                            </a>{' '}
                            for off-premise consumption (
                            <a
                              href='https://www.gov.uk/guidance/food-products-and-vat-notice-70114'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              Gov UK Food Products VAT Notice
                            </a>
                            ).
                            <br />
                            <br />
                            Our system then surfaces net and gross price VAT in
                            your statements for your reporting. Find out more.
                          </span>
                        }
                      >
                        <QuestionCircleOutlined className='_ml-8 _mr-8' />
                      </Tooltip>
                    </Row>
                  }
                >
                  <InputNumber
                    min={0}
                    className='width-100'
                    data-testid='product-form-vat'
                    onChange={(val: string | number | undefined) => {
                      if (isNumeric(val)) setDefaultVat(val)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='default_price'
                  rules={requiredRule}
                  label={<FormLabel>Price excluding VAT</FormLabel>}
                >
                  <InputNumber
                    disabled
                    precision={2}
                    min={0}
                    className='width-100'
                    data-testid='product-form-price'
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col>
                <Row>
                  <Col>Calories (kcal)</Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      name='calories'
                      className='mb-0'
                      rules={caloriesFieldRule}
                    >
                      <InputNumber
                        min={0}
                        data-testid='product-form-calories'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]} className='_mb-24'>
              <Col span={24}>
                <Form.Item
                  name='allergens'
                  label={
                    <Row align='middle' className='_mb-0'>
                      <FormLabel>Allergens:</FormLabel>
                      <Tooltip
                        title={
                          <span>
                            In line with Natasha's law, it's mandatory to list
                            allergen information at the time of purchase.
                            <br />
                            <br />
                            Find out more{' '}
                            <a
                              href='https://support.slerp.com/knowledge/allergen-tagging-and-listing'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              here
                            </a>
                            .
                          </span>
                        }
                      >
                        <QuestionCircleOutlined className='_ml-8' />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Select
                    mode='multiple'
                    style={{ width: '100%' }}
                    placeholder='Select allergen(s)'
                    data-testid='product-form-allergens'
                  >
                    {Object.keys(ALLERGENS).map((key) => (
                      <Select.Option key={key} value={key} data-testid={key}>
                        {ALLERGENS[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 8]} className='_pl-8 _mb-4'>
              <Col span={24}>
                <Row align='middle' gutter={[8, 8]} className='_mb-24'>
                  <Switch
                    title='Order limit'
                    data-testid='product-form-order-limit-toggle'
                    onChange={() => setOrderLimitSwitch(!orderLimitSwitch)}
                    checked={orderLimitSwitch}
                  />
                  <span className='pl-half-rem'>Order limit</span>
                  <Tooltip
                    title={
                      <span>
                        Limits the number of items that can be purchased by a
                        customer for a single product.
                      </span>
                    }
                  >
                    <QuestionCircleOutlined className='_ml-8' />
                  </Tooltip>
                </Row>
                {orderLimitSwitch && (
                  <Row data-testid='product-form-container-default-limit'>
                    <Col span={24}>
                      <Form.Item name='default_limit' rules={requiredRule}>
                        <InputNumber
                          min={0}
                          precision={0}
                          data-testid='product-form-default-limit'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row
              gutter={[8, 8]}
              className={`_pl-8 ${
                isEmpty(productInfo?.id) ? '_mb-24' : '_mb-64'
              }`}
              align='middle'
            >
              <Form.Item name='alcoholic' className='_mb-0'>
                <Switch
                  title='Alcoholic'
                  defaultChecked={initialValues.alcoholic}
                  data-testid='product-form-alcoholic'
                />
              </Form.Item>
              <FormLabel className='pl-half-rem'>Alcoholic</FormLabel>
              <Tooltip
                title={
                  <span>
                    This will trigger an over 18s consent prompt for this
                    product at checkout.
                  </span>
                }
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </Row>

            {isEmpty(productInfo?.id) && (
              <Row gutter={[8, 8]} className='_pl-8 _mb-64' align='middle'>
                <Form.Item name='publish_to_stores' className='_mb-0'>
                  <Switch
                    title='Make live to all locations'
                    defaultChecked={initialValues.publish_to_stores}
                    data-testid='product-form-published'
                  />
                </Form.Item>
                <Tooltip title='Enabling this feature will make this product live in all your stores, ready for customers to purchase'>
                  <span className='pl-half-rem'>
                    Add product to all inventories and mark it as in stock
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    <a
                      href='https://support.slerp.com/knowledge/publishing-products'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Publishing Products
                    </a>
                  }
                >
                  <QuestionCircleOutlined className='_ml-8' />
                </Tooltip>
              </Row>
            )}
          </Form>
        </Col>
      </Row>
    </>
  )
})

const FormLabel = styled.span`
  line-height: 1;
`

export default ProductForm
