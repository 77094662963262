import React, { useEffect, useState } from 'react'
import { debounce, isEmpty, slice } from 'lodash'
import moment from 'moment-timezone'
import { useSession } from '@slerp/accounts'
import { useQuery } from '@apollo/client'
import { useMutation } from '@apollo/client'
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  message,
  Row,
  Select,
  Skeleton,
  Switch,
  Table,
  Tag,
  Tooltip
} from 'antd'
import converter from 'csvtojson'
import {
  EditOutlined,
  QuestionCircleOutlined,
  ClockCircleOutlined
} from '@ant-design/icons'
import { uuid } from 'uuidv4'
import env from 'env'
import SnoozeButton from './SnoozeButton'
import { isItemSnoozed } from './utils'
import InventoryPublishDrawer from './InventoryPublishDrawer'
import SpecialAvailabilityDrawer from './SpecialAvailability'
import PricingDrawer from './Pricings/Drawer'
import {
  UNPUBLISH_STORE_VARIANTS,
  PUBLISH_STORE_VARIANTS,
  PUBLISH_PRE_ORDER_STORE_VARIANTS,
  UNPUBLISH_PRE_ORDER_STORE_VARIANTS,
  PUBLISH_STORE_MODIFIERS,
  UNPUBLISH_STORE_MODIFIERS,
  PUBLISH_PRE_ORDER_STORE_MODIFIERS,
  UNPUBLISH_PRE_ORDER_STORE_MODIFIERS,
  GET_MERCHANT_CATEGORIES,
  GET_MERCHANT_MODIFIER_GROUPS,
  UPDATE_SAMEDAY_VARIANT_STOCK_COUNT,
  UPDATE_PREORDER_VARIANT_STOCK_COUNT,
  GET_STORE_PREORDER_INVENTORY,
  GET_STORE_SAMEDAY_INVENTORY,
  MARK_IN_STOCK_STORE_VARIANTS,
  MARK_OUT_OF_STOCK_STORE_VARIANTS,
  MARK_IN_STOCK_PRE_ORDER_STORE_VARIANTS,
  MARK_OUT_OF_STOCK_PRE_ORDER_STORE_VARIANTS,
  MARK_IN_STOCK_STORE_MODIFIERS,
  MARK_OUT_OF_STOCK_STORE_MODIFIERS,
  MARK_IN_STOCK_PRE_ORDER_STORE_MODIFIERS,
  MARK_OUT_OF_STOCK_PRE_ORDER_STORE_MODIFIERS,
  SNOOZE_STORE_VARIANT,
  UNSNOOZE_STORE_VARIANT,
  SNOOZE_STORE_MODIFIER,
  UNSNOOZE_STORE_MODIFIER,
  CREATE_STORE_MODIFIERS,
  CREATE_STORE_VARIANTS,
  UPDATE_STORE_CATEGORIES_ARRANGEMENTS,
  UPDATE_STORE_MODIFIER_GROUP_ARRANGEMENTS
} from './DashboardQueries'
import { MUTATE_BULK_STOCK_COUNTS } from './../StockQueries'
import { SelectPrefixWrapper } from './../../Widgets/'
import { GET_PRODUCTS_CATEGORIES_BY_CATEGORY_IDS } from 'components/Locations/Dashboard/DashboardQueries'
import { ProductCategory } from 'components/ProductsAndModifiers/Products/Form'
import { isCategorySpeciallyAvailable } from './helpers'
import { AppsIcon, FilterIcon, SelectDownArrowIcon } from '@slerp/assets'
import {
  Category,
  CategoryIdsParam,
  InventoriesProps,
  ModifierGroup,
  StoreInventory,
  StoreModifier,
  StoreVariant,
  StoreVariantMultipleCategories
} from './types'

const { Search } = Input

const Inventories = (props: InventoriesProps) => {
  const { storeInfo, stores, isPreorder } = props
  const [storeVariants, setStoreVariants] = useState<Array<StoreVariant>>([])
  const { merchant } = useSession()
  const [storeModifiers, setStoreModifiers] = useState<Array<StoreModifier>>([])
  const [modifierGroups, setModifierGroups] = useState<Array<ModifierGroup>>([])
  const [inventoryProfile, setInventoryProfile] = useState('variants')
  const [processingVariantStockToggle, setProcessingVariantStockToggle] =
    useState<Array<string>>([])
  const [isVariantTogglingStockState, setIsVariantTogglingStockState] =
    useState(false)
  const [processingModifierStockToggle, setProcessingModifierStockToggle] =
    useState<Array<string>>([])
  const [isModifierTogglingStockState, setIsModifierTogglingStockState] =
    useState(false)
  const [categoryIdFilter, setCategoryIdFilter] = useState('all')
  const [modifierGroupIdFilter, setModifierGroupIdFilter] = useState('all')
  const [selectedVariantsRowKeys, setSelectedVariantsRowKeys] = useState<
    Array<String>
  >([])
  const [selectedModifiersRowKeys, setSelectedModifiersRowKeys] = useState<
    Array<String>
  >([])
  const [fuzzySearchKeyword, setFuzzySearchKeyword] = useState('')
  const [variantStockCountLoading, setVariantStockCountLoading] = useState<
    Array<string>
  >([])
  const [modifierStockCountLoading, setModifierStockCountLoading] = useState<
    Array<string>
  >([])
  const [isInventoryDrawerOpen, setIsInventoryDrawerOpen] = useState(false)
  const [isSpecialAvailabilityDrawerOpen, setIsSpecialAvailabilityDrawerOpen] =
    useState<boolean>(false)
  const [csvFile, setCsvFile] = useState()
  const [uploading, setUploading] = useState<boolean>(false)
  const [isPricingDrawerVisible, setIsPricingDrawerVisible] =
    useState<boolean>(false)
  const [selectedStoreVariant, setSelectedStoreVariant] =
    useState<StoreVariant | null>(null)
  const [modifiedStoreInventory, setModifiedStoreInventory] =
    useState<StoreInventory>()
  const [isLoadingPristineInventory, setIsLoadingPristineInventory] =
    useState<boolean>(true)
  const [categoryIds, setCategoryIds] = useState<CategoryIdsParam[]>([])
  const [categories, setCategories] = useState<Category[]>([])

  const {
    data: storeInventoryData,
    refetch: refetchStoreInventory,
    loading: isLoadingStoreInventory
  } = useQuery<StoreInventory>(
    isPreorder ? GET_STORE_PREORDER_INVENTORY : GET_STORE_SAMEDAY_INVENTORY,
    {
      variables: { storeId: storeInfo.id },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (!storeInventoryData) return
        const { store_modifiers } = storeInventoryData

        if (modifierGroupIdFilter === 'all') {
          setStoreModifiers(store_modifiers.filter(modifiersFuzzySearchHandler))
        } else {
          const filteredStoreModifiers = store_modifiers.filter(
            (storeModifier: StoreModifier) => {
              const {
                modifier: { modifier_group_modifiers }
              } = storeModifier
              const modifierGroupIds = modifier_group_modifiers.map(
                (mgm) => mgm.modifier_group_id
              )
              return modifierGroupIds.includes(modifierGroupIdFilter)
            }
          )
          setStoreModifiers(
            filteredStoreModifiers.filter(modifiersFuzzySearchHandler)
          )
        }
      }
    }
  )
  const { data: categoriesData, loading: isLoadingCategories } = useQuery<{
    categories: Category[]
  }>(GET_MERCHANT_CATEGORIES, {
    variables: { merchantId: merchant.id },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  const { data: modifierGroupsData } = useQuery(GET_MERCHANT_MODIFIER_GROUPS, {
    variables: { merchantId: merchant.id },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: isLoadingProductsCategories,
    refetch: refetchProductsCategories
  } = useQuery<{ products_categories: ProductCategory[] }>(
    GET_PRODUCTS_CATEGORIES_BY_CATEGORY_IDS,
    {
      variables: {
        categoryIds: props.mockCategoryIdsParam ?? categoryIds
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      skip: props.skip ?? (isLoadingCategories || !categoriesData),
      onCompleted: (data: { products_categories: ProductCategory[] }) => {
        if (!storeInventoryData) return
        const { store_variants, store_modifiers } = storeInventoryData
        const { products_categories } = data

        const variantsWithCategories = store_variants.map(
          (sv: StoreVariant) => {
            const involvedCategories = products_categories.filter(
              (pc: ProductCategory) =>
                pc.product_id === sv.product_variant.product.id
            )

            return {
              ...sv,
              categories: involvedCategories
            }
          }
        )

        setModifiedStoreInventory({
          store_modifiers: store_modifiers,
          store_variants: variantsWithCategories
        })
      }
    }
  )

  const [unPublishStoreVariants] = useMutation(UNPUBLISH_STORE_VARIANTS, {
    fetchPolicy: 'no-cache'
  })
  const [publishStoreVariants] = useMutation(PUBLISH_STORE_VARIANTS, {
    fetchPolicy: 'no-cache'
  })
  const [unPublishPreOrderStoreVariants] = useMutation(
    UNPUBLISH_PRE_ORDER_STORE_VARIANTS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [publishPreOrderStoreVariants] = useMutation(
    PUBLISH_PRE_ORDER_STORE_VARIANTS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [unPublishStoreModifiers] = useMutation(UNPUBLISH_STORE_MODIFIERS, {
    fetchPolicy: 'no-cache'
  })
  const [publishStoreModifiers] = useMutation(PUBLISH_STORE_MODIFIERS, {
    fetchPolicy: 'no-cache'
  })
  const [unPublishPreOrderStoreModifiers] = useMutation(
    UNPUBLISH_PRE_ORDER_STORE_MODIFIERS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [publishPreOrderStoreModifiers] = useMutation(
    PUBLISH_PRE_ORDER_STORE_MODIFIERS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [createStoreModifiers] = useMutation(CREATE_STORE_MODIFIERS, {
    fetchPolicy: 'no-cache'
  })
  const [createStoreVariants] = useMutation(CREATE_STORE_VARIANTS, {
    fetchPolicy: 'no-cache'
  })
  const [markInStockStoreVariants] = useMutation(MARK_IN_STOCK_STORE_VARIANTS, {
    fetchPolicy: 'no-cache'
  })
  const [markOutOfStockStoreVariants] = useMutation(
    MARK_OUT_OF_STOCK_STORE_VARIANTS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [markInStockPreOrderStoreVariants] = useMutation(
    MARK_IN_STOCK_PRE_ORDER_STORE_VARIANTS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [markOutOfStockPreOrderStoreVariants] = useMutation(
    MARK_OUT_OF_STOCK_PRE_ORDER_STORE_VARIANTS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const [markInStockStoreModifiers] = useMutation(
    MARK_IN_STOCK_STORE_MODIFIERS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [markOutOfStockStoreModifiers] = useMutation(
    MARK_OUT_OF_STOCK_STORE_MODIFIERS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [markInStockPreOrderStoreModifiers] = useMutation(
    MARK_IN_STOCK_PRE_ORDER_STORE_MODIFIERS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [markOutOfStockPreOrderStoreModifiers] = useMutation(
    MARK_OUT_OF_STOCK_PRE_ORDER_STORE_MODIFIERS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const [updateVariantSamedayStockCount] = useMutation(
    UPDATE_SAMEDAY_VARIANT_STOCK_COUNT,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const [updateVariantPreorderStockCount] = useMutation(
    UPDATE_PREORDER_VARIANT_STOCK_COUNT,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const [snoozeStoreVariant, { loading: snoozingStoreVariant }] = useMutation(
    SNOOZE_STORE_VARIANT,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const [unsnoozeStoreVariant, { loading: unsnoozingStoreVariant }] =
    useMutation(UNSNOOZE_STORE_VARIANT, {
      fetchPolicy: 'no-cache'
    })

  const [snoozeStoreModifier, { loading: snoozingStoreModifier }] = useMutation(
    SNOOZE_STORE_MODIFIER,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const [unsnoozeStoreModifier, { loading: unsnoozingStoreModifier }] =
    useMutation(UNSNOOZE_STORE_MODIFIER, {
      fetchPolicy: 'no-cache'
    })

  const [bulkStockCountsUpload] = useMutation(MUTATE_BULK_STOCK_COUNTS)

  const [updateStoreCategoriesArrangements] = useMutation(
    UPDATE_STORE_CATEGORIES_ARRANGEMENTS
  )

  const [updateStoreModifierGroupArrangements] = useMutation(
    UPDATE_STORE_MODIFIER_GROUP_ARRANGEMENTS
  )

  const resetFormState = () => {
    setIsVariantTogglingStockState(false)
    setProcessingVariantStockToggle([])
    setVariantStockCountLoading([])
  }

  const removeQuotes = (value: string) => {
    if (value) return value.replace('"', '')
    return ''
  }

  const onCSVDownload = () => {
    const items = storeInventoryData?.store_variants?.reduce(
      (acc: BulkUploadOutgoingType[], value: StoreVariant) => {
        const variant = {
          category_name: removeQuotes(
            value?.product_variant?.product?.category?.name
          ),
          product_name: removeQuotes(value?.product_variant?.name),
          id: value?.id,
          sku: removeQuotes(value?.product_variant?.sku),
          stock_available: `${value?.stock_count - value?.stock_sold}`,
          enabled: value?.stock_type === 'manual' ? 'true' : 'false'
        }
        return [...acc, variant]
      },
      []
    )
    if (items && items.length > 0) {
      const replacer = (key: string, value: string) => (!!!value ? '' : value)
      const header = Object.keys(items[0])
      const csv = [
        header.join(','),
        // assigned any but real type is BulkUploadOutgoingType to fix type issue for string index access
        ...items.map((row: any | BulkUploadOutgoingType) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(',')
        )
      ].join('\r\n')
      return csv
    }
    return ''
  }

  const onCSVUpload = () => {
    setUploading(true)
    const fileReader = new FileReader()
    fileReader.readAsText(csvFile)
    fileReader.onloadend = () => {
      converter({
        trim: true,
        checkType: true,
        includeColumns: /(id|stock_available|enabled)/
      })
        .fromString(fileReader.result as string)
        .then((rows) => {
          bulkStockCountsUpload({
            variables: { bulkStockCountsParams: rows, storeId: storeInfo.id }
          })
            .then((payload) => {
              return refetchStoreInventory()
            })
            .then(() => {
              message.success('Bulk stock count upload success!', 4)
              setUploading(false)
              setCsvFile(undefined)
            })
            .catch((error) => {
              message.error(`Stock counts not updated: ${error.message}`, 4)
              setUploading(false)
            })
        })
    }
  }

  const variantsFuzzySearchHandler = (variant: StoreVariant) => {
    const {
      product_variant: { sku = '', name }
    } = variant
    const parsedFilter = fuzzySearchKeyword.toLowerCase()
    return (
      name.toLowerCase().includes(parsedFilter) ||
      (sku || '').toLowerCase().includes(parsedFilter)
    )
  }

  const modifiersFuzzySearchHandler = (modifier: StoreModifier) => {
    const {
      modifier: { sku = '', name }
    } = modifier
    const parsedFilter = fuzzySearchKeyword.toLowerCase()
    return (
      name.toLowerCase().includes(parsedFilter) ||
      (sku || '').toLowerCase().includes(parsedFilter)
    )
  }

  useEffect(() => {
    if (!categoriesData) return
    const { categories } = categoriesData

    const ids = categories?.map((category: Category) => ({
      category_id: { _eq: category.id }
    }))

    setCategories(categories)
    setCategoryIds(ids)
  }, [categoriesData])

  useEffect(() => {
    if (modifiedStoreInventory) {
      const { store_modifiers, store_variants } = modifiedStoreInventory

      const storeVariants = store_variants.filter(
        (storeVariant: StoreVariant) => storeVariant.product_variant !== null
      )
      const storeModifiers = store_modifiers.filter(
        (storeModifier: StoreModifier) => storeModifier.modifier !== null
      )

      resetFormState()
      if (categoryIdFilter === 'all') {
        setIsLoadingPristineInventory(false)
        setStoreVariants(storeVariants.filter(variantsFuzzySearchHandler))
      } else {
        const filteredStoreVariants = storeVariants.filter(
          (storeVariant: StoreVariantMultipleCategories) => {
            return storeVariant?.categories?.some(
              (category: ProductCategory) =>
                category.category_id === categoryIdFilter
            )
          }
        )
        setStoreVariants(
          filteredStoreVariants.filter(variantsFuzzySearchHandler)
        )
        setIsLoadingPristineInventory(false)
      }
      if (modifierGroupIdFilter === 'all') {
        setIsLoadingPristineInventory(false)
        setStoreModifiers(storeModifiers.filter(modifiersFuzzySearchHandler))
      } else {
        const filteredStoreModifiers = storeModifiers.filter(
          (storeModifier: StoreModifier) => {
            const {
              modifier: { modifier_group_modifiers }
            } = storeModifier
            const modifierGroupIds = modifier_group_modifiers.map(
              (mgm) => mgm.modifier_group_id
            )
            return modifierGroupIds.includes(modifierGroupIdFilter)
          }
        )
        setStoreModifiers(
          filteredStoreModifiers.filter(modifiersFuzzySearchHandler)
        )
        setIsLoadingPristineInventory(false)
      }
    }
    setIsLoadingPristineInventory(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modifiedStoreInventory,
    categoryIdFilter,
    modifierGroupIdFilter,
    fuzzySearchKeyword
  ])

  useEffect(() => {
    if (modifierGroupsData) {
      const { modifier_groups } = modifierGroupsData
      setModifierGroups(modifier_groups)
    }
  }, [modifierGroupsData])

  useEffect(() => {
    setIsPricingDrawerVisible(!isEmpty(selectedStoreVariant))
  }, [selectedStoreVariant])

  const togglePublishStatusForVariants = (ids: string[], value: boolean) => {
    let mutation
    if (isPreorder) {
      mutation = value
        ? publishPreOrderStoreVariants
        : unPublishPreOrderStoreVariants
    } else {
      mutation = value ? publishStoreVariants : unPublishStoreVariants
    }
    mutation({
      variables: {
        variantIds: ids,
        storeId: storeInfo.id
      }
    })
      .then((result) => {
        refetchStoreInventory()
      })
      .then(() => refetchProductsCategories())

      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to toggle publish state for store variants. Due to ${error.message}`,
          3
        )
      })
  }

  const toggleInStockForVariants = (ids: string[], value: boolean) => {
    let mutation
    if (isPreorder) {
      mutation = value
        ? markInStockPreOrderStoreVariants
        : markOutOfStockPreOrderStoreVariants
    } else {
      mutation = value ? markInStockStoreVariants : markOutOfStockStoreVariants
    }

    setIsVariantTogglingStockState(true)
    setProcessingVariantStockToggle(ids)

    mutation({
      variables: {
        storeId: storeInfo.id,
        variantIds: ids
      }
    })
      .then((result) => {
        const ids = result.data.update_store_variants.returning.map(
          ({ id }: StoreVariant) => id
        )

        setProcessingVariantStockToggle(ids)
        setIsVariantTogglingStockState(false)
        setStoreVariants((prev: StoreVariant[]) => {
          const updatedStoreVariants = prev.map((sv) => {
            const updatedStoreVariantStatus: StoreVariant =
              result.data.update_store_variants.returning.find(
                (r: StoreVariant) => r.id === sv.id
              )

            return updatedStoreVariantStatus
              ? {
                  ...sv,
                  in_stock: updatedStoreVariantStatus?.in_stock
                }
              : sv
          })

          return updatedStoreVariants
        })
      })
      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to set stock availability for store variants. Due to ${error.message}`,
          3
        )
      })
  }

  const toggleInStockForModifiers = (ids: string[], value: boolean) => {
    let mutation
    if (isPreorder) {
      mutation = value
        ? markInStockPreOrderStoreModifiers
        : markOutOfStockPreOrderStoreModifiers
    } else {
      mutation = value
        ? markInStockStoreModifiers
        : markOutOfStockStoreModifiers
    }

    setIsModifierTogglingStockState(true)
    setProcessingModifierStockToggle(ids)

    mutation({
      variables: {
        storeId: storeInfo.id,
        modifierIds: ids
      }
    })
      .then((result) => {
        setStoreModifiers((prev: StoreModifier[]) => {
          const updatedStoreModifiers = prev.map((sv) => {
            const updatedStoreModifierStatus: StoreModifier =
              result.data.update_store_modifiers.returning.find(
                (r: StoreModifier) => r.id === sv.id
              )

            return updatedStoreModifierStatus
              ? {
                  ...sv,
                  in_stock: updatedStoreModifierStatus?.in_stock
                }
              : sv
          })

          return updatedStoreModifiers
        })
      })
      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to set stock availability for store modifiers. Due to ${error.message}`,
          3
        )
      })
      .finally(() => {
        setIsModifierTogglingStockState(false)
        setProcessingModifierStockToggle([])
      })
  }

  const togglePublishStatusForModifiers = (ids: string[], value: boolean) => {
    let mutation
    if (isPreorder) {
      mutation = value
        ? publishPreOrderStoreModifiers
        : unPublishPreOrderStoreModifiers
    } else {
      mutation = value ? publishStoreModifiers : unPublishStoreModifiers
    }
    mutation({
      variables: {
        modifierIds: ids,
        storeId: storeInfo.id
      }
    })
      .then((result) => {
        refetchStoreInventory()
      })
      .then(() => refetchProductsCategories())
      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to toggle publish state for store modifiers. Due to ${error.message}`,
          3
        )
      })
  }

  const snoozeStoreVariantHandler = (
    storeVariantId: string,
    snooze: boolean
  ) => {
    const mutation = snooze ? unsnoozeStoreVariant : snoozeStoreVariant
    setVariantStockCountLoading([...variantStockCountLoading, storeVariantId])
    mutation({ variables: { storeVariantId } })
      .then((result) => refetchStoreInventory())
      .then(() => refetchProductsCategories())
      .catch((error) => {
        const msg =
          error.message == 'no_schedule_available_for_day'
            ? 'Product/Modifier cannot be snoozed. There are no shifts available today during the locations opening hours'
            : `Unable to snooze stock variant. Due to ${error.message}`
        setVariantStockCountLoading(
          variantStockCountLoading.filter(
            (val: string) => val !== storeVariantId
          )
        )
        message.destroy()
        message.error(msg, 3)
      })
  }

  const snoozeStoreModifierHandler = (
    storeModifierId: string,
    snooze: boolean
  ) => {
    const mutation = snooze ? unsnoozeStoreModifier : snoozeStoreModifier
    setModifierStockCountLoading([...variantStockCountLoading, storeModifierId])
    mutation({ variables: { storeModifierId } })
      .then((result) => {
        refetchStoreInventory()
      })
      .catch((error) => {
        const msg =
          error.message == 'no_schedule_available_for_day'
            ? 'Product/Modifier cannot be snoozed. There are no shifts available today during the locations opening hours'
            : `Unable to snooze stock variant. Due to ${error.message}`

        setModifierStockCountLoading(
          modifierStockCountLoading.filter(
            (val: string) => val !== storeModifierId
          )
        )
        message.destroy()
        message.error(msg, 3)
      })
  }

  const STORE_VARIANT_COLUMNS = [
    {
      title: 'Product Name',
      dataIndex: ['product_variant', 'name'],
      key: 'key',
      width: '20%',
      render: (productName: string, storeVariant: StoreVariant) => {
        const { variant_id } = storeVariant
        const imageUrl = `https://gear.${
          env.ASSET_HOST
        }/assets/variant/${variant_id}?size=original&${moment().unix()}`
        return (
          <>
            <Row align='middle' gutter={[8, 8]} className='_mb-0'>
              <Col>
                <Avatar size={30} shape='square' src={imageUrl} />
              </Col>
              <Col span={20}>
                <span
                  data-testid='inventory-dashboard-store-pricing-link'
                  className='cursor-pointer'
                  onClick={() => setSelectedStoreVariant(storeVariant)}
                >
                  {productName}
                </span>
              </Col>
            </Row>
          </>
        )
      }
    },
    {
      title: 'Category',
      dataIndex: ['categories'],
      key: 'category',
      width: '20%',
      render: (categories: ProductCategory[]) => {
        let aggregatedViewEnabled = false
        let visibleGroups = []
        let aggregatedCategories: ProductCategory[] = []

        if ((categories || []).length === 3) {
          visibleGroups = [...categories]
        } else {
          aggregatedViewEnabled = (categories || []).length > 2
          visibleGroups = aggregatedViewEnabled
            ? slice(categories, 0, 2)
            : [...categories]
          aggregatedCategories = slice(categories || [], 2)
        }

        const tags = visibleGroups.map((pc: ProductCategory) => {
          const isSpeciallyAvailable = isCategorySpeciallyAvailable({
            productCategory: pc,
            isPreorder: isPreorder ?? false,
            storeId: storeInfo.id
          })

          return (
            <Tag
              className={`-basic ${
                isSpeciallyAvailable ? 'default-source' : ''
              }`}
            >
              {isSpeciallyAvailable ? <ClockCircleOutlined /> : <></>}
              {pc.category.name}
            </Tag>
          )
        })

        if (aggregatedViewEnabled) {
          tags.push(
            <Tooltip
              title={
                <ul className='_pl-16 _mb-0'>
                  {aggregatedCategories.map((pc: ProductCategory) => {
                    const isSpeciallyAvailable = isCategorySpeciallyAvailable({
                      productCategory: pc,
                      isPreorder: isPreorder ?? false,
                      storeId: storeInfo.id
                    })

                    return (
                      <li
                        className={`${
                          isSpeciallyAvailable ? '-specially-available' : ''
                        }`}
                      >
                        {pc.category.name}
                      </li>
                    )
                  })}
                </ul>
              }
              placement='right'
            >
              <Tag className='-basic -plain-text-format'>
                {`+ ${aggregatedCategories.length} more categories`}
              </Tag>
            </Tooltip>
          )
        }

        return (
          <Row gutter={[8, 8]} className='_mb-0'>
            {tags}
          </Row>
        )
      }
    },
    {
      title: 'SKU',
      dataIndex: ['product_variant', 'sku'],
      key: 'product_variant.sku',
      width: '10%'
    },
    {
      title: (
        <>
          Reset{' '}
          <Tooltip
            title={
              <span>
                The reset function allows you to set when your stock count
                returns to par levels. This can be set to manual, daily or to a
                specific day of the week in the dropdown below. Learn about
                stock count{' '}
                <a
                  href='https://support.slerp.com/knowledge/understanding-inventories'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  here
                </a>
                .
              </span>
            }
          >
            <QuestionCircleOutlined className='_ml-8 _mr-8' />
          </Tooltip>
        </>
      ),
      key: 'stock_type',
      width: '160px',
      render: (record: StoreVariant) => <StockReset variant={record} />
    },
    {
      key: 'divider',
      width: '5%',
      render: () => <div>to</div>
    },
    {
      title: (
        <>
          <span>Default reset amount</span>
          <Tooltip
            placement='right'
            title={
              <a
                href='https://support.slerp.com/en_us/stock-counts-v1-B1HPD54pu'
                target='_blank'
                rel='noopener noreferrer'
              >
                Stock Counts
              </a>
            }
          >
            <QuestionCircleOutlined className='_ml-8' />
          </Tooltip>
        </>
      ),
      key: 'stock_count',
      width: 160,
      render: (record: StoreVariant) => <StockCount variant={record} />
    },
    {
      title: 'Remaining stock',
      key: 'left_in_stock',
      render: (variant: StoreVariant) => {
        const { id, stock_type, stock_count, stock_sold } = variant
        const isLoading = variantStockCountLoading.includes(id)
        if (isLoading)
          return <Skeleton.Input style={{ width: '88px' }} active />
        // stock_type could be null, undefined or ''
        const available = isEmpty(stock_type) ? 0 : stock_count - stock_sold

        return (
          <InputNumber
            data-testid='inventory-dashboard-available-stock'
            disabled={true}
            value={available}
            className='_max-w-sm'
          />
        )
      },
      width: 160
    },
    {
      title: 'In Stock',
      dataIndex: 'in_stock',
      key: 'in_stock',
      width: 240,
      render: (inStock: boolean, { variant_id, unsnooze_at }: StoreVariant) => {
        const loading = processingVariantStockToggle.includes(variant_id)
        const snoozed = isItemSnoozed(unsnooze_at)
        return (
          <Switch
            loading={loading}
            checked={inStock}
            disabled={isVariantTogglingStockState || snoozed}
            onChange={(e) => toggleInStockForVariants([variant_id], e)}
          />
        )
      }
    }
  ]

  const VARIANT_SNOOZE_COLUMN = [
    {
      title: (
        <>
          Snooze
          <Tooltip
            title={
              <span>
                Product snooze allows you to mark a product temporarily out of
                stock. For more information read{' '}
                <a
                  href='https://support.slerp.com/en_us/product-snooze-BJWZXzfoc'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  here
                </a>
                .
              </span>
            }
          >
            <QuestionCircleOutlined className='_ml-8 _mr-8' />
          </Tooltip>
        </>
      ),
      dataIndex: 'snooze',
      key: 'snooze',
      width: 160,
      render: (val: undefined | null, { id, unsnooze_at }: StoreVariant) => {
        return (
          <SnoozeButton
            key={uuid()}
            itemId={id}
            unsnoozeAt={unsnooze_at}
            onClickHandler={snoozeStoreVariantHandler}
            loading={snoozingStoreVariant || unsnoozingStoreVariant}
            disabled={snoozingStoreVariant || unsnoozingStoreVariant}
            dataTestId='inventory-dashboard-snooze-product'
          />
        )
      }
    }
  ]

  const STORE_MODIFIER_COLUMNS = [
    {
      title: 'Modifier Name',
      dataIndex: ['modifier', 'name'],
      key: 'modifier_name'
    },
    {
      title: 'SKU',
      dataIndex: ['modifier', 'sku'],
      key: 'modifier_sku'
    },
    {
      title: 'In Stock',
      dataIndex: 'in_stock',
      key: 'in_stock',
      width: '100px',
      render: (
        inStock: boolean,
        { modifier_id, unsnooze_at, modifier }: StoreModifier
      ) => {
        const loading = processingModifierStockToggle.includes(modifier_id)
        return (
          <Switch
            loading={loading}
            checked={inStock}
            disabled={isModifierTogglingStockState || !isEmpty(unsnooze_at)}
            onChange={(value: boolean) =>
              toggleInStockForModifiers([modifier_id], value)
            }
            data-testid={`modifiers-${modifier.name}-in-stock-switch`}
          />
        )
      }
    }
  ]

  const MODIFIER_SNOOZE_COLUMN = [
    {
      title: (
        <>
          <span>Snooze</span>
          <Tooltip
            title={
              <span>
                Modifier snooze allows you to mark a product temporarily out of
                stock. For more information read{' '}
                <a
                  href='https://support.slerp.com/en_us/preview/article/modifier-snooze-Bkjm7RHn5'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  here
                </a>
                .
              </span>
            }
          >
            <QuestionCircleOutlined className='_ml-8 _mr-8' />
          </Tooltip>
        </>
      ),
      dataIndex: 'snooze',
      key: 'snooze',
      width: 240,
      render: (val: undefined | null, { id, unsnooze_at }: StoreVariant) => {
        return (
          <SnoozeButton
            key={uuid()}
            itemId={id}
            unsnoozeAt={unsnooze_at}
            onClickHandler={snoozeStoreModifierHandler}
            loading={snoozingStoreModifier || unsnoozingStoreModifier}
            disabled={snoozingStoreModifier || unsnoozingStoreModifier}
            dataTestId='inventory-dashboard-snooze-modifier'
          />
        )
      }
    }
  ]

  const onVariantRowCheckboxChange = (keys: any) => {
    setSelectedVariantsRowKeys(keys)
  }

  const variantTableRowSelection = {
    selectedRowKeys: selectedVariantsRowKeys,
    onChange: onVariantRowCheckboxChange
  }

  const onModifierRowCheckboxChange = (keys: any) => {
    setSelectedModifiersRowKeys(keys)
  }

  const modifierTableRowSelection = {
    selectedRowKeys: selectedModifiersRowKeys,
    onChange: onModifierRowCheckboxChange
  }

  const storeVariantsActions = (action: string) => {
    const storeVariantsId = storeVariants
      .filter((sv: StoreVariant) => selectedVariantsRowKeys.includes(sv.id))
      .map((sv) => sv.variant_id)

    switch (action) {
      case 'markInStock':
        toggleInStockForVariants(storeVariantsId, true)
        break
      case 'markOutStock':
        toggleInStockForVariants(storeVariantsId, false)
        break
      case 'publish':
        togglePublishStatusForVariants(storeVariantsId, true)
        break
      case 'unpublish':
        togglePublishStatusForVariants(storeVariantsId, false)
        break
    }
  }

  const storeModifiersActions = (action: string) => {
    const storeModifiersId = storeModifiers
      .filter((sv: StoreModifier) => selectedModifiersRowKeys.includes(sv.id))
      .map((sv) => sv.modifier_id)

    switch (action) {
      case 'publish':
        togglePublishStatusForModifiers(storeModifiersId, true)
        break
      case 'unpublish':
        togglePublishStatusForModifiers(storeModifiersId, false)
        break
    }
  }

  const handleActionMenuProcedure = (event: any) => {
    const { key } = event
    if (inventoryProfile === 'variants') {
      storeVariantsActions(key)
    } else {
      storeModifiersActions(key)
    }
  }

  const actionMenus = () => {
    return (
      <Menu onClick={handleActionMenuProcedure}>
        {inventoryProfile === 'variants' && (
          <Menu.Item key='markInStock'>Mark In-Stock</Menu.Item>
        )}
        {inventoryProfile === 'variants' && (
          <Menu.Item key='markOutStock'>Mark Out-Stock</Menu.Item>
        )}
        <Menu.Item key='publish'>Publish</Menu.Item>
        <Menu.Item key='unpublish'>Unpublish</Menu.Item>
      </Menu>
    )
  }

  const debouncedFuzzySearchKeyword = debounce(setFuzzySearchKeyword, 200)
  const isActionMenuDisabled = () => {
    let isDisabled = true
    if (inventoryProfile === 'variants') {
      isDisabled = selectedVariantsRowKeys.length > 0 ? false : true
    } else {
      isDisabled = selectedModifiersRowKeys.length > 0 ? false : true
    }
    return isDisabled
  }

  const submitStockCountUpdate = (
    id: string,
    params: {
      variantId: string
      stockType: string
      stockCount: number
      stockSold: number
      stockResetCount: number
    }
  ) => {
    setVariantStockCountLoading([...variantStockCountLoading, id])
    params.stockCount = parseInt(params.stockCount || 0)
    params.stockType = isEmpty(params.stockType) ? null : params.stockType
    const mutation = isPreorder
      ? updateVariantPreorderStockCount
      : updateVariantSamedayStockCount

    mutation({ variables: { ...params, storeId: storeInfo.id } })
      .then((result) => {
        const { id, stock_count, stock_type, stock_reset_count } =
          result.data.update_store_variants.returning[0]

        setVariantStockCountLoading((prev) => prev.filter((vId) => vId !== id))

        setStoreVariants((prev: StoreVariant[]) => {
          const storeVariant: StoreVariant = prev.find((sv) => sv.id === id)!

          const updatedVariant = {
            ...storeVariant,
            stock_type,
            stock_count,
            stock_reset_count
          }

          const updatedStoreVariants = prev.map((sv) => {
            return storeVariant.id === sv.id ? updatedVariant : sv
          })

          return updatedStoreVariants
        })
      })
      .catch((error) => {
        setVariantStockCountLoading(
          variantStockCountLoading.filter((val: string) => val !== id)
        )
        message.destroy()
        message.error(
          `Unable to update stock count. Due to ${error.message}`,
          3
        )
      })
  }

  const constructParams = (variant: StoreVariant) => {
    return {
      variantId: variant.variant_id,
      stockType: variant.stock_type || '',
      stockCount: 0,
      stockSold: 0,
      stockResetCount: 0
    }
  }

  const StockReset = ({ variant }: { variant: StoreVariant }) => {
    const isLoading = variantStockCountLoading.includes(variant.id)
    const params = constructParams(variant)

    const options = [
      { value: '', label: 'Disabled' },
      { value: 'manual', label: 'Manual' },
      { value: 'daily', label: 'Daily' },
      { value: 'weekly_monday', label: 'Weekly (Monday)' },
      { value: 'weekly_tuesday', label: 'Weekly (Tuesday)' },
      { value: 'weekly_wednesday', label: 'Weekly (Wednesday)' },
      { value: 'weekly_thursday', label: 'Weekly (Thursday)' },
      { value: 'weekly_friday', label: 'Weekly (Friday)' },
      { value: 'weekly_saturday', label: 'Weekly (Saturday)' },
      { value: 'weekly_sunday', label: 'Weekly (Sunday)' }
    ]

    if (isLoading) return <Skeleton.Input style={{ width: '160px' }} active />

    return (
      <Select
        loading={isLoading}
        disabled={isLoading}
        value={variant.stock_type || ''}
        style={{ width: '160px' }}
        onSelect={(value) => {
          if (value !== '') {
            params.stockResetCount = variant.stock_reset_count
            params.stockSold = variant.stock_sold
            params.stockCount = variant.stock_count
          }
          submitStockCountUpdate(variant.id, { ...params, stockType: value })
        }}
        data-testid='inventory-dashboard-stock-reset'
      >
        {options.map((opt) => (
          <Select.Option
            title={opt.label}
            value={opt.value}
            label={opt.label}
            disabled={
              isPreorder && opt.value !== 'daily' && opt.label !== 'Disabled'
            }
          >
            {opt.label}
          </Select.Option>
        ))}
      </Select>
    )
  }

  const StockCount = ({ variant }: { variant: StoreVariant }) => {
    const isLoading = variantStockCountLoading.includes(variant.id)
    const params = constructParams(variant)

    if (isLoading)
      return <Skeleton.Input size='default' style={{ width: '88px' }} active />

    return (
      <InputNumber
        data-testid='inventory-dashboard-stock-count-input'
        disabled={isLoading || (variant.stock_type || '') === ''}
        defaultValue={variant.stock_reset_count || 0}
        min={0}
        className='_max-w-sm'
        onChange={debounce((value) => {
          if (value >= 0)
            submitStockCountUpdate(variant.id, {
              ...params,
              stockResetCount: value || 0,
              stockCount: value || 0
            })
        }, 1000)}
      />
    )
  }

  return (
    <>
      <Row align='middle' justify='space-between'>
        <Col>
          <Row>
            <Col className='_ml-4'>
              <SelectPrefixWrapper
                prefixicon={<FilterIcon />}
                style={{ marginRight: '24px' }}
              >
                <Select
                  style={{ width: '100%' }}
                  defaultValue={inventoryProfile}
                  onChange={setInventoryProfile}
                  suffixIcon={<SelectDownArrowIcon />}
                  data-testid='inventory-dashboard-inventory-type-select'
                >
                  <Select.Option key='variants' value='variants'>
                    Products
                  </Select.Option>
                  <Select.Option key='modifiers' value='modifiers'>
                    Modifiers
                  </Select.Option>
                </Select>
              </SelectPrefixWrapper>
            </Col>
            <Col>
              {inventoryProfile === 'variants' && (
                <SelectPrefixWrapper prefixicon={<AppsIcon />}>
                  <Select
                    suffixIcon={<SelectDownArrowIcon />}
                    className='_w-100 _mr-24'
                    defaultValue='all'
                    onChange={setCategoryIdFilter}
                    data-testid='inventory-dashboard-category-select'
                    loading={isLoadingCategories}
                  >
                    <Select.Option key='all' value='all'>
                      All Categories
                    </Select.Option>
                    {categories.map((category) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </SelectPrefixWrapper>
              )}
              {inventoryProfile === 'modifiers' && (
                <SelectPrefixWrapper prefixicon={<AppsIcon />}>
                  <Select
                    suffixIcon={<SelectDownArrowIcon />}
                    className='_w-100 _mr-24'
                    defaultValue='all'
                    onChange={setModifierGroupIdFilter}
                    data-testid='inventory-dashboard-modifier-group-select'
                  >
                    <Select.Option key='all' value='all'>
                      All Modifier Groups
                    </Select.Option>
                    {modifierGroups.map((modifierGroup) => (
                      <Select.Option
                        key={modifierGroup.id}
                        value={modifierGroup.id}
                      >
                        {modifierGroup.name}
                      </Select.Option>
                    ))}
                  </Select>
                </SelectPrefixWrapper>
              )}
            </Col>
            <Col className='_ml-4'>
              <Button
                icon={
                  <ClockCircleOutlined
                    className='_mb-0'
                    style={{
                      position: 'relative',
                      top: '-0.5px'
                    }}
                  />
                }
                className='_ht-36'
                onClick={() => setIsSpecialAvailabilityDrawerOpen(true)}
                data-testid='inventory-dashboard-special-availability-btn'
              >
                Special Availability
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row justify='end' gutter={8} align='middle'>
            <Col>
              <Search
                placeholder='Search'
                onChange={(e) => debouncedFuzzySearchKeyword(e.target.value)}
                data-testid='inventory-dashboard-search-input'
              />
            </Col>
            <Col>
              <Dropdown.Button
                overlay={actionMenus}
                disabled={isActionMenuDisabled()}
                data-testid='inventory-dashboard-actions-dd'
              >
                Actions
              </Dropdown.Button>
            </Col>
            <Col>
              <Button
                icon={<EditOutlined style={{ verticalAlign: '0.125em' }} />}
                onClick={() => setIsInventoryDrawerOpen(true)}
                data-testid='inventory-dashboard-edit-inventory-btn'
              >
                Edit Inventory
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify='center'>
        <Col span={24}>
          {inventoryProfile === 'variants' && (
            <Table
              loading={
                isLoadingCategories ||
                isLoadingStoreInventory ||
                isLoadingProductsCategories ||
                isLoadingPristineInventory
              }
              data-testid='inventory-dashboard-variants-inventory-table'
              rowSelection={variantTableRowSelection}
              columns={
                !isPreorder
                  ? [...STORE_VARIANT_COLUMNS, ...VARIANT_SNOOZE_COLUMN]
                  : STORE_VARIANT_COLUMNS
              }
              dataSource={storeVariants}
              rowKey='id'
            />
          )}
          {inventoryProfile === 'modifiers' && (
            <Table
              loading={isLoadingStoreInventory || isLoadingPristineInventory}
              data-testid='inventory-dashboard-modifiers-inventory-table'
              rowSelection={modifierTableRowSelection}
              columns={
                !isPreorder
                  ? [...STORE_MODIFIER_COLUMNS, ...MODIFIER_SNOOZE_COLUMN]
                  : STORE_MODIFIER_COLUMNS
              }
              dataSource={storeModifiers}
              rowKey='id'
            />
          )}
        </Col>
      </Row>
      {selectedStoreVariant && (
        <PricingDrawer
          visible={isPricingDrawerVisible}
          onClose={() => {
            setSelectedStoreVariant(null)
            setIsPricingDrawerVisible(false)
          }}
          storeVariantId={selectedStoreVariant.id}
          isPreorder={isPreorder}
          storeId={storeInfo.id}
          variantName={selectedStoreVariant.product_variant.name}
          productId={selectedStoreVariant.product_variant.product.id}
        />
      )}
      <InventoryPublishDrawer
        storeInfo={storeInfo}
        isPreorder={isPreorder}
        isVisible={isInventoryDrawerOpen}
        onClose={() => setIsInventoryDrawerOpen(false)}
        publishStoreVariants={
          isPreorder ? publishPreOrderStoreVariants : publishStoreVariants
        }
        unpublishStoreVariants={
          isPreorder ? unPublishPreOrderStoreVariants : unPublishStoreVariants
        }
        publishStoreModifiers={
          isPreorder ? publishPreOrderStoreModifiers : publishStoreModifiers
        }
        unpublishStoreModifiers={
          isPreorder ? unPublishPreOrderStoreModifiers : unPublishStoreModifiers
        }
        refetchStoreInventory={refetchStoreInventory}
        createStoreModifiers={createStoreModifiers}
        createStoreVariants={createStoreVariants}
        updateStoreCategoriesArrangements={updateStoreCategoriesArrangements}
        updateStoreModifierGroupArrangements={
          updateStoreModifierGroupArrangements
        }
      />
      <SpecialAvailabilityDrawer
        isVisible={isSpecialAvailabilityDrawerOpen}
        onClose={() => setIsSpecialAvailabilityDrawerOpen(false)}
        categories={categories}
        storeId={storeInfo.id}
        stores={stores}
        isPreorder={isPreorder}
      />
    </>
  )
}

export default Inventories
