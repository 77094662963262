import moment from 'moment-timezone'
import { uuid } from 'uuidv4'
import { MerchantSettings } from '@slerp/controls'
import { Store } from 'antd/lib/form/interface'
import { Category } from '../Categories/CategoriesList'
import { MessageType } from 'antd/lib/message'

interface UpdateMerchantArrangementSettingsParams {
  variables: {
    merchant: string
    arrangement: MerchantSettings
  }
}

interface CreateCategoryParams {
  variables: {
    merchantSlug: string
    name: string
    description: string
  }
}

interface ToggleArchiveStateParams {
  variables: {
    categoryId: string
    archivedAt: string | null
  }
}

interface ToggleProductArchiveStateParams {
  variables: {
    productIds: Array<string>
    archivedAt: string | null
  }
}

interface ProductVariant {
  id: string
  archived_at: string
  default_variant: boolean
  image: string
  inserted_at: string
  limit: number
  name: string
  options: Array<object>
  price: number
  quantity: number
  restrictions: {
    id: string
    alcoholic: boolean
  }
  sku: string
  vat: number
  store_variants: {
    store_id?: string
    preorder_store_id?: string
    published_at: string | null
  }[]
}

interface VariantsProps {
  productVariants: Array<ProductVariant>
  storeIds: Array<string>
  values: Store
  productId: string
  merchantId: string
  publishToStores: boolean
}

type CreateCategory = {
  data: { createCategory: Category }
}

const createCategory = (
  merchantSlug: string,
  name: string,
  description: string,
  merchantSettings: MerchantSettings,
  updateMerchantArrangementSettings: (
    variables: UpdateMerchantArrangementSettingsParams
  ) => Promise<unknown>,
  createCategory: (variables: CreateCategoryParams) => Promise<unknown>,
  errorHandler: (err: Error) => MessageType
): Promise<CreateCategory> => {
  return createCategory({
    variables: {
      merchantSlug,
      name,
      description
    }
  }).then((result: CreateCategory) => {
    const { category_arrangement } = merchantSettings
    const updatedCategoryArrangement = Object.values(category_arrangement)
    updatedCategoryArrangement.push(result?.data?.createCategory.id)

    updateMerchantArrangementSettings({
      variables: {
        merchant: merchantSlug,
        arrangement: {
          ...merchantSettings,
          category_arrangement: { ...updatedCategoryArrangement }
        }
      }
    }).catch((err: Error) => errorHandler(err))

    return result as CreateCategory
  })
}

interface ToggleCategoryArchiveStateProps {
  categoryId: string
  shouldArchive: boolean
  merchantSlug: string
  merchantSettings: MerchantSettings
  toggleArchiveState: (variables: ToggleArchiveStateParams) => Promise<unknown>
  toggleProductArchiveState: (
    variables: ToggleProductArchiveStateParams
  ) => Promise<unknown>
  updateMerchantArrangementSettings: (
    variables: UpdateMerchantArrangementSettingsParams
  ) => Promise<unknown>
  affectedProducts: {
    id: string
    default_variant_id: string
  }[]
}

const toggleCategoryArchiveState = ({
  categoryId,
  shouldArchive,
  merchantSlug,
  merchantSettings,
  toggleArchiveState,
  toggleProductArchiveState,
  updateMerchantArrangementSettings,
  affectedProducts
}: ToggleCategoryArchiveStateProps) => {
  const hasOne = affectedProducts.length > 0
  const archivedAt = shouldArchive ? moment().format() : null
  const updatedCategoryArchiveState = toggleArchiveState({
    variables: {
      categoryId,
      archivedAt
    }
  })

  const productIds = affectedProducts.map((ap) => ap.id)

  const { product_arrangement, category_arrangement } = merchantSettings
  const productArrangement = Object.values(product_arrangement)
  const categoryArrangement = Object.values(category_arrangement)

  const updatedProductArrangement = shouldArchive
    ? productArrangement.filter((id: string) => !productIds.includes(id))
    : [...productArrangement, ...productIds]

  const updatedCategoryArrangement = shouldArchive
    ? categoryArrangement.filter((id: string) => id !== categoryId)
    : [...categoryArrangement, categoryId]

  const archiveOrRestoreProducts = hasOne
    ? toggleProductArchiveState({
        variables: {
          productIds: productIds,
          archivedAt: shouldArchive ? moment().format() : null
        }
      })
    : () => Promise.resolve()

  const updatedArrangement = updateMerchantArrangementSettings({
    variables: {
      merchant: merchantSlug,
      arrangement: {
        ...merchantSettings,
        category_arrangement: { ...updatedCategoryArrangement },
        ...(hasOne && { product_arrangement: { ...updatedProductArrangement } })
      }
    }
  })

  return Promise.all([
    updatedCategoryArchiveState,
    archiveOrRestoreProducts,
    updatedArrangement
  ])
}

const buildStoreVariants = (props: VariantsProps) => {
  const { productVariants, storeIds, values, productId, merchantId } = props

  let insertValues: {
    id: String
    name: String
    description: string | null
    product_id: String
    merchant_id: String
    sku: string | null
    default_variant: Boolean
    image: string | null
    price: Number
    vat: Number
    limit: Number
    quantity: Number
    restrictions: {}
    options: any
    inserted_at: String
    updated_at: String
  }[] = []
  const defaultVariant = productVariants.find(
    (variant: ProductVariant) => variant.default_variant === true
  )!

  let store_variants: {
    id: string
    store_id: string | null
    variant_id: string
    inserted_at: string
    updated_at: string | null
    published_at: string | null
    in_stock: boolean
  }[] = []

  if (productVariants.length > 1) {
    const recentVariants = productVariants.reduce(
      (
        acc: { length: number; data: Array<ProductVariant> },
        cur: ProductVariant
      ) => {
        const { length, data } = acc
        if (!cur.options) {
          return acc
        }
        if (cur.options.length > length) {
          return {
            length: cur.options.length,
            data: [cur]
          }
        } else if (cur.options.length === length) {
          return {
            ...acc,
            data: [...data, cur]
          }
        }
        return acc
      },
      {
        length: 0,
        data: []
      }
    ).data

    recentVariants.forEach((variantData: ProductVariant) => {
      values.options.forEach((data: String) => {
        const variantId = uuid()
        const name = variantData.options.reduce((acc: any, cur: any) => {
          let value = ''
          if (acc === null) {
            value = `${defaultVariant.name} (${cur.value})`
          } else {
            value = `${acc} (${cur.value})`
          }
          return value
        }, null)

        storeIds.forEach((storeId: string) => {
          const { store_variants: storeVariants } = defaultVariant
          const isPublishedInSamedayInventory =
            storeVariants.find((sv) => sv.store_id === storeId)
              ?.published_at !== null
          const isPublishedInPreorderInventory =
            storeVariants.find((sv) => sv.preorder_store_id === storeId)
              ?.published_at !== null

          const insert = {
            id: uuid(),
            store_id: storeId,
            variant_id: variantId,
            inserted_at: 'now()',
            updated_at: 'now()',
            published_at: isPublishedInSamedayInventory ? 'now()' : null,
            in_stock: true
          }
          const preOrderInsert = {
            id: uuid(),
            store_id: null,
            preorder_store_id: storeId,
            variant_id: variantId,
            inserted_at: 'now()',
            updated_at: 'now()',
            published_at: isPublishedInPreorderInventory ? 'now()' : null,
            in_stock: true
          }
          store_variants.push(insert)
          store_variants.push(preOrderInsert)
        })

        const variant = {
          id: variantId,
          name: `${name} (${data})`,
          description: null,
          product_id: productId,
          merchant_id: merchantId,
          sku: values.sku,
          default_variant: false,
          image: null,
          price: 0,
          vat: 0,
          limit: 0,
          quantity: 10,
          restrictions: {
            id: variantData.restrictions.id,
            alcoholic: variantData.restrictions.alcoholic
          },
          options: [
            ...variantData.options,
            {
              name: values.name,
              value: data
            }
          ],
          inserted_at: 'now()',
          updated_at: 'now()'
        }
        insertValues.push(variant)
      })
    })
  } else {
    values.options.forEach((data: String) => {
      const variantId = uuid()

      storeIds.forEach((storeId: string) => {
        const { store_variants: storeVariants } = productVariants[0]
        const isPublishedInSamedayInventory =
          storeVariants.find((sv) => sv.store_id === storeId)?.published_at !==
          null
        const isPublishedInPreorderInventory =
          storeVariants.find((sv) => sv.preorder_store_id === storeId)
            ?.published_at !== null

        const insert = {
          id: uuid(),
          store_id: storeId,
          variant_id: variantId,
          inserted_at: 'now()',
          updated_at: 'now()',
          published_at: isPublishedInSamedayInventory ? 'now()' : null,
          in_stock: true
        }
        const preOrderInsert = {
          id: uuid(),
          store_id: null,
          preorder_store_id: storeId,
          variant_id: variantId,
          inserted_at: 'now()',
          updated_at: 'now()',
          published_at: isPublishedInPreorderInventory ? 'now()' : null,
          in_stock: true
        }
        store_variants.push(insert)
        store_variants.push(preOrderInsert)
      })

      const variant = {
        id: variantId,
        name: `${productVariants[0].name} (${data})`,
        description: null,
        product_id: productId,
        merchant_id: merchantId,
        sku: values.sku,
        default_variant: false,
        image: null,
        price: 0,
        vat: 0,
        limit: 0,
        quantity: 10,
        restrictions: {
          id: productVariants[0].restrictions.id,
          alcoholic: productVariants[0].restrictions.alcoholic
        },
        options: [
          {
            name: values.name,
            value: data
          }
        ],
        inserted_at: 'now()',
        updated_at: 'now()'
      }
      insertValues.push(variant)
    })
  }

  return { insertValues, store_variants }
}

export { toggleCategoryArchiveState, createCategory, buildStoreVariants }
